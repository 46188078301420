import { HDOMAIN_RESOURCE_TYPES } from '@hostinger/hdomains-status';
import dayjs from 'dayjs';

import { wordpressRepo, hDomainsRepo } from '@/repositories';
import { toUnicode, DEFAULT_DATE_TIME_FORMAT } from '@/utils/helpers';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

export default {
  state: {
    data: {},
    stagings: [],
    loaded: false,
    installing: false,
    hasVulnerability: false,
    loadingStagings: false,
    installingStaging: false,
    publishingStaging: false,
    revertingStaging: false,
    domainInfo: {},
    lastStagingDeploy: null,
  },

  mutations: {
    SET_LS_CACHE_INACTIVE_LIST_DATA(state, payload) {
      state.data = payload;
      state.loaded = true;
    },
    SET_STAGING_LIST_DATA(state, { stagings }) {
      state.stagings = stagings;
    },
    SET_STAGING_LOADING(state, { loadingStagings }) {
      state.loadingStagings = loadingStagings;
    },
    SET_STAGING_INSTALLING(state, { status }) {
      state.installingStaging = status;
    },
    SET_STAGING_PUBLISHING(state, { status }) {
      state.publishingStaging = status;
    },
    SET_STAGING_REVERTING(state, { status }) {
      state.revertingStaging = status;
    },
    SET_STAGING_STATUS(state, { domain, directory, status }) {
      const staging = state.stagings.find(
        (item) => item.domain === domain && item.directory === directory,
      );

      staging.status = status;
    },
    SET_LAST_STAGING_DEPLOY(state) {
      const stagings = state.stagings.filter(
        (staging) => staging.value !== null,
      );

      if (stagings.length) {
        const maxDate = Math.max(
          ...stagings.map((staging) => new Date(staging.deployedAt)),
        );
        state.lastStagingDeploy = dayjs(maxDate).format(
          DEFAULT_DATE_TIME_FORMAT,
        );
      }
    },
    SET_DOMAIN_INFO(state, data) {
      state.domainInfo = data;
    },
    SET_VULNERABILITY(state, { status }) {
      state.hasVulnerability = status;
    },
  },
  getters: {
    getLSCacheInactiveList: (state) => ({
      loaded: state.loaded,
      data: state.data,
    }),
    getStagingList: (state) => ({
      stagings: state.stagings,
    }),
    getStagingLoadingStatus: (state) => state.loadingStagings,
    getStagingInstallingStatus: (state) => state.installingStaging,
    getStagingPublishingStatus: (state) => state.publishingStaging,
    getStagingRevertingStatus: (state) => state.revertingStaging,
    getLastStagingDeploy: (state) => state.lastStagingDeploy,
    getVulnerabilityStatus: (state) => state.hasVulnerability,
  },
  actions: {
    async getDomainResources(context, domain) {
      const [{ data }, err] = await hDomainsRepo.getResources({
        title: this.domain,
        resourceType: HDOMAIN_RESOURCE_TYPES.DOMAIN,
      });

      if (err) return;

      const domainInfo = data.find(
        (domainDetails) => domainDetails.title === domain,
      );
      context.commit('SET_DOMAIN_INFO', domainInfo);
    },

    async hostingLSCacheInactiveListIndex(context) {
      context.commit('SET_LS_CACHE_INACTIVE_LIST_DATA', null);
      const [{ data }, err] = await wordpressRepo.getLSCacheInactiveList();
      if (!err) {
        context.commit('SET_LS_CACHE_INACTIVE_LIST_DATA', data);
      }
    },
    async hostingLSCacheActivationStore(context, requestBody) {
      const [, err] = await wordpressRepo.postLSCacheWebsiteList(requestBody);

      if (!err) {
        context.commit('SET_LS_CACHE_INACTIVE_LIST_DATA', []);
      }

      return err;
    },
    async hostingStagingListIndex(context, routeData) {
      if (!routeData) return;

      context.commit('SET_STAGING_LOADING', {
        loadingStagings: true,
      });

      const { directory, domain, withNotification, stagingDomain, event } =
        routeData;
      const [{ data }, err] = await wordpressRepo.getStagingList(
        directory || '',
        domain,
      );

      const eventToastrList = {
        wordpress_staging_create: {
          type: 'success',
          text: i18n.t('Staging created successfully'),
          htmlText: i18n.t(
            'Check staging website on the WordPress staging page.',
          ),
        },
        wordpress_staging_deploy: {
          type: 'success',
          text: i18n.t('Staging {domain} is successfully published', {
            domain: toUnicode(stagingDomain),
          }),
          htmlText: '',
        },
        wordpress_staging_deploy_failed: {
          type: 'error',
          text: i18n.t(
            'Staging {domain} failed to publish. Please contact support for help.',
            {
              domain: toUnicode(stagingDomain),
            },
          ),
          htmlText: '',
        },
        wordpress_staging_revert: {
          type: 'success',
          text: i18n.t('Staging {domain} is successfully reverted', {
            domain: toUnicode(stagingDomain),
          }),
          htmlText: '',
        },
        wordpress_staging_revert_failed: {
          type: 'error',
          text: i18n.t(
            'Staging {domain} failed to revert. Please contact support for help.',
            {
              domain: toUnicode(stagingDomain),
            },
          ),
          htmlText: '',
        },
      };

      if (!err) {
        context.commit('SET_STAGING_LIST_DATA', {
          stagings: [...data],
        });
        context.commit('SET_LAST_STAGING_DEPLOY');
        context.commit('SET_STAGING_LOADING', {
          loadingStagings: false,
        });

        const isDeploying = data.find(
          (staging) => staging.status === 'deploying',
        );
        const isReverting = data.find(
          (staging) => staging.status === 'reverting',
        );
        context.commit('SET_STAGING_PUBLISHING', {
          status: !!isDeploying,
        });
        context.commit('SET_STAGING_REVERTING', {
          status: !!isReverting,
        });

        if (withNotification) {
          const eventObj = eventToastrList[event];
          if (eventObj.type === 'success') {
            toastr.s(eventObj.text, {
              html: `<p>${eventObj.htmlText}</p>`,
            });
          } else {
            toastr.e(eventObj.text, {
              html: `<p>${eventObj.htmlText}</p>`,
            });
          }
        }
      }
    },
    async hostingStagingStore(_, requestBody) {
      return await wordpressRepo.postNewStaging(requestBody);
    },
    async deleteHostingStaging({ dispatch }, requestBody) {
      const [{ data }] = await wordpressRepo.deleteHostingStaging(requestBody);
      if (data) {
        dispatch('hostingStagingListIndex', {
          directory: requestBody.prodDirectory,
          domain: requestBody.productionDomain,
        });
      }

      return data;
    },
    async publishHostingStaging(context, requestBody) {
      context.commit('SET_STAGING_PUBLISHING', { status: true });
      const [{ data }] = await wordpressRepo.publishHostingStaging(requestBody);
      if (data) {
        context.commit('SET_STAGING_STATUS', {
          domain: requestBody.domain,
          directory: requestBody.directory,
          status: 'deploying',
        });
      }
    },
    async revertHostingStaging(context, requestBody) {
      context.commit('SET_STAGING_REVERTING', { status: true });
      const [{ data }] = await wordpressRepo.revertHostingStaging(requestBody);
      if (data) {
        context.commit('SET_STAGING_STATUS', {
          domain: requestBody.domain,
          directory: requestBody.directory,
          status: 'reverting',
        });
      }
    },
    async isInstallingHostingStaging(context) {
      const [{ data }] = await wordpressRepo.getIsInstalingStagingList();
      context.commit('SET_STAGING_INSTALLING', {
        status: data,
      });
    },
    async getVulnerabilityStatus(context) {
      const [{ data }, err] = await wordpressRepo.getVulnerabilityStatus();
      if (!err) {
        context.commit('SET_VULNERABILITY', {
          status: data,
        });
      }
    },
  },
};
