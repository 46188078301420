<template>
  <div class="d-flex flex-wrap justify-content-center align-items-center">
    <HpIcon
      class="h-mr-8"
      icon="icon-neutral"
      :width="16"
      :danger="!isDomainPropagating"
      :warning="isDomainPropagating"
    />
    <Trans>Domain status</Trans>
    :
    <Trans
      :translate-params="{
        domain: domain,
        time: propagationPeriod.time,
        unit: propagationPeriod.unit,
      }"
      tag="span"
      class="ml-1"
    >
      {{ status }}
    </Trans>
    <a
      v-if="!isDomainPropagating"
      v-trans
      href
      class="ml-2 text-danger text-underline"
      @click.prevent="handleClick"
    >
      v2.how.to.fix.this.question
    </a>
  </div>
</template>

<script>
//JSON-slugs-migrated
import { NEXT_BEST_ACTION } from '@hostinger/hdomains-status';

import { useDomain, useDomainStatus } from '@/composables';
import { useDomainsStatusStore, useProfileStore } from '@/stores';
import { AmplitudeEvent } from '@/types';

export default {
  name: 'DomainNotPointingBanner',
  props: {
    banner: {
      type: Object,
    },
  },
  created() {
    if (this.banner.vIf && this.banner.vShow) {
      this.$amplitudeV2(AmplitudeEvent.Hosting.CONNECT_DOMAIN_SHOWN, {
        type: 'banner',
      });
    }
  },
  setup() {
    const domainsStatusStore = useDomainsStatusStore();
    const profileStore = useProfileStore();
    const { domain } = useDomain();
    const { domainStatusInputObject, domainAction } = useDomainStatus(
      domain.value,
    );

    return {
      domain,
      domainsStatusStore,
      profileStore,
      domainStatusInputObject,
      domainAction,
    };
  },
  computed: {
    domainStatus() {
      return this.domainsStatusStore?.getDomainStatus(this.domain)
        ?.domainStatus;
    },
    isDomainPropagating() {
      return this.domainStatus?.isInPropagationTime === true;
    },
    propagationTimeMins() {
      return this.domainStatus?.propagationTimeMins;
    },
    status() {
      if (!this.isDomainPropagating) {
        return 'v2.not.pointing.to.our.name.servers';
      }
      if (this.isDomainPropagating && !!this.propagationPeriod.time) {
        return 'v2.domain.will.be.accessible.in.about.time.unit';
      }

      return 'v2.domain.is.currently.connecting.to.hostinger.it.may.take.up.to.hours';
    },
    propagationPeriod() {
      const timeInMinutes = this.propagationTimeMins;
      const MINUTES_IN_HOUR = 60;
      if (timeInMinutes > MINUTES_IN_HOUR) {
        return {
          time: Math.floor(timeInMinutes / MINUTES_IN_HOUR),
          unit: 'hours',
        };
      }

      return { time: timeInMinutes, unit: 'minutes' };
    },
  },
  methods: {
    handleClick() {
      if (
        [
          NEXT_BEST_ACTION.CONNECT_DOMAIN,
          NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL,
        ].includes(this.domainStatus?.nextBestAction)
      ) {
        this.domainAction?.action();
      }

      this.$store.dispatch('domainIsPointingIndex', this.domain);
    },
  },
};
</script>
