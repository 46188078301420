import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { hResourcesRepo } from '@/repositories';
import type { HResourceType, ProWebsiteResource } from '@/types';
import {
  HRESOURCES_STATE,
  HRESOURCES_TYPE,
  STORE_PERSISTENT_KEYS,
} from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';

export const useManagedResourcesStore = defineStore(
  'managedResourcesStore',
  () => {
    const managedResources = ref<ProWebsiteResource[]>([]);
    const isManagedResourcesLoading = ref(false);
    const isLoaded = ref(false);

    const filterByTypeFunction = (
      type: HResourceType,
      resource: ProWebsiteResource,
    ) => type === resource.type && resource.state === HRESOURCES_STATE.PENDING;

    const managedPendingResourcesByType = computed<
      Record<string, ProWebsiteResource[]>
    >(() => ({
      [HRESOURCES_TYPE.DOMAIN]: managedResources.value.filter((resource) =>
        filterByTypeFunction(HRESOURCES_TYPE.DOMAIN, resource),
      ),
      [HRESOURCES_TYPE.HOSTING]: managedResources.value.filter((resource) =>
        filterByTypeFunction(HRESOURCES_TYPE.HOSTING, resource),
      ),
      [HRESOURCES_TYPE.EMAIL]: managedResources.value.filter((resource) =>
        filterByTypeFunction(HRESOURCES_TYPE.EMAIL, resource),
      ),
      [HRESOURCES_TYPE.GOOGLE_WORKSPACE]: managedResources.value.filter(
        (resource) =>
          filterByTypeFunction(HRESOURCES_TYPE.GOOGLE_WORKSPACE, resource),
      ),
      [HRESOURCES_TYPE.VIRTUAL_MACHINE]: managedResources.value.filter(
        (resource) =>
          filterByTypeFunction(HRESOURCES_TYPE.VIRTUAL_MACHINE, resource),
      ),
    }));

    const getManagedPendingHostingResources = computed(() =>
      managedResources.value.filter(
        (resource: ProWebsiteResource) =>
          resource.type === HRESOURCES_TYPE.HOSTING &&
          resource.state === HRESOURCES_STATE.PENDING,
      ),
    );

    const getManagedResourceBySubscriptionId = (subscriptionId: string) =>
      managedResources.value.find(
        (resource) => resource.chargebeeSubscriptionId === subscriptionId,
      );

    const fetchManagedResources = async (
      { requestData, requestConfig } = {
        requestData: {},
        requestConfig: { overrideCache: true },
      },
    ) => {
      isManagedResourcesLoading.value = true;
      const [{ data }, error] =
        await hResourcesRepo.getProPendingSetupResources(
          requestData,
          requestConfig,
        );

      if (error) {
        isManagedResourcesLoading.value = false;
        errorLogger.logError(error, 'get pro pending setup resources error');

        return [{ data }, error];
      }

      managedResources.value = data;
      isManagedResourcesLoading.value = false;
      isLoaded.value = true;

      return [{ data }, error];
    };

    return {
      managedResources,
      isManagedResourcesLoading,
      isLoaded,
      getManagedPendingHostingResources,
      managedPendingResourcesByType,
      getManagedResourceBySubscriptionId,
      fetchManagedResources,
    };
  },
  { persist: { key: STORE_PERSISTENT_KEYS.MANAGED_RESOURCES_STORE } },
);
