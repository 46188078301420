import * as amplitude from '@amplitude/analytics-browser';
import cookies from 'js-cookie';
import type { Ref, App, Directive, VNode, DirectiveBinding } from 'vue';
import type {
  RouteLocationNormalizedLoaded,
  RouteRecordName,
  Router,
} from 'vue-router';

import { router } from '@/router';
import store from '@/store';
import { useProfileStore } from '@/stores';
import { AmplitudeLocation, ONBOARDING_SCENARIO } from '@/types';
import type { AddonOnboardingScenario } from '@/types';
import {
  userAgent,
  getDeviceType,
  getDeviceOS,
  getDeviceBrowser,
} from '@/utils/deviceService';
import { getJwtToken, parseJwt } from '@/utils/helpers/authHelpers';
import deviceIdService from '@/utils/services/deviceIdService';
import { errorLogger } from '@/utils/services/errorLogging';
import { camelToSnakeObj } from '@/utils/services/namingConventionsService';

const OFFER_CHOSEN_EVENTS = '.offer_chosen';

const sanitizeJwt = (path: string) => {
  const [pathWithoutJwt] = path.split('?jwt');

  return pathWithoutJwt;
};

const sanitizeCreationPath = (path: string) => {
  if (path.includes('?jwt')) {
    return sanitizeJwt(path);
  }

  return path;
};

const getGlobalAccessManagerParams = () => {
  const jwtToken = getJwtToken();
  if (!jwtToken) return;

  const { access } = parseJwt(jwtToken);
  const isImpersonated = access !== null;

  return {
    is_impersonated: isImpersonated,
    ...(isImpersonated && {
      impersonated_client_id: access?.managedClientId,
    }),
  };
};

const getCreationPath = () => {
  const currentRoute = router.currentRoute?.value;
  const sanitizedCreationPath = sanitizeCreationPath(currentRoute?.fullPath);

  return sanitizedCreationPath === '/'
    ? store.state.redirect.params.amplitudeCreationPath || sanitizedCreationPath
    : sanitizedCreationPath;
};

const getGlobalDeviceParams = () => ({
  ...(window?.screen
    ? { screen_resolution: [window.screen.width, window.screen.height] }
    : {}),
  ...(userAgent
    ? {
        device_type: getDeviceType(),
        os: getDeviceOS(),
        browser: getDeviceBrowser(),
      }
    : {}),
  ...(window ? { window_size: [window.innerWidth, window.innerHeight] } : {}),
});

const getGlobalAmplitudeProperties = () => {
  const currentRoute = router.currentRoute?.value;
  const globalParams: {
    creation_location?: RouteRecordName | null | undefined;
    creation_path?: string;
    is_addon?: boolean;
    location?: AmplitudeLocation.Base | null | undefined;
    is_impersonated?: boolean;
    impersonated_client_id?: string;
    beta_test?: boolean;
    screen_resolution?: number[];
  } = {
    creation_location: currentRoute?.name,
    creation_path: getCreationPath(),
    ...getGlobalAccessManagerParams(),
    ...getGlobalDeviceParams(),
  };

  // Onboarding V2
  if (currentRoute?.fullPath.includes('onboarding-v2')) {
    const isAddon = [
      ONBOARDING_SCENARIO.ADDON,
      ONBOARDING_SCENARIO.QUICK_INSTALL_MIGRATION_ADDON,
      ONBOARDING_SCENARIO.QUICK_INSTALL_WEBSITE_BUILDER_ADDON,
      ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_ADDON,
      ONBOARDING_SCENARIO.QUICK_INSTALL_EMPTY_WEBSITE_ADDON,
    ].includes(currentRoute?.params?.scenario as AddonOnboardingScenario);

    globalParams.creation_location = `${String(currentRoute?.name)}V2`;
    globalParams.location = AmplitudeLocation.Base.HOSTING_ONBOARDING_V2;
    globalParams.is_addon = isAddon;
  }

  // Onboarding V1
  if (currentRoute?.fullPath.includes('lets-start')) {
    const isAddon = currentRoute?.params?.type === 'addon';
    globalParams.is_addon = isAddon;
  }

  // H5G onboarding
  if (currentRoute?.fullPath.includes('h5g/onboarding')) {
    globalParams.beta_test = true;
  }

  return globalParams;
};
export const offerChosenProperties = (eventName: string) => {
  const profileStore = useProfileStore();

  const isOfferChosenEvent = eventName.includes(OFFER_CHOSEN_EVENTS);

  return isOfferChosenEvent
    ? {
        is_risky: profileStore.account?.hasRiskIndicators,
        is_payment_method_added:
          !!store.getters['paymentMethods/getDefaultPaymentMethod'],
      }
    : {};
};

export const trackAmplitudeEvent = async (eventName: string, params = {}) => {
  const isStaging = process.env.VITE_MODE === 'staging';

  if (!eventName || isStaging || cookies.get('e2e-skip-amplitude')) return;

  try {
    const globalProperties = getGlobalAmplitudeProperties();

    return await amplitude.track(eventName, {
      ...globalProperties,
      ...offerChosenProperties(eventName),
      ...camelToSnakeObj(params),
    }).promise;
  } catch (error) {
    errorLogger.logError(error as Error);
  }
};

export const setOnceAmplitudeUserProperty = async (
  propertyName: string,
  propertyValue: string,
) => {
  const identifyEvent = new amplitude.Identify();
  identifyEvent.setOnce(propertyName, propertyValue);

  await amplitude.identify(identifyEvent);
};

export const trackDirective: Directive<
  HTMLElement,
  string | { eventName: string; params: object }
> = {
  beforeMount(el, binding, vNode) {
    el.addEventListener('click', () => amplitudeTrackingV2(vNode, binding));
  },

  unmounted(el, binding, vNode) {
    el.removeEventListener('click', () => amplitudeTrackingV2(vNode, binding));
  },
};

const amplitudeTrackingV2 = (
  vNode: VNode<
    any,
    HTMLElement,
    {
      [key: string]: any;
    }
  >,
  binding: DirectiveBinding<string | { eventName: string; params: object }>,
) => {
  const event = getDirectiveEventNameV2(vNode, binding);

  const name = typeof event === 'object' ? event?.eventName : event;
  const params = typeof event === 'object' ? event?.params : undefined;

  if (!name) return;

  trackAmplitudeEvent(name, params);
};

const getDirectiveEventNameV2 = (
  vNode: VNode<
    any,
    HTMLElement,
    {
      [key: string]: any;
    }
  >,
  binding: DirectiveBinding<string | { eventName: string; params: object }>,
) => binding.arg || binding.value;

export const amplitudeV2 = trackAmplitudeEvent;

export const amplitudePlugin = (pluginContext: {
  currentRouteRef: Ref<RouteLocationNormalizedLoaded>;
  app: App<Element>;
  router: Router;
  store: any;
}) => {
  const { app } = pluginContext;

  app.directive('track-v2', trackDirective);
  app.config.globalProperties.$amplitudeV2 = trackAmplitudeEvent;
};

export const initAmplitude = async ({
  clientId,
  brand,
  managerClientId,
}: {
  clientId: string;
  brand: string;
  managerClientId?: string;
}) => {
  if (
    !process.env.VITE_AMPLITUDE_PUBLIC_API_KEY ||
    !process.env.VITE_AMPLITUDE_ANALYTICS_URL
  ) {
    return;
  }
  const deviceId = deviceIdService.getDeviceId();
  const userId = managerClientId || clientId;

  amplitude.init(process.env.VITE_AMPLITUDE_PUBLIC_API_KEY, userId, {
    serverUrl: process.env.VITE_AMPLITUDE_ANALYTICS_URL,
    deviceId,
    defaultTracking: {
      attribution: false,
      pageViews: false,
      sessions: false,
      formInteractions: false,
      fileDownloads: false,
    },
    cookieOptions: {
      domain: process.env.VITE_AMPLITUDE_COOKIE_DOMAIN,
    },
  });

  const identifyEvent = new amplitude.Identify();

  identifyEvent.setOnce('reseller_client_id', userId);
  identifyEvent.setOnce('brand', brand);

  amplitude.identify(identifyEvent);
};
