import type {
  RouteLocationNormalized,
  RouteLocationRaw,
  Router,
} from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import { useBrands, useModal } from '@/composables';
import store from '@/store';
import { useFrontendSettingsStore } from '@/stores';
import { Route } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';
import { $t } from '@/utils/services/i18nService';
import { checkAndUpdateHpanelVersion } from '@/utils/services/versionUpdateChecker';
import { removeQueryParamFromUrl } from '@/utils/services/windowService';

/**
 * This router is empty and is set in `main.ts` with all the routes present
 * to prevent circular dependency injection
 */
export let router = createRouter({
  history: createWebHistory(),
  routes: [],
});

const FIXED_MODALS = [
  'RequiredPasswordChangeModalV2',
  'RequiredNiagahosterOnboardingVideoModal',
  'HvpsLicenseModal',
  'PurchaseAddonModal',
  'HostingerMailOneStepModal',
  'UpdatePersonalInformationV2',
  'GeneralModal',
  'ClaimFreeEmailServiceModal',
];

export const setRouter = (routerToSet: Router) => {
  router = routerToSet;

  router.beforeEach(async (to, from) => {
    errorLogger.setTag('route', to?.name);
    errorLogger.setTag('previousRoute', from?.name);

    if (to.name === Route.Base.LOGIN) {
      // login route performs redirection to cpanel,
      // thus we can skip all other initialization steps.

      return;
    }

    const { closeModal, currentModalName } = useModal();

    setPageTitle(to);

    if (
      currentModalName.value &&
      !FIXED_MODALS.includes(currentModalName.value)
    ) {
      closeModal();
    }

    const notSameNames = to.name !== from.name;
    const notSamePaths = to.path !== from.path;

    if (notSamePaths) {
      store.commit('SET_PREV_ROUTE_NAME', from.name);
    }

    if (notSameNames && from.name !== store.state.redirect.routeName) {
      // TODO - TYPESCRIPT SUPPORT AFTER VUE COMPAT MIGRATION
      store.commit('UNSET_REDIRECT');
    }

    if (from.name && notSameNames) {
      await checkAndUpdateHpanelVersion(to);
    }
  });

  router.afterEach((to, from) => {
    const frontendSettingsStore = useFrontendSettingsStore();

    if (to.query.jwt) {
      removeQueryParamFromUrl('jwt');
    }

    if (to.params?.domain !== from?.params.domain) {
      store.commit('domainChange');
    }

    if (to.params?.id !== from?.params.id) {
      store.commit('domainChange');
    }

    const isSameRoute = to.name === from.name;

    if (!isSameRoute) {
      frontendSettingsStore.setPageSettings(to.meta as unknown as any);
    }
    frontendSettingsStore.setState('pageLoading', false);

    if (to.meta.scrollToTop === false) return;

    const scrollableContent =
      document.getElementById('scrollable-content') || window;

    scrollableContent?.scrollTo(0, 0);
  });
};

const NAVIGATION_FAILURE_TYPE_CANCEL = 8;

export const dangerouslyPushRouteThroughCancellation = async (
  pushConfig: RouteLocationRaw,
) => {
  const navigationFailure = await router.push(pushConfig);

  if (navigationFailure?.type === NAVIGATION_FAILURE_TYPE_CANCEL) {
    router.push(pushConfig);
  }
};

export const dangerouslyReplaceRouteThroughCancellation = async (
  pushConfig: RouteLocationRaw,
) => {
  if (!router) return;

  const navigationFailure = await router.replace(pushConfig);

  if (navigationFailure?.type === NAVIGATION_FAILURE_TYPE_CANCEL) {
    router.replace(pushConfig);
  }
};

const setPageTitle = (to: RouteLocationNormalized) => {
  const { isFromAnotherBrand } = useBrands();

  const DEFAULT_TITLE = 'Hostinger';
  const ANOTHER_BRAND_TITLE = 'hPanel';

  const metaTitleSuffix = isFromAnotherBrand.value
    ? ANOTHER_BRAND_TITLE
    : DEFAULT_TITLE;

  document.title = `${$t(to.meta?.title, to.params)} | ${metaTitleSuffix}`;
};
