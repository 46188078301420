import type { CatalogItem, HostingPlan } from '@/types';

export type PurchasePlan = HostingPlan | CatalogItem | null;

export interface PurchaseModalParams {
  purchasePlan: PurchasePlan;
  couponCode?: string;
  onSuccess?: Function;
  redirect?: string;
  couponCodeDisclaimer?: string;
  isCouponHidden?: boolean;
  relatedResourceId?: number;
  position?: number;
  subCategories?: string[];
}

export type PlanPricingDetails = {
  price: number;
  discountPercentage: number;
  period: string;
  renewalPrice: number;
  coupon?: string;
};

export type PlanWithPricingDetailsAndPosition =
  | (HostingPlan & { pricingDetails: PlanPricingDetails; position: number })
  | (CatalogItem & { pricingDetails: PlanPricingDetails; position: number });

export const GOOGLE_ANALYTICS_EVENTS = {
  VIEW_ITEM_LIST: 'ga4.view_item_list',
  VIEW_ITEM: 'ga4.view_item',
  ADD_TO_CART: 'ga4.add_to_cart',
  VIEW_CART: 'ga4.view_cart',
  BEGIN_CHECKOUT: 'ga4.begin_checkout',
  SELECT_ITEM: 'ga4.select_item',
} as const;

export type GoogleAnalyticsEvents =
  (typeof GOOGLE_ANALYTICS_EVENTS)[keyof typeof GOOGLE_ANALYTICS_EVENTS];

export interface IGaItem {
  discount?: number;
  price?: number;
  currency?: string;
  index?: number;
  coupon: string;
  item_brand: string;
  item_category: string;
  item_category2: string | string[];
  item_id: string;
  item_list_id: string;
  item_list_name: string;
  item_name: string;
  quantity: number;
}
