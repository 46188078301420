import type { NavigationGuard } from 'vue-router';

import { useVpsPurchaseStore } from '@/stores';
import { VPS_PURCHASE_STEP } from '@/types';

const hVpsPurchaseGuard: NavigationGuard = async (to) => {
  const purchaseStore = useVpsPurchaseStore();

  if (to.name === VPS_PURCHASE_STEP.GET_PLAN) {
    if (!purchaseStore.state?.template) {
      purchaseStore.resetStep();
      purchaseStore.goToStep(VPS_PURCHASE_STEP.START);

      return;
    }

    const isTemplateRequirePanelPassword =
      purchaseStore.state?.template?.isPanelPassResetSupported &&
      !purchaseStore.state?.panelPassword;

    if (
      (!purchaseStore.isGamePanelPurchase &&
        !purchaseStore.state?.rootPassword) ||
      isTemplateRequirePanelPassword
    ) {
      purchaseStore.goToStep(VPS_PURCHASE_STEP.FEATURES);

      return;
    }
  }

  if (
    to.name === VPS_PURCHASE_STEP.FEATURES &&
    !purchaseStore.state?.template
  ) {
    purchaseStore.resetStep();
    purchaseStore.goToStep(VPS_PURCHASE_STEP.START);

    return;
  }
};

export default hVpsPurchaseGuard;
