import type { NavigationGuard } from 'vue-router';

import { useExperiment, useReferrals } from '@/composables';
import { useReferralStore } from '@/stores/referralStore';
import { Experiment, Route } from '@/types';

export const referralsGuard: NavigationGuard = async () => {
  const referralsStore = useReferralStore();
  const { sendReferralsEnterAmplitudeEvent } = useReferrals();
  const { isExperimentActive } = useExperiment(
    Experiment.ID.REFERRALS_GET_STARTED_REMOVAL,
  );

  if (!referralsStore.userReferralData) {
    await referralsStore.fetchReferralClientInfo(true);
  }

  if (referralsStore.userReferralData?.disabled) {
    return { name: Route.Base.HOME };
  }

  if (!referralsStore.referralsRoute) {
    await referralsStore.fetchReferralAccessData();
    if (!referralsStore.referralsRoute) {
      return { name: Route.Base.HOME };
    }
  }

  sendReferralsEnterAmplitudeEvent();

  if (isExperimentActive.value) {
    return;
  }

  return referralsStore.hasAccessToReferralDashboard
    ? undefined
    : { name: Route.Referral.WELCOME_TO_REFERRALS };
};

export const welcomeToReferralsGuard: NavigationGuard = async () => {
  const referralStore = useReferralStore();

  if (referralStore.userReferralData === null && referralStore.error === null) {
    await referralStore.fetchReferralClientInfo(true);
  }

  if (referralStore.hasAccessToReferralDashboard) {
    return { name: Route.Referral.REFERRALS };
  }

  if (!referralStore.referralsRoute) {
    await referralStore.fetchReferralAccessData();
  }

  return referralStore.referralsRoute ? undefined : { name: Route.Base.HOME };
};
