<template>
  <div v-if="data.importantMessage && currentStep === 0">
    <p v-trans>
      {{ data.importantMessage }}
    </p>
    <div class="d-flex justify-content-end h-mt-16">
      <HButton v-qa-generate variant="text" class="h-mr-8" @click="close">
        {{ $t('Cancel') }}
      </HButton>
      <HButton v-qa-generate @click="goToNextStep(1)">
        {{ $t('Continue') }}
      </HButton>
    </div>
  </div>
  <div v-if="!data.importantMessage || currentStep === 1">
    <template v-if="!payment.loading">
      <span v-if="data.goBackText" class="arrow-back">
        <HpIcon
          icon="ic-arrow-left"
          gray
          class="align-middle"
          @click="data.goBackCustom()"
        />
        <span v-trans>
          {{ data.goBackText }}
        </span>
      </span>
      <h3 class="h-mb-0">
        {{ data.title }}
      </h3>
      <p class="h-mb-16">
        {{ data.subtitle }}
      </p>
    </template>
    <ModalSkeletonLoader v-if="loading || pricesLoading" />
    <CvcIFrame
      v-else-if="isCvcRefreshVisible"
      :data="cvcIframeData"
      @success="onCvcRefreshSuccess"
      @cancel="isCvcRefreshed = true"
    />
    <template v-else>
      <template v-if="!payment.loading">
        <MailboxCount
          v-if="data.mailboxCount && currentMailboxPrice"
          :mailbox-count="data.mailboxCount"
          :mailbox-price="currentMailboxPrice"
          :loading="estimation.loading"
          is-upgrade
        />
        <PurchasePeriods
          :prices="prices"
          :is-addon="!!data.addon"
          :has-mailbox="data.isEmail"
          :pre-selected-period="preselectedPeriodId"
          is-upgrade
          @change="handlePurchasePeriodChange"
        />
        <HSnackbar v-if="data.warningMessage" variant="warning" class="h-mb-16">
          {{ data.warningMessage }}
        </HSnackbar>
        <PurchaseFeatures
          v-if="data.features"
          :features="data.features"
          light
        />
        <PurchaseQuantity
          v-if="data.isEmail"
          class="h-mb-8"
          title="Need more accounts?"
          :min-value="mailboxCounterMinValue"
          :max-value="maxAccountNum"
          :tooltip="mailboxCounterTooltipText"
          :is-estimation-loading="estimation.loading"
          @change="changeQuantity"
        />
        <PurchaseDetails
          :pricing-details="pricingDetails"
          :loading="estimation.loading"
          :edit-loading="paymentEditLoading"
          :error="estimation.error"
          :total-tooltip-text="totalTooltipText"
          @edit-payment="handleEditPaymentMethod"
        >
          <template #coupon>
            <PurchaseCoupon
              :loading="estimation.couponLoading"
              :error="estimation.couponError"
              :active-coupon="activeCoupon"
              @on-apply="
                onCouponApply($event, () => getPricingEstimate($event))
              "
              @on-remove="onCouponRemove(() => getPricingEstimate())"
            />
          </template>
        </PurchaseDetails>
        <UpgradeRenewalInfo
          :period="selectedPlanPeriod.period"
          :period-unit="selectedPlanPeriod.periodUnit"
          :price="selectedPlanPeriod.price"
          :is-email="data.isEmail"
        />
        <PurchaseButtons
          :loading="
            estimation.loading || paymentEditLoading || cvcIframeData.isLoading
          "
          :error="estimation.error"
          :credit-pay="isCreditPay"
          step="complete"
          @on-success="goToPayment"
        />
      </template>
      <PurchaseProcessing
        v-else
        :completed="payment.completed"
        :error="payment.error"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ModalSkeletonLoader from '@/components/Loaders/SkeletonCompositions/ModalSkeletonLoader.vue';
import PurchaseCoupon from '@/components/Modals/Parts/Coupon/PurchaseCoupon.vue';
import CvcIFrame from '@/components/Modals/Parts/CvcIFrame.vue';
import MailboxCount from '@/components/Modals/Parts/MailboxCount.vue';
import PurchaseButtons from '@/components/Modals/Parts/PurchaseButtons.vue';
import PurchaseDetails from '@/components/Modals/Parts/PurchaseDetails.vue';
import PurchaseFeatures from '@/components/Modals/Parts/PurchaseFeatures.vue';
import PurchasePeriods from '@/components/Modals/Parts/PurchasePeriods.vue';
import PurchaseProcessing from '@/components/Modals/Parts/PurchaseProcessing.vue';
import PurchaseQuantity from '@/components/Modals/Parts/PurchaseQuantity.vue';
import UpgradeRenewalInfo from '@/components/Modals/Parts/UpgradeRenewalInfo.vue';
import { usePurchaseModal, useCVCRefresh, useModal } from '@/composables';
import { hBillingRepo } from '@/repositories';
import { useCatalogStore, useProfileStore } from '@/stores';
import { AmplitudeEvent, Email, Route } from '@/types';
import { mapKeyValue } from '@/utils/helpers';
import { transformPlanName } from '@/utils/helpers/emailsHelper';
import creditsMixin from '@/utils/mixins/hbilling/creditsMixin';
import modalsMixin from '@/utils/mixins/modalsMixin';
import newPurchaseMixin from '@/utils/mixins/newPurchaseMixin';
import { monthsPerPeriodUnit } from '@/utils/services/chargebeeCurrencyService';

const AMPLITUDE_EVENT_MAP = {
  hostinger_email: {
    onShowEventName: AmplitudeEvent.Email.EMAIL_UPGRADE_PERIOD_CHOICE_SHOWN,
  },
  hosting: {
    onShowEventName: AmplitudeEvent.Hosting.UPGRADE_PERIOD_CHOICE_SHOWN,
    onPaymentClickEventName: AmplitudeEvent.Hosting.UPGRADE_OFFER_CHOSEN,
  },
  vps: {
    onPaymentClickEventName: AmplitudeEvent.Vps.UPGRADE_OFFER_CHOSEN,
  },
  black_friday: {
    onPaymentClickEventName:
      AmplitudeEvent.BlackFriday.BLACK_FRIDAY_OFFER_CHOSEN,
  },
};

export default {
  mixins: [modalsMixin, newPurchaseMixin, creditsMixin],
  components: {
    PurchasePeriods,
    PurchaseDetails,
    ModalSkeletonLoader,
    PurchaseProcessing,
    PurchaseFeatures,
    PurchaseButtons,
    MailboxCount,
    PurchaseQuantity,
    PurchaseCoupon,
    CvcIFrame,
    UpgradeRenewalInfo,
  },
  mounted() {
    this.trackSeenAmplitude();
  },
  data: () => ({
    loading: false,
    pricesLoading: false,
    prices: [],
    pricingDetails: {},
    mailboxCount: null,
    selectedPlanPeriod: {},
    preselectedPeriodId: '',
    period: null,
  }),
  setup() {
    const {
      cvcIframeData,
      isCvcRefreshVisible,
      isCVCRefreshNeeded,
      getCvcIframe,
      isCvcRefreshed,
      addIpToWhitelist,
    } = useCVCRefresh();
    const catalogStore = useCatalogStore();
    const profileStore = useProfileStore();
    const { updateModalStep } = useModal();

    const {
      estimation,
      billingAddress,
      activeCoupon,
      loadedEstimations,
      onEstimationError,
      onCouponRemove,
      onCouponApply,
      addLoadedEstimation,
      setEstimationLoadingState,
      setEstimationErrorState,
      getCouponRequest,
    } = usePurchaseModal();

    return {
      estimation,
      billingAddress,
      activeCoupon,
      loadedEstimations,
      catalogStore,
      profileStore,
      onEstimationError,
      onCouponRemove,
      onCouponApply,
      addLoadedEstimation,
      setEstimationLoadingState,
      setEstimationErrorState,
      getCouponRequest,
      updateModalStep,
      isCvcRefreshVisible,
      isCVCRefreshNeeded,
      getCvcIframe,
      cvcIframeData,
      isCvcRefreshed,
      addIpToWhitelist,
    };
  },
  created() {
    this.setPrices();
  },
  methods: {
    async goToPayment() {
      if (this.isCVCRefreshNeeded) {
        this.isCvcRefreshed = false;
        await this.getCvcIframe();

        return;
      }
      await this.paymentCall();
    },
    onCvcRefreshSuccess() {
      this.isCvcRefreshed = true;
      this.paymentCall();
    },

    fireEmailOfferChosenEvent() {
      this.$amplitudeV2(AmplitudeEvent.Email.OFFER_CHOSEN, {
        ...this.commonOfferChosenEventProperties,
        plan: this.upgradeItem.metadata.plan,
        isUpsell: Number(
          [Route.Email.UPSELL, Route.Email.TITAN_UPGRADE_PLAN].includes(
            this.$route.name,
          ),
        ),
      });
    },

    handleEditPaymentMethod() {
      if (this.data.isEmail) {
        this.fireEmailOfferChosenEvent();
      }

      this.editPayment(
        { ...this.billingRequest, methodId: undefined },
        this.paymentType,
      );
    },

    async paymentCall() {
      if (this.data.importantMessage) {
        this.updateModalStep({
          step: 1,
          data: { hideX: true },
        });
      }

      if (this.data.isEmail) {
        this.fireEmailOfferChosenEvent();
      }

      if (this.amplitudeConfig?.onPaymentClickEventName) {
        this.$amplitudeV2(
          this.amplitudeConfig.onPaymentClickEventName,
          this.commonOfferChosenEventProperties,
        );
      }

      const [{ data }, error] = await this.completePayment(
        this.billingRequest,
        this.paymentType,
        undefined,
        this.data?.isNotClosable || false,
      );
      if (
        this.data.onSuccess &&
        !this.payment.error &&
        (this.paymentMethod || this.isCreditPay) &&
        this.payment.completed &&
        !error
      ) {
        this.addIpToWhitelist();
        this.data.onSuccess(data);
      }
    },
    async getAvailablePeriods(priceId) {
      let currentPrice = this.catalogStore.getPriceById(priceId);

      if (!currentPrice) {
        const [{ data }] = await hBillingRepo.getItemByPrice(priceId, {
          includeArchived: 1,
        });

        currentPrice = data.prices.find((item) => item.id === priceId);
      }

      const availablePeriods = currentPrice.metadata.upgradesTo.filter(
        (price) => price.includes(this.data.upgradeItemId),
      );

      const periods = this.upgradeItem.prices.filter(({ id }) =>
        availablePeriods.includes(id),
      );

      if (this.data.isSale) {
        const filteredPeriods = this.getYearlyPeriods(periods);

        return !filteredPeriods.length ? periods : filteredPeriods;
      }

      return periods;
    },
    getYearlyPeriods(periods) {
      return periods.filter(
        ({ period, periodUnit }) => period === 1 && periodUnit === 'year',
      );
    },
    getAddonPeriods(itemId, addon) {
      const addonItem = this.catalogStore.getAddon(itemId, addon);
      const itemPricePeriod = [...this.data.itemPriceId.split('-')].pop();

      return addonItem.prices.filter(({ id }) => id.includes(itemPricePeriod));
    },
    async getPricingEstimate(coupon, retryAfterCouponError = false, error) {
      const activeCoupon = coupon || this.activeCoupon;

      this.setEstimationLoadingState(true, coupon);

      const alreadyLoadedEstimation = this.loadedEstimations.find(
        ({ id, quantity, coupon: alreadyLoadedCoupon }) =>
          id === this.selectedPlanPeriod.id &&
          quantity === this.quantity &&
          alreadyLoadedCoupon === activeCoupon,
      );

      if (alreadyLoadedEstimation) {
        this.pricingDetails = alreadyLoadedEstimation.pricing;
        this.setEstimationLoadingState(false, coupon);

        return true;
      }

      this.setEstimationLoadingState(true, coupon);

      if (!retryAfterCouponError) {
        this.setEstimationErrorState(false, coupon, error);
      }

      const [{ data }, responseError] = await hBillingRepo.estimatePlanUpgrade(
        this.data.subscriptionId,
        {
          itemPriceId: this.selectedPlanPeriod.id,
          quantity: this.quantity,
          ...this.getCouponRequest(activeCoupon),
        },
        this.data.addon,
      );

      if (responseError) {
        return this.onEstimationError({
          coupon,
          retryCallback: () =>
            this.getPricingEstimate(coupon, true, responseError),
        });
      }

      this.pricingDetails = {
        ...data,
        id: data.items[0].product,
      };

      this.addLoadedEstimation({
        id: this.pricingDetails.id,
        quantity: this.quantity,
        pricing: this.pricingDetails,
        coupon: activeCoupon,
      });

      this.setEstimationLoadingState(false, coupon);

      return true;
    },
    handlePurchasePeriodChange(period) {
      if (period) {
        if (this.data.isEmail && this.selectedPlanPeriod.id) {
          const durationInMonths =
            period.periodUnit === 'year' ? period.period * 12 : period.period;

          this.$amplitudeV2(
            AmplitudeEvent.Email.EMAIL_UPGRADE_DURATION_CHANGED,
            {
              location: this.amplitudeLocation,
              product: this.data.amplitudeContext,
              plan: this.upgradeItem.metadata.plan,
              selectedDuration: durationInMonths,
              isUpsell: 0,
            },
          );
        }

        this.selectedPlanPeriod = period;
      }
      this.getPricingEstimate();
    },
    preselectYearlyPeriod() {
      const yearlyPeriod = this.prices.find(({ id }) => id.includes('1y'));

      if (yearlyPeriod) {
        this.preselectedPeriodId = yearlyPeriod.id;
        this.selectedPlanPeriod = yearlyPeriod;
      }
    },
    trackSeenAmplitude() {
      if (this.amplitudeConfig?.onShowEventName) {
        this.$amplitudeV2(this.amplitudeConfig?.onShowEventName, {
          product: this.data.amplitudeContext,
          upgradeItemId: this.data.upgradeItemId,
          location: this.amplitudeLocation,
          element: 'modal',
          isUpsell: 0,
          plan: this.upgradeItem.metadata.plan,
        });
      }
    },
    async setPrices() {
      this.pricesLoading = true;
      this.prices = !this.data.addon
        ? await this.getAvailablePeriods(this.data.itemPriceId)
        : this.getAddonPeriods(this.data.upgradeItemId, this.data.addon);

      if (this.data.isHosting) {
        this.preselectYearlyPeriod();
      }
      this.pricesLoading = false;
    },
    changeQuantity(event) {
      if (this.data.isEmail) {
        const amplitudeEventName =
          this.mailboxCount < event
            ? AmplitudeEvent.Email.EMAIL_UPGRADE_ADDITIONAL_ACCOUNT_ADDED
            : AmplitudeEvent.Email.EMAIL_UPGRADE_ADDITIONAL_ACCOUNT_REMOVED;

        this.$amplitudeV2(amplitudeEventName, {
          location: this.amplitudeLocation,
          product: this.data.amplitudeContext,
          plan: this.upgradeItem.metadata.plan,
          numberOfAccounts: event,
        });
      }

      this.mailboxCount = Number(event);
      this.getPricingEstimate();
    },
  },
  computed: {
    maxAccountNum() {
      return Email.Limit.MAILBOX_LIMIT - parseInt(this.data?.mailboxCount || 0);
    },
    mailboxCounterMinValue() {
      return 0;
    },
    mailboxCounterTooltipText() {
      return this.$t('The number of accounts cannot be lower than {minValue}', {
        minValue: this.mailboxCounterMinValue,
      });
    },
    quantity() {
      if (this.data.isEmail) {
        return this.mailboxCount + this.data.mailboxCount;
      }

      return 1;
    },
    paymentType() {
      return this.data.addon ? 'purchase-addon' : 'upgrade';
    },
    totalTooltipText() {
      return this.data.totalTooltipText || '';
    },
    billingRequest() {
      const req = {
        customerId: !this.data.addon
          ? this.profileStore.account?.id
          : undefined,
        subscriptionId: this.data.subscriptionId,
        ...this.getCouponRequest(this.activeCoupon),
        itemPriceId: this.pricingDetails.id,
        quantity: this.quantity,
        redirectReturn: this.data.redirect,
        redirectCancel: this.data.redirect,
        transactionDetails: {
          metadata: {
            cvcRefreshRequired: this.isCvcRefreshed,
          },
        },
      };
      if (this.paymentMethod) req.methodId = this.paymentMethod.id;

      return req;
    },
    upgradeItem() {
      return this.catalogStore.getCatalogItemById(this.data.upgradeItemId);
    },
    currentMailboxPrice() {
      const { firstPeriodPrice, period, periodUnit } =
        this.upgradeItem?.prices?.find(
          ({ id }) => id === this.pricingDetails.id,
        ) || {};

      const price = firstPeriodPrice / period;
      const monthlyPrice = price / 12;

      return String(
        periodUnit !== 'month'
          ? this.$currency.format(monthlyPrice, {
              isChargebeePrice: true,
            })
          : this.$currency.format(price, { isChargebeePrice: true }),
      );
    },
    amplitudeConfig() {
      return mapKeyValue(AMPLITUDE_EVENT_MAP, this.data.amplitudeContext);
    },
    amplitudeLocation() {
      return (
        this.data.amplitudeLocation ||
        this.$route.query?.location ||
        this.$route.query?.redirectLocation ||
        this.$route.params?.source ||
        this.$route.query?.source ||
        this.$route.name
      );
    },
    selectedPlanDurationInMonths() {
      return (
        monthsPerPeriodUnit(this.selectedPlanPeriod.periodUnit) *
        this.selectedPlanPeriod.period
      );
    },
    commonOfferChosenEventProperties() {
      return {
        product: this.data.amplitudeContext,
        location: this.amplitudeLocation,
        duration: this.selectedPlanDurationInMonths,
        plan: transformPlanName(this.data.currentPlan),
      };
    },
    ...mapGetters(['getCurrentOrder']),
  },
};
</script>

<style lang="scss">
.arrow-back {
  display: flex;
  margin-bottom: 24px;

  .icon-wrapper .h-icon {
    cursor: pointer;
    margin-right: 14px;
    padding-right: 14px;
    border-right: 1px solid var(--gray-border);
  }
}
</style>
