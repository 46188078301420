import { objectEntries } from '@vueuse/core';

export namespace ExternalUrl {
  export enum Tutorial {
    LITE_SPEED = 'https://www.hostinger.com/tutorials/litespeed-website-optimization-tool/',
  }

  export enum Maintenance {
    STATUS_PAGE = 'https://statuspage.hostinger.com/',
  }

  export enum Registry {
    DOMREG = 'https://www.domreg.lt',
    HOSTMASTER = 'https://self-service.dk-hostmaster.dk/domain',
  }

  export enum PayPal {
    SUPPORT_LINK = 'https://www.paypal.com/uk/cshelp/contact-us',
  }
}

export const HOSTINGER_CONSULTATION = {
  EN: 'https://calendly.com/d/4xf-hky-zrd',
  PT: 'https://calendly.com/d/3w7-drc-q87',
} as const;

export const ELIGIBLE_LANGUAGES_FOR_SALES_CALL = objectEntries(
  HOSTINGER_CONSULTATION,
).map(([lang]) => lang);

export type TEligibleLanguages =
  (typeof ELIGIBLE_LANGUAGES_FOR_SALES_CALL)[number];
