export const STORE_PERSISTENT_KEYS = {
  ACCESS: 'access-store',
  ACCESS_MANAGER: 'access-manager-store',
  COMBINED_DOMAINS: 'combined-domains-store',
  DOMAIN_MANAGEMENT: 'domain-management-store',
  DOMAINS_TABLE: 'domains-table-store',
  EMAIL_VERIFICATION: 'email-verification-store',
  HDOMAIN_RESOURCE: 'hdomain-resource-store',
  HOSTING_SETUP: 'hosting-setup-store',
  HOSTINGER_PRO: 'hostinger-pro-store',
  ONBOARDING_DATA_CENTER: 'onboarding-data-center-store',
  VPS_SERVER: 'vps-server-store',
  VPS_ONBOARDING: 'vps-onboarding-store',
  VPS_TEMPLATE: 'vps-template-store',
  VPS_DATA_CENTER: 'vps-data-center-store',
  VPS_PURCHASE: 'vps-purchase-store',
  WEBSITES: 'websites-store',
  DOMAIN_MOVE: 'domains-move-store',
  H_DOMAINS_STORE: 'h-domains-store',
  H_DOMAINS_ACTIONS_STORE: 'h-domains-actions-store',
  MIGRATIONS_ONBOARDING_STORE: 'migrations-onboarding-store',
  HOSTING_MIGRATIONS_STORE: 'hosting-migrations-store',
  ORDERS_STORE: 'orders-store',
  WIDGETS_STORE: 'widgets-store',
  ONBOARDING_STORE: 'onboarding-store',
  H5G_ONBOARDING_STORE: 'h5g-onboarding-store',
  H5G_WEBSITES_STORE: 'h5g-websites-store',
  WORDPRESS_STORE: 'wordpress-store',
  HOSTING_DASHBOARD_STORE: 'hosting-dashboard-store',
  ONBOARDING_SOURCE_STORE: 'onboarding-source-store',
  LANGUAGE_STORE: 'language-store',
  DOMAIN_CHECKER_STORE: 'domain-checker-store',
  BACKUPS_STORE: 'backups-store',
  WEBSITE_OWNERSHIP_TRANSFER_STORE: 'website-ownership-transfer-store',
  DOMAINS_STATUS_STORE: 'domains-status-store',
  MANAGED_RESOURCES_STORE: 'managed-resources-store',
} as const;
