import { storeToRefs } from 'pinia';

import { useProPanelRoutes } from '@/composables/hostingerPro/useProPanelRoutes';
import { useReferralsPromotion } from '@/composables/referrals/useReferralsPromotion';
import { useProfile } from '@/composables/useProfile';
import { useReferrals } from '@/composables/useReferrals';
import { useCatalogStore } from '@/stores/catalogStore';
import { useProsumerCartStore } from '@/stores/proPanel/prosumerCartStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import type { TBrandId } from '@/types';
import { Service, HBilling, Client, Referral } from '@/types';
import { monthsPerPeriodUnit } from '@/utils/services/chargebeeCurrencyService';
import currency from '@/utils/services/currencyService';

interface CalculateHostingPurchaseBonusParams {
  price: number;
  tax: number;
  catalogItemId?: string;
  commissionPercentage?: number;
}

const ONE_WEEK_IN_DAYS = 7;

// TODO rename to useReferToPurchase
export const useWebproPurchase = () => {
  const { isProPanelRoute } = useProPanelRoutes();
  const { accountDaysRegistered } = useProfile();
  const prosumerCartStore = useProsumerCartStore();
  const catalogStore = useCatalogStore();
  const profileStore = useProfileStore();
  const { activePromotion } = useReferralsPromotion();

  const { referralDiscountRate } = useReferrals();

  const { isPurchaseForClient } = storeToRefs(prosumerCartStore);

  const isEligibleForCommission = (periodUnit: string, period: number) => {
    const isOneMonthPurchase =
      monthsPerPeriodUnit(periodUnit) * Number(period) === 1;

    return !isOneMonthPurchase;
  };

  const getCommissionPercentage = (catalogItemId = '') => {
    const isEligibleBrandForSpecialOffer =
      checkBrandEligibilityForSpecialOffer();

    if (!isEligibleBrandForSpecialOffer) {
      return Referral.CommissionRate.COMMISSION_20;
    }

    if (
      activePromotion.value &&
      getIsProductEligibleByPromotionType[activePromotion.value?.promotionType](
        catalogItemId,
      )
    ) {
      return activePromotion.value?.commissionRate;
    }

    return Referral.CommissionRate.COMMISSION_20;
  };

  const checkIsVpsAndCloudOfferActive = (catalogItemId = '') => {
    const { category, subcategory } =
      catalogStore.getCatalogItemById(catalogItemId) || {};

    const isAccountCreatedMoreThan7DaysAgo =
      accountDaysRegistered.value >= ONE_WEEK_IN_DAYS;

    const isEligibleForSpecialOffer = isVpsOrCloudHostingProduct(
      category,
      subcategory,
    );

    return isAccountCreatedMoreThan7DaysAgo && isEligibleForSpecialOffer;
  };

  const isVpsOrCloudHostingProduct = (
    category: string = '',
    subcategory: string = '',
  ) => {
    const isVps = category === HBilling.CatalogCategory.VPS;
    const isCloudHosting =
      category === HBilling.CatalogCategory.HOSTING &&
      subcategory === HBilling.CatalogSubcategory.CLOUD;

    return isVps || isCloudHosting;
  };

  const getIsProductEligibleByPromotionType = {
    [Referral.LimitedTimeOfferCampaignTypeKey.CLOUD_VPS]:
      checkIsVpsAndCloudOfferActive,
    [Referral.LimitedTimeOfferCampaignTypeKey.ALL_PLANS]: () => true,
  };

  const checkBrandEligibilityForSpecialOffer = () => {
    const ineligibleBrandIds: TBrandId[] = [
      Client.BrandId.Hostinger.RU,
      Client.BrandId.Other.WEBLINK,
      Client.BrandId.Other.HOSTMANIA,
      Client.BrandId.Other.HOSTING24,
      Client.BrandId.Other.NG_CO_ID,
    ];

    return !ineligibleBrandIds.includes(profileStore.brandId as TBrandId);
  };

  const calculateHostingPurchaseBonus = ({
    price,
    tax,
    catalogItemId,
    commissionPercentage,
  }: CalculateHostingPurchaseBonusParams) => {
    const finalAmount = price - tax;
    const discount = finalAmount * referralDiscountRate.value;
    const finalAmountAfterDiscount = finalAmount - discount;
    const percentage =
      commissionPercentage || getCommissionPercentage(catalogItemId);
    const referralAmount = (finalAmountAfterDiscount / 100) * percentage;

    return currency.format(referralAmount);
  };

  const getProPurchaseModalData = (product: Service.Type) => {
    if (product === Service.Type.VPS) {
      return {
        isPurchaseForClient: isPurchaseForClient.value && isProPanelRoute.value,
        isReferralCommissionSnackbarVisible: true,
      };
    }
  };

  return {
    isEligibleForCommission,
    calculateHostingPurchaseBonus,
    getProPurchaseModalData,
    getCommissionPercentage,
    checkBrandEligibilityForSpecialOffer,
  };
};
