import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useProfileStore } from '@/stores/profile/profileStore';
import {
  GOOGLE_ANALYTICS_EVENTS,
  Route,
  VPS_PURCHASE_STEP,
  type CatalogItem,
  type Currency,
  type HostingPlan,
  type PlanWithPricingDetailsAndPosition,
} from '@/types';
import { convertToEur } from '@/utils/helpers/currencyHelpers';

const ROUTES_TO_SEND_GTM = [
  Route.Base.BUY_HOSTING,
  Route.Vps.VPS_PLANS,
  Route.Base.BUY_VPS,
  VPS_PURCHASE_STEP.GET_PLAN,
] as string[];

export const useGoogleAnalyticsPurchaseEvents = () => {
  const profileStore = useProfileStore();
  const route = useRoute();
  const path = computed(() =>
    route.path
      .substring(1, route.path.length)
      .replaceAll('/', '_')
      .replaceAll('-', '_'),
  );
  const shouldSendGAEvents = computed(() =>
    ROUTES_TO_SEND_GTM.includes(route.name as string),
  );

  const brandDomain = computed(() => profileStore.account?.brand?.domain ?? '');

  const getItemVariant = (catalogItem: CatalogItem | HostingPlan): string => {
    const idSplit = catalogItem.id.split('-');
    idSplit.reverse();

    return `${catalogItem.id} - ${idSplit[0]}`;
  };

  const getItemListId = (subCategories: string[]): string => {
    if (!subCategories.length) {
      return '';
    }

    if (subCategories.length === 1) {
      return `hpanel_${path.value}_pricing_table-${subCategories[0]}`;
    }

    return `hpanel_${path.value}_pricing_table-${subCategories.join(
      '_and_',
    )}_grouped`;
  };

  const toUppercaseFirstLetter = (word: string): string =>
    word.charAt(0).toUpperCase() + word.slice(1);

  const getItemListName = (subCategories: string[]): string => {
    const formattedPath = path.value
      .split('_')
      .map(toUppercaseFirstLetter)
      .join(' ')
      .trim();
    const formattedSubCategories = subCategories.map(toUppercaseFirstLetter);

    if (!subCategories.length) {
      return '';
    }

    if (subCategories.length === 1) {
      return `hPanel ${formattedSubCategories[0]} ${formattedPath} Pricing Table`;
    }

    return `hPanel ${formattedSubCategories.join(
      ' And ',
    )} Grouped ${formattedPath} Pricing Table`;
  };

  const getListNameAndId = (subCategories: string[]) => ({
    itemListId: getItemListId(subCategories),
    itemListName: getItemListName(subCategories),
  });

  const mapEcommerceEventItem = (
    item: PlanWithPricingDetailsAndPosition,
    itemListId: string,
    itemListName: string,
  ) => {
    const { pricingDetails } = item;

    const formattedPrice = convertToEur(
      pricingDetails.price,
      item.prices[0].currencyCode as Currency.Code,
    );
    const formattedRenewalPrice = convertToEur(
      pricingDetails.renewalPrice,
      item.prices[0].currencyCode as Currency.Code,
    );

    return {
      item_id: item.slug,
      item_name: item.externalName,
      coupon: pricingDetails?.coupon ?? '',
      index: item.position,
      price: formattedPrice.amount,
      discount: pricingDetails.discountPercentage,
      item_brand: brandDomain.value,
      item_category: item.category,
      item_category2: item.subcategory,
      item_category3: pricingDetails.period,
      item_category4: formattedRenewalPrice.amount,
      item_list_id: itemListId,
      item_list_name: itemListName,
      quantity: 1,
      item_variant: getItemVariant(item),
      currency: formattedPrice.currency,
    };
  };

  const getGAViewItemListEvent = (
    items: PlanWithPricingDetailsAndPosition[],
  ) => {
    const { itemListId, itemListName } = getListNameAndId([
      ...new Set(items.map((item) => item.subcategory)),
    ]);

    return {
      event: GOOGLE_ANALYTICS_EVENTS.VIEW_ITEM_LIST,
      hostname: brandDomain.value,
      item_list_id: itemListId,
      item_list_name: itemListName,
      ecommerce: {
        items: items.map((item) =>
          mapEcommerceEventItem(item, itemListId, itemListName),
        ),
      },
    };
  };

  const getGAViewItemEvent = (
    item: PlanWithPricingDetailsAndPosition,
    subCategories: string[],
  ) => {
    const { itemListId, itemListName } = getListNameAndId(subCategories);
    const { pricingDetails } = item;
    const formattedPrice = convertToEur(
      pricingDetails.price,
      item.prices[0].currencyCode as Currency.Code,
    );

    return {
      event: GOOGLE_ANALYTICS_EVENTS.VIEW_ITEM,
      hostname: brandDomain.value,
      currency: formattedPrice.currency,
      value: formattedPrice.amount,
      ecommerce: {
        items: [mapEcommerceEventItem(item, itemListId, itemListName)],
      },
    };
  };

  const getGAAddToCartEvent = (
    items: PlanWithPricingDetailsAndPosition,
    subCategories: string[],
  ) => ({
    ...getGAViewItemEvent(items, subCategories),
    event: GOOGLE_ANALYTICS_EVENTS.ADD_TO_CART,
  });

  const getGAViewCartEvent = (
    item: PlanWithPricingDetailsAndPosition,
    subCategories: string[],
  ) => ({
    ...getGAViewItemEvent(item, subCategories),
    event: GOOGLE_ANALYTICS_EVENTS.VIEW_CART,
  });

  const getGABeginCheckoutEvent = (
    item: PlanWithPricingDetailsAndPosition,
    subCategories: string[],
  ) => ({
    ...getGAViewItemEvent(item, subCategories),
    coupon: item.pricingDetails?.coupon ?? '',
    event: GOOGLE_ANALYTICS_EVENTS.BEGIN_CHECKOUT,
  });

  const getGASelectEvent = (
    item: PlanWithPricingDetailsAndPosition,
    subCategories: string[],
  ) => ({
    ...getGAViewItemEvent(item, subCategories),
    event: GOOGLE_ANALYTICS_EVENTS.SELECT_ITEM,
  });

  return {
    shouldSendGAEvents,
    getGAViewItemListEvent,
    getGAViewItemEvent,
    getGAAddToCartEvent,
    getGAViewCartEvent,
    getGABeginCheckoutEvent,
    getGASelectEvent,
  };
};
