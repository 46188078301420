import { toUnicode } from 'punycode';

import { useTimeoutPoll } from '@vueuse/core';
import { computed } from 'vue';

import { useDeveloperToolsWebsites } from '@/composables/developerTools/useDeveloperToolsWebsites';
import { useGlobals } from '@/composables/useGlobals';
import { reportRepo } from '@/repositories';
import whReportRepo from '@/repositories/whApi/whReportRepo';
import { router } from '@/router';
import { useCreateReportStore } from '@/stores/developerTools/createReportStore';
import { useWebsiteReportsStore } from '@/stores/developerTools/websiteReportsStore';
import { useWordpressStore } from '@/stores/wordpressStore';
import { ScheduleTypeParam, ReportState, ScheduleType, Route } from '@/types';

export const useWebsiteReportActions = () => {
  const { t, toastr } = useGlobals();
  const wordpressStore = useWordpressStore();
  const createReportStore = useCreateReportStore();
  const websiteReportsStore = useWebsiteReportsStore();
  const { enabledDeveloperToolsWebsites } = useDeveloperToolsWebsites();

  const IS_TEMPLATES_FEATURE_ENABLED = false;
  const IS_WEBSITE_ADDRESS_ENABLED = false;

  const SCHEDULE_TYPE_PARAM_MAP = {
    [ScheduleType.MONTHLY]: ScheduleTypeParam.DAY_OF_WEEK,
    [ScheduleType.WEEKLY]: ScheduleTypeParam.DAY_OF_MONTH,
  };

  const SCHEDULE_TYPE_MAP = {
    [ScheduleTypeParam.DAY_OF_WEEK]: ScheduleType.WEEKLY,
    [ScheduleTypeParam.DAY_OF_MONTH]: ScheduleType.MONTHLY,
  };

  const formattedWebsitesList = computed(() => {
    if (!enabledDeveloperToolsWebsites.value.length) {
      return [{ label: '', value: '' }];
    }

    return enabledDeveloperToolsWebsites.value.map(({ website }) => ({
      label: toUnicode(website),
      value: website,
    }));
  });

  const fetchReportState = async () => {
    const website = wordpressStore.getManagedAndOwnedWordpressWebsiteByDomain(
      createReportStore.details.domain || '',
    );
    if (!website) {
      pausePollingReportState();

      return Promise.resolve();
    }

    const params = {
      directory: website.directory,
    };

    const headers = createReportStore.reportRequestHeaders;
    if (!headers) return;

    const [{ data }, err] = await reportRepo.getReportState({
      params,
      headers,
    });

    if (err || data.state === ReportState.FAILED) {
      pausePollingReportState();

      return;
    }

    if (data.state === ReportState.READY) {
      pausePollingReportState();
      fetchReportFile(data.documentKey);
    }
  };

  const { pause: pausePollingReportState } = useTimeoutPoll(
    fetchReportState,
    1000,
  );

  const fetchReportFile = (key: string) => {
    const fileLink = `${process.env.VITE_API_REST}/v3/report/website/download?document_key=${key}`;
    window.open(fileLink, '_blank');
  };

  const getScheduleTypeParamFromOption = (
    type: ScheduleType.WEEKLY | ScheduleType.MONTHLY,
  ) => SCHEDULE_TYPE_PARAM_MAP[type];

  const getScheduleTypeOptionFromParam = (
    type: ScheduleTypeParam.DAY_OF_MONTH | ScheduleTypeParam.DAY_OF_WEEK,
  ) => SCHEDULE_TYPE_MAP[type];

  const createReport = async (isDownloadFlow = false) => {
    const headers = createReportStore.reportRequestHeaders;
    const mainParams = createReportStore.getReportsMainParams();

    if (!headers || !mainParams) return false;

    const params = {
      ...mainParams,
      ...(isDownloadFlow && { deliverToEmail: null }),
    };

    const [_, err] = await reportRepo.postGenerateReport({
      params,
      headers,
    });

    if (err) return false;

    return true;
  };

  const sendReportToClient = async (email: string) => {
    createReportStore.set('deliverToEmail', email);
    const isSuccessful = await createReport();
    if (!isSuccessful) return false;
    createReportStore.$reset();

    return true;
  };

  const scheduleReport = async (
    scheduleType: ScheduleTypeParam,
    scheduleValue: string | null | number,
    scheduleHour: string | number,
  ) => {
    const scheduleParams = {
      scheduleType,
      scheduleValue,
      scheduleHour,
    };

    const params = createReportStore.getReportsMainParams();
    const headers = createReportStore.reportRequestHeaders;
    if (!headers || !params) return false;

    const [_, err] = await reportRepo.postSchedule({
      params: { ...params, ...scheduleParams },
      headers,
    });

    if (err) return false;

    return true;
  };

  const editSchedule = async (
    id: number,
    scheduleType: ScheduleTypeParam,
    scheduleValue: number | null,
    scheduleHour: number,
  ) => {
    const scheduleParams = {
      scheduleType,
      scheduleValue,
      scheduleHour,
    };

    const params = createReportStore.getReportsMainParams(true);
    const headers = createReportStore.reportRequestHeaders;
    if (!headers || !params) return false;

    const [_, err] = await whReportRepo.editSchedule({
      id,
      data: { ...params, ...scheduleParams },
    });

    if (err) return false;

    return true;
  };

  const displayReportGeneratingToastr = (domain: string) => {
    toastr.s(
      t('v2.reports.generating.report.for.website', {
        domain: toUnicode(domain),
      }),
      {
        text: t('v2.reports.generating.report.for.website.subtitle', {
          number: 2,
        }),
        timeout: 15000,
        icon: 'icon-timelapse',
        iconType: 'primary',
      },
    );
  };

  const showSentViaEmailToastrAndRefetchData = ({
    domain,
    email,
  }: {
    domain: string;
    email: string;
  }) => {
    websiteReportsStore.fetchReportList();

    toastr.s(
      t('v2.reports.report.sent.to.email', {
        domain: toUnicode(domain),
        email,
      }),
    );
  };

  const showGeneratedToastrAndRefetchData = ({
    domain,
    documentKey,
  }: {
    domain: string;
    documentKey: string;
  }) => {
    websiteReportsStore.fetchReportList();

    toastr.s(
      t('v2.reports.report.downloaded.successfully', {
        domain: toUnicode(domain),
      }),
      {
        timeout: 15000,
        forceTimeout: true,
        buttons: [
          {
            text: t('v2.preview'),
            props: {
              primary: true,
              text: true,
              'data-qa': 'hpanel_tracking-websites-preview-pdf',
            },
            callback: () => fetchReportFile(documentKey),
          },
        ],
      },
    );
  };

  const exitCreateReportFlow = () => {
    createReportStore.$reset();
    router.push({ name: Route.DeveloperTools.REPORTS });
  };

  return {
    IS_TEMPLATES_FEATURE_ENABLED,
    IS_WEBSITE_ADDRESS_ENABLED,
    formattedWebsitesList,
    getScheduleTypeParamFromOption,
    getScheduleTypeOptionFromParam,
    fetchReportFile,
    createReport,
    scheduleReport,
    editSchedule,
    sendReportToClient,
    displayReportGeneratingToastr,
    showSentViaEmailToastrAndRefetchData,
    showGeneratedToastrAndRefetchData,
    exitCreateReportFlow,
  };
};
