import type { ChargebeeCancelReason } from './chargebeeModels';
import type { BillingSubscription } from './subscriptionModels';

import type { DeveloperToolType } from '@/types/enums';
import { Hosting } from '@/types/enums';

export const HRESOURCES_STATE = {
  ACTIVE: 'active',
  PENDING: 'pending_setup',
  UPDATING: 'updating',
  FAILED: 'failed',
  SUSPENDED: 'suspended',
  SUSPENDING: 'suspending',
  ACTIVATING: 'activating',
  COMPLETED: 'completed',
  CREATED: 'created',
  UNSUSPENDING: 'unsuspending',
  DELETED: 'deleted',
  DELETING: 'deleting',
  DESTROYED: 'destroyed',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
  LOADING: 'loading',
} as const;

export const HRESOURCES_TYPE = {
  VIRTUAL_MACHINE: 'virtual_machine',
  EMAIL: 'email',
  GOOGLE_WORKSPACE: 'google_workspace',
  TITAN_MAIL: 'titan_mail',
  DOMAIN: 'domain',
  DOMAIN_TRANSFER: 'domain_transfer',
  FREE_DOMAIN: 'free_domain',
  FREE_DOMAIN_TRANSFER: 'free_domain_transfer',
  CPANEL_RESELLER_HOSTING: 'cpanel_reseller_hosting',
  DOMAIN_PRIVACY_PROTECTION: 'domain_privacy_protection',
  HOSTING: 'hosting',
  CPANEL_HOSTING: 'cpanel_hosting',
  PRIORITY_SUPPORT: 'priority_support',
  SSL: 'ssl',
  CLOUDFLARE: 'cloudflare',
  RANKING_COACH: 'ranking_coach',
  WEBSITE_BUILDER: 'website_builder',
  DAILY_BACKUP: 'daily_backup',
  DEVELOPER_TOOLS: 'developer_tools',
} as const;

export const HRESOURCE_ADDON_TYPE = {
  CLOUDFLARE: 'cloudflare',
  DAILY_BACKUP: 'daily_backup',
  DOMAIN_PRIVACY_PROTECTION: 'domain_privacy_protection',
  TITAN_MAIL: 'titan_mail',
  SSL_INSTALL: 'ssl_install',
  MAIL: 'mail',
  VPS_LICENSE: 'vps_license',
} as const;

export const HRESOURCE_ADDON_STATE = {
  PENDING_SETUP: 'pending_setup',
  ACTIVE: 'active',
  CANCELED: 'canceled',
  SUSPENDED: 'suspended',
} as const;

export const HRESOURCE_ITEM_STATE = {
  ACTIVE: 'active',
  DELETED: 'deleted',
} as const;

export const HRESOURCE_ITEM_TYPE = {
  WEBSITE: 'website',
  SUBDOMAIN: 'subdomain',
  PARKED: 'parked',
  ADDON: 'addon',
} as const;

export const ACTIVE_STATES_FOR_ORDERS: HResourceState[] = [
  HRESOURCES_STATE.ACTIVE,
  HRESOURCES_STATE.ACTIVATING,
  HRESOURCES_STATE.SUSPENDING,
  HRESOURCES_STATE.UPDATING,
  HRESOURCES_STATE.CREATED,
  HRESOURCES_STATE.FAILED,
  HRESOURCES_STATE.SUSPENDED,
  HRESOURCES_STATE.PENDING,
  HRESOURCES_STATE.UNSUSPENDING,
];

export const ACTIVE_STATES_FOR_HOMEPAGE: HResourceState[] = [
  HRESOURCES_STATE.ACTIVE,
  HRESOURCES_STATE.ACTIVATING,
  HRESOURCES_STATE.SUSPENDING,
  HRESOURCES_STATE.UPDATING,
  HRESOURCES_STATE.CREATED,
  HRESOURCES_STATE.FAILED,
];

export const ALLOW_UPGRADE_STATES_FOR_HOSTING_ORDER: HResourceState[] = [
  HRESOURCES_STATE.ACTIVE,
  HRESOURCES_STATE.SUSPENDED,
];

export const HOSTING_ORDER_RESOURCE_TYPES: HResourceType[] = [
  HRESOURCES_TYPE.HOSTING,
];

export const VPS_ORDER_RESOURCE_TYPES: HResourceType[] = [
  HRESOURCES_TYPE.VIRTUAL_MACHINE,
];

export const CPANEL_ORDER_RESOURCE_TYPES: HResourceType[] = [
  HRESOURCES_TYPE.CPANEL_HOSTING,
  HRESOURCES_TYPE.CPANEL_RESELLER_HOSTING,
];

export const LIFETIME_HRESOURCE_TYPES: HResourceType[] = [
  HRESOURCES_TYPE.SSL,
  HRESOURCES_TYPE.CLOUDFLARE,
];

export const LOW_LEVEL_PLANS = [
  Hosting.Plan.STARTER,
  Hosting.Plan.PREMIUM,
  Hosting.Plan.WORDPRESS_SINGLE,
  Hosting.Plan.WORDPRESS_PREMIUM,
] as const;

export const NON_RENEWABLE_REASONS = [
  Hosting.CancelledReason.FRAUD,
  Hosting.CancelledReason.INCORRECT_ORDER,
  Hosting.CancelledReason.REFUND,
  Hosting.CancelledReason.STAGING_DELETED,
  Hosting.CancelledReason.WRONG_PRODUCT,
  Hosting.SuspendReason.ABUSE,
] as const;

export const CLOUD_PLANS = [
  Hosting.Plan.CLOUD_ECONOMY,
  Hosting.Plan.CLOUD_PROFESSIONAL,
  Hosting.Plan.CLOUD_ENTERPRISE,
  Hosting.Plan.WORDPRESS_CLOUD_ECONOMY,
  Hosting.Plan.WORDPRESS_CLOUD_PROFESSIONAL,
  Hosting.Plan.WORDPRESS_CLOUD_ECONOMY_SPECIAL,
] as const;

export const BUSINESS_AND_CLOUD_PLANS = [
  Hosting.Plan.BUSINESS,
  Hosting.Plan.CLOUD_ECONOMY,
  Hosting.Plan.CLOUD_PROFESSIONAL,
  Hosting.Plan.CLOUD_ENTERPRISE,
] as const;

export const BUSINESS_OR_ABOVE_PLANS = [
  Hosting.Plan.BUSINESS,
  Hosting.Plan.CLOUD_ECONOMY,
  Hosting.Plan.CLOUD_ENTERPRISE,
  Hosting.Plan.CLOUD_PROFESSIONAL,
  Hosting.Plan.WEBLINK_BUSINESS,
  Hosting.Plan.WOO_PREMIUM,
  Hosting.Plan.WOO_BUSINESS,
  Hosting.Plan.WORDPRESS_CLOUD_ECONOMY,
  Hosting.Plan.WORDPRESS_CLOUD_PROFESSIONAL,
  Hosting.Plan.WORDPRESS_BUSINESS,
  Hosting.Plan.WORDPRESS_BUSINESS_SPECIAL,
  Hosting.Plan.WORDPRESS_CLOUD_ECONOMY_SPECIAL,
  Hosting.Plan.HOSTINGER_BUSINESS_TRIAL,
] as const;

export const AMAZON_AFFILIATE_ELIGIBLE_PLANS = [
  Hosting.Plan.BUSINESS,
  Hosting.Plan.CLOUD_ECONOMY,
  Hosting.Plan.CLOUD_PROFESSIONAL,
  Hosting.Plan.CLOUD_ENTERPRISE,
  Hosting.Plan.WORDPRESS_BUSINESS,
  Hosting.Plan.WORDPRESS_CLOUD_ECONOMY,
  Hosting.Plan.WORDPRESS_CLOUD_PROFESSIONAL,
] as const;

export type HResourceType =
  | (typeof HRESOURCES_TYPE)[keyof typeof HRESOURCES_TYPE];

export type HResourceTypeArray = ReadonlyArray<HResourceType>;

export type HResourceState =
  (typeof HRESOURCES_STATE)[keyof typeof HRESOURCES_STATE];

export type HResourceItemState =
  (typeof HRESOURCE_ITEM_STATE)[keyof typeof HRESOURCE_ITEM_STATE];

export type HResourceItemType =
  (typeof HRESOURCE_ITEM_TYPE)[keyof typeof HRESOURCE_ITEM_TYPE];

export type HResourceAddonType =
  (typeof HRESOURCE_ADDON_TYPE)[keyof typeof HRESOURCE_ADDON_TYPE];

export type HResourceAddonState =
  (typeof HRESOURCE_ADDON_STATE)[keyof typeof HRESOURCE_ADDON_STATE];

export interface IHResourceItem {
  id: number;
  resourceId: number;
  type: HResourceItemType;
  state: HResourceItemState;
  domain: string;
  config: [] | null;
  createdAt: string;
  updatedAt: string;
  resource: IHResource;
}

export interface IHResourceAddon {
  id: number;
  resourceId: number;
  type: HResourceAddonType;
  config: [] | null;
  state: HResourceAddonState;
  createdAt: string;
  updatedAt: string;
}

export interface IHResourceMetadata {
  country?: string;
  orderId?: string;
  expiresAt?: string;
  invoiceNr?: string;
  domain?: string;
  planCode?: string;
  planTitle?: string;
  planName?: string;
  isChargebee?: 1 | 0;
  currencyCode?: string;
  customerName?: string;
  billingPeriod?: string;
  customerEmail?: string;
  resourceVersion?: number;
}

export interface IHResourceConfig {
  plan?: Hosting.Plan;
  emailPlan?: string;
  domain?: string;
  username?: string;
  tlds?: string[];
  h5g?: boolean;
  tools?: {
    name: DeveloperToolType;
    maxWebsiteQuantity: number;
  }[];
}

export interface IHResource {
  id: number;
  customerCustomId: string;
  userId: number;
  referenceId: string | null;
  relatedResourceId: number | null;
  idempotencyKey: string | null;
  chargebeeSubscriptionId: string | null;
  type: HResourceType;
  state: HResourceState;
  reason: ChargebeeCancelReason | null;
  domain?: string;
  title: string | null;
  config?: IHResourceConfig;
  metadata?: IHResourceMetadata;
  subscription?: BillingSubscription;
  expiresAt: string | null;
  activatedAt: string | null;
  suspendedAt: string | null;
  createdAt: string;
  updatedAt: string;
  items: IHResourceItem[];
  addons: IHResourceAddon[];
  parent: IHResource | null;
  relatedServices: IHResource[];
  planName?: string;
}

export interface IVpsResource extends IHResource {
  status?: string;
  applicableFor?: string;
  vpsOrderId?: string;
}

// ------------------------------------------ LEGACY MONOLITH RESOURCE MAPS ------------------------------------------- //

export const MONOLITH_RESOURCE_TYPE = {
  HOSTING: 'hosting',
  VPS: 'vps',
  DOMAIN: 'domain',
  DOMAIN_TRANSFER: 'domain_transfer',
  HOSTINGER_MAIL: 'hostinger_mail',
  GOOGLE_GSUITE: 'google_gsuite',
  FLOCK_MAIL: 'flock_mail',
  PRIORITY_SUPPORT: 'priority_support',
  SSL_INSTALL: 'ssl_install',
  CLOUDFLARE: 'cloudflare',
  CPANEL_HOSTING: 'cpanel_hosting',
  CPANEL_RESELLER_HOSTING: 'cpanel_reseller_hosting',
  RANKING_COACH: 'ranking_coach',
  DAILY_BACKUP: 'daily_backup',
} as const;

export const MONOLITH_RESOURCE_STATUS = {
  PENDING_DATA_ENTRY: 'pending_data_entry',
  ACTIVE: 'active',
  IN_PROGRESS: 'in_progress',
  FAILED: 'failed',
  SUSPENDED: 'suspended',
  DELETED: 'deleted',
} as const;

export const HOMEPAGE_SECTION = {
  DOMAIN: 'Domain',
  HOSTING: 'Hosting',
  VPS: 'VPS',
  EMAIL: 'Email',
  GOOGLE_WORKSPACE: 'Google Workspace',
  SEO: 'SEO',
  DOMAIN_TRANSFER: 'Domain Transfer',
} as const;

export const HRESOURCE_GROUP = {
  DOMAINS: 'domains',
  WEBSITES: 'websites',
  VPS: 'vps',
  EMAILS: 'emails',
} as const;

export type MonolithResourceStatus =
  (typeof MONOLITH_RESOURCE_STATUS)[keyof typeof MONOLITH_RESOURCE_STATUS];

export type MonolithResourceType =
  (typeof MONOLITH_RESOURCE_TYPE)[keyof typeof MONOLITH_RESOURCE_TYPE];

export type HomepageSection =
  (typeof HOMEPAGE_SECTION)[keyof typeof HOMEPAGE_SECTION];

export type HResourceGroup =
  (typeof HRESOURCE_GROUP)[keyof typeof HRESOURCE_GROUP];

export const RESOURCE_TO_MONOLITH_TYPE_MAP = {
  [HRESOURCES_TYPE.HOSTING]: MONOLITH_RESOURCE_TYPE.HOSTING,
  [HRESOURCES_TYPE.VIRTUAL_MACHINE]: MONOLITH_RESOURCE_TYPE.VPS,
  [HRESOURCES_TYPE.DOMAIN]: MONOLITH_RESOURCE_TYPE.DOMAIN,
  [HRESOURCES_TYPE.FREE_DOMAIN]: MONOLITH_RESOURCE_TYPE.DOMAIN,
  [HRESOURCES_TYPE.FREE_DOMAIN_TRANSFER]:
    MONOLITH_RESOURCE_TYPE.DOMAIN_TRANSFER,
  [HRESOURCES_TYPE.DOMAIN_TRANSFER]: MONOLITH_RESOURCE_TYPE.DOMAIN_TRANSFER,
  [HRESOURCES_TYPE.EMAIL]: MONOLITH_RESOURCE_TYPE.HOSTINGER_MAIL,
  [HRESOURCES_TYPE.GOOGLE_WORKSPACE]: MONOLITH_RESOURCE_TYPE.GOOGLE_GSUITE,
  [HRESOURCES_TYPE.TITAN_MAIL]: MONOLITH_RESOURCE_TYPE.FLOCK_MAIL,
  [HRESOURCES_TYPE.PRIORITY_SUPPORT]: MONOLITH_RESOURCE_TYPE.PRIORITY_SUPPORT,
  [HRESOURCES_TYPE.SSL]: MONOLITH_RESOURCE_TYPE.SSL_INSTALL,
  [HRESOURCES_TYPE.CLOUDFLARE]: MONOLITH_RESOURCE_TYPE.CLOUDFLARE,
  [HRESOURCES_TYPE.CPANEL_HOSTING]: MONOLITH_RESOURCE_TYPE.CPANEL_HOSTING,
  [HRESOURCES_TYPE.RANKING_COACH]: MONOLITH_RESOURCE_TYPE.RANKING_COACH,
  [HRESOURCES_TYPE.CPANEL_RESELLER_HOSTING]:
    MONOLITH_RESOURCE_TYPE.CPANEL_RESELLER_HOSTING,
} as Record<HResourceType, MonolithResourceType>;

export const HOMEPAGE_RESOURCE_TO_SECTION_MAP = {
  [HRESOURCES_TYPE.DOMAIN]: HOMEPAGE_SECTION.DOMAIN,
  [HRESOURCES_TYPE.HOSTING]: HOMEPAGE_SECTION.HOSTING,
  [HRESOURCES_TYPE.CPANEL_HOSTING]: HOMEPAGE_SECTION.HOSTING,
  [HRESOURCES_TYPE.CPANEL_RESELLER_HOSTING]: HOMEPAGE_SECTION.HOSTING,
  [HRESOURCES_TYPE.VIRTUAL_MACHINE]: HOMEPAGE_SECTION.VPS,
  [HRESOURCES_TYPE.EMAIL]: HOMEPAGE_SECTION.EMAIL,
  [HRESOURCES_TYPE.TITAN_MAIL]: HOMEPAGE_SECTION.EMAIL,
  [HRESOURCES_TYPE.GOOGLE_WORKSPACE]: HOMEPAGE_SECTION.GOOGLE_WORKSPACE,
  [HRESOURCES_TYPE.RANKING_COACH]: HOMEPAGE_SECTION.SEO,
  [HRESOURCES_TYPE.DOMAIN_TRANSFER]: HOMEPAGE_SECTION.DOMAIN_TRANSFER,
} as Record<HResourceType, HomepageSection>;

export const HRESOURCE_TO_GROUP_MAP = {
  [HRESOURCES_TYPE.DOMAIN]: HRESOURCE_GROUP.DOMAINS,
  [HRESOURCES_TYPE.VIRTUAL_MACHINE]: HRESOURCE_GROUP.VPS,
  [HRESOURCES_TYPE.EMAIL]: HRESOURCE_GROUP.EMAILS,
  [HRESOURCES_TYPE.TITAN_MAIL]: HRESOURCE_GROUP.EMAILS,
} as Record<HResourceType, HResourceGroup>;

export const RESOURCE_STATE_TO_MONOLITH_STATUS_MAP = {
  [HRESOURCES_STATE.PENDING]: MONOLITH_RESOURCE_STATUS.PENDING_DATA_ENTRY,
  [HRESOURCES_STATE.ACTIVE]: MONOLITH_RESOURCE_STATUS.ACTIVE,
  [HRESOURCES_STATE.UPDATING]: MONOLITH_RESOURCE_STATUS.IN_PROGRESS,
  [HRESOURCES_STATE.FAILED]: MONOLITH_RESOURCE_STATUS.FAILED,
  [HRESOURCES_STATE.SUSPENDED]: MONOLITH_RESOURCE_STATUS.SUSPENDED,
  [HRESOURCES_STATE.SUSPENDING]: MONOLITH_RESOURCE_STATUS.IN_PROGRESS,
  [HRESOURCES_STATE.ACTIVATING]: MONOLITH_RESOURCE_STATUS.IN_PROGRESS,
  [HRESOURCES_STATE.CREATED]: MONOLITH_RESOURCE_STATUS.FAILED,
  [HRESOURCES_STATE.UNSUSPENDING]: MONOLITH_RESOURCE_STATUS.IN_PROGRESS,
  [HRESOURCES_STATE.DELETED]: MONOLITH_RESOURCE_STATUS.DELETED,
} as Record<HResourceState, MonolithResourceStatus>;

export const SUBSCRIPTION_TITLE_MAP = {
  [HRESOURCES_TYPE.SSL]: 'SSL Certificate Activation',
  [HRESOURCES_TYPE.CLOUDFLARE]: 'Cloudflare Protection',
} as Record<HResourceType, string>;

//------------------------------------------------------------------------------------------//

export interface IGetHResourcesRequest {
  type?: HResourceType[];
  state?: HResourceState[];
  subscriptionId?: string;
  referenceId?: string | number;
  status?: string;
}
