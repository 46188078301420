import type {
  IReportStateResponse,
  IGetReportStateRequestData,
  IGenerateReportRequestData,
  IReportsListRes,
  ICreateReportRequestParams,
  ReportHeaders,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v3/report`,
  whApiUrl: `${process.env.VITE_API_WH_API}/api/hapi/v1`,

  async postGenerateReport({ params, headers }: IGenerateReportRequestData) {
    return await hAsync(
      http.post(`${this.url}/website/generate`, params, {
        headers: { ...headers },
      }),
    );
  },

  async getReportState({ params, headers }: IGetReportStateRequestData) {
    return await hAsync<IReportStateResponse>(
      http.get(`${this.url}/website/state`, {
        params: { directory: params.directory },
        headers: {
          ...headers,
        },
      }),
    );
  },

  async postSchedule({
    params,
    headers,
  }: {
    params: ICreateReportRequestParams;
    headers: ReportHeaders;
  }) {
    return await hAsync(
      http.post(`${this.url}/website/schedule`, params, {
        headers,
      }),
    );
  },

  async getList(params: { page: number; perPage: number }) {
    return await hAsync<IReportsListRes>(
      http.get(`${this.url}/website/list`, {
        params,
      }),
    );
  },

  async deleteReport(params: { reportId: string }) {
    return await hAsync(
      http.delete(`${this.url}/website`, {
        params,
      }),
    );
  },
};
