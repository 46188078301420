import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import whReportRepo from '@/repositories/whApi/whReportRepo';
import type { IReportsScheduledListItem } from '@/types';
import { getItemsPerPage } from '@/utils/services/paginationService';

const INITIAL_PAGE = 1;

export const useScheduledReportsStore = defineStore(
  'scheduledReportsStore',
  () => {
    const list = ref<Array<IReportsScheduledListItem> | null>(null);
    const totalListItems = ref(0);
    const isLoadingList = ref(false);
    const isDeleting = ref(false);
    const page = ref<number | null>(null);
    const perPage = ref<number | null>(null);

    const hasListBeenFetched = computed(() => list.value !== null);
    const hasScheduledReports = computed(() => !!list.value?.length);

    const $reset = () => {
      list.value = null;
      totalListItems.value = 0;
      isLoadingList.value = false;
      isDeleting.value = false;
      page.value = null;
      perPage.value = null;
    };

    const fetchListWithCurrentPagination = async () =>
      await fetchScheduledReportsList({
        page: page.value || INITIAL_PAGE,
        perPage: perPage.value || getItemsPerPage(),
      });

    const fetchScheduledReportsList = async (
      params: { page: number; perPage: number } = {
        page: INITIAL_PAGE,
        perPage: getItemsPerPage(),
      },
    ) => {
      isLoadingList.value = true;

      const [{ data }, error] = await whReportRepo.getScheduledReportsList(
        params,
      );

      if (error) {
        isLoadingList.value = false;

        return;
      }
      list.value = data.resources;
      totalListItems.value = data.pagination.total;
      page.value = data.pagination.page;
      perPage.value = data.pagination.perPage;

      isLoadingList.value = false;
    };

    const deleteSchedule = async (id: number) => {
      isDeleting.value = true;
      const [, err] = await whReportRepo.deleteSchedule({ id });
      isDeleting.value = false;

      return !!err;
    };

    const getScheduleById = (id: number) =>
      list.value?.find((item) => item.id === id);

    return {
      list,
      hasListBeenFetched,
      hasScheduledReports,
      totalListItems,
      isLoadingList,
      isDeleting,
      page,
      perPage,
      deleteSchedule,
      $reset,
      fetchScheduledReportsList,
      fetchListWithCurrentPagination,
      getScheduleById,
    };
  },
  {
    persist: { key: 'scheduled-reports-store' },
  },
);
