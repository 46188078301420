import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useResourceFilters } from '@/composables';
import {
  PAYPAL_COMMISSION_THRESHOLD,
  WIRE_TRANSFER_COMMISSION_THRESHOLD,
} from '@/data/globalConstants';
import { amplitudeV2 } from '@/plugins/amplitudeV2';
import referralRepo from '@/repositories/referralRepo';
import { useProfileStore, useReferralStore, useWordpressStore } from '@/stores';
import { AmplitudeEvent, Analytics, Referral, Route } from '@/types';
import { isCurrentTimeWithinDates, mapKeyValue } from '@/utils/helpers';
import { sendGoogleAnalyticsEvent } from '@/utils/services/google';

export const useReferrals = () => {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const referralStore = useReferralStore();
  const { hasCloudHosting, hasVps } = useResourceFilters();
  const wordpressStore = useWordpressStore();
  const profileStore = useProfileStore();

  const isLoadingAddPayPal = ref(false);
  const currentPaypalEmail = computed(
    () => referralStore.userReferralData?.paypalEmail,
  );
  const availablePaypalEmails = computed(() => referralStore.payPalList);

  const addPaypalPaymentMethod = async (
    currentPayoutMethod?: Referral.PayoutType,
  ) => {
    isLoadingAddPayPal.value = true;

    const redirectPath = route.fullPath;
    const redirectRoute = `${redirectPath}?refetch=1`;
    const actionPayload = {
      redirectCancel: redirectRoute,
      redirectReturn: redirectRoute,
      merchantAccounts: ['braintree_paypal'],
    };

    const redirectUrl = await store.dispatch(
      'paymentMethods/billingAddPaymentMethod',
      actionPayload,
    );
    if (currentPayoutMethod) {
      sendPaypalChangedAmplitudeEvent(currentPayoutMethod);
    }
    location.href = redirectUrl;
    isLoadingAddPayPal.value = false;
  };

  const sendPaypalChangedAmplitudeEvent = (
    currentPayoutMethod: Referral.PayoutType,
  ) => {
    amplitudeV2(
      AmplitudeEvent.Referral.REFERRAL_PAYOUT_METHOD_CHANGE_METHOD_CHANGED,
      {
        currentMethodType: formatPayoutTypeForAmplitude(currentPayoutMethod),
        newMethodType: formatPayoutTypeForAmplitude(Referral.PayoutType.MANUAL),
      },
    );
  };

  const addPaypal = async () => {
    if (!route.query.refetch || currentPaypalEmail.value) {
      return;
    }
    await referralStore.fetchUserReferralData();
    await referralStore.addEmail(availablePaypalEmails.value[0]);
  };

  const getReferralCommissionThreshold = (
    paymentMethod?: Referral.PayoutType,
  ) => {
    if (!paymentMethod) return 0;
    if (paymentMethod === Referral.PayoutType.PAYPAL) {
      return PAYPAL_COMMISSION_THRESHOLD;
    }

    return WIRE_TRANSFER_COMMISSION_THRESHOLD;
  };

  const formatReferralPayoutType = (payoutType?: Referral.PayoutType) => {
    if (!payoutType) return '';
    const payoutTypeMap = {
      [Referral.PayoutType.PAYPAL]: 'PayPal',
      [Referral.PayoutType.MANUAL]: 'Wire Transfer',
      default: 'Wire Transfer',
    };

    return mapKeyValue(payoutTypeMap, payoutType);
  };

  const formatPayoutTypeForAmplitude = (payoutType?: Referral.PayoutType) => {
    if (!payoutType) return 'none';
    const payoutTypeMap = {
      [Referral.PayoutType.PAYPAL]: 'paypal',
      [Referral.PayoutType.MANUAL]: 'wire-transfer',
      default: 'Wire Transfer',
    };

    return mapKeyValue(payoutTypeMap, payoutType);
  };

  const referralsPromotionalBannerVariant = computed<
    Referral.PromotionalBannerVariant | undefined
  >(() => {
    const isOfferActive = isCurrentTimeWithinDates({
      startsAt: '2024-06-24T09:00:00Z',
      endsAt: '2024-06-30T23:59:59Z',
    });
    if (!isOfferActive) {
      return undefined;
    }
    if (hasCloudHosting.value) return Referral.PromotionalBannerVariant.CLOUD;
    if (hasVps.value) return Referral.PromotionalBannerVariant.VPS;

    const hasWordPressWebsites = !!wordpressStore.wordpressApplications.length;
    if (hasWordPressWebsites) {
      return Referral.PromotionalBannerVariant.WORDPRESS;
    }

    const hasBuilderWebsites = store.getters.getHasAccountsWebsiteBuilder;
    if (hasBuilderWebsites) {
      return Referral.PromotionalBannerVariant.WEBSITE_BUILDER;
    }

    return undefined;
  });

  const sendJoinedReferralsAnalyticsEvent = async () => {
    if (!referralStore.userReferralData) {
      return;
    }

    sendGoogleAnalyticsEvent(Analytics.GoogleEvent.JOIN_REFERRALS, {
      referralCode: referralStore.userReferralData.referralCode,
      clientId: profileStore.account?.id,
    });
  };

  const sendReferralsEnterAmplitudeEvent = async () => {
    amplitudeV2(AmplitudeEvent.Referral.REFERRALS_ENTER, {
      location: referralStore.referralsEntryLocation,
      isNewReferralsUser: !referralStore.isReferralRockProgramMember,
    });
  };

  const joinReferralProgram = async () => {
    const [, error] = await referralRepo.setRegistrationPaypalEmail();
    if (error) {
      return await router.replace({
        name: Route.Base.HOME,
      });
    }

    await referralStore.fetchUserReferralData();
    sendJoinedReferralsAnalyticsEvent();
  };

  const referralDiscountRate = computed(
    () =>
      referralStore.userReferralData?.discountRate ??
      Referral.DiscountRate.DISCOUNT_20,
  );

  return {
    isLoadingAddPayPal,
    addPaypal,
    addPaypalPaymentMethod,
    getReferralCommissionThreshold,
    formatReferralPayoutType,
    formatPayoutTypeForAmplitude,
    referralsPromotionalBannerVariant,
    sendJoinedReferralsAnalyticsEvent,
    sendReferralsEnterAmplitudeEvent,
    joinReferralProgram,
    referralDiscountRate,
  };
};
