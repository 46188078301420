export namespace Route {
  export enum Base {
    LOGIN = 'login',
    HOME = 'home',
    HOSTING = 'hosting',
    EMAILS = 'emails',
    HOSTINGER_PRO = 'hostinger_pro',
    HOSTING_V2 = 'hosting_v2',
    WEBSITES = 'websites',
    BUY_VPS = 'buy_vps',
    BUY_HOSTING = 'buy_hosting',
    BUY_RANKING_COACH = 'buy_ranking_coach',
    BUY_WEBSITE_BUILDER = 'buy_website_builder',
    STORE = 'store',
    PAID_INVOICES = 'paid_invoices',
    NOT_FOUND = 'not_found',
    SERVERS = 'servers',
    HOSTING_OFFER = 'hosting_offer',
    HELP = 'help',
    MARKETPLACE = 'marketplace',
    SERVER_DOWN = 'server_down',
    PLAYGROUND = 'playground',
    PROFILE = 'profile',
  }

  export enum Domain {
    DOMAIN_DNS = 'domain-dns',
    DOMAIN_DNS_HISTORY_RESTORE = 'domain-dns-history-restore',
    DOMAIN_MANAGEMENT = 'domain-management',
    DOMAIN_SETTINGS = 'domain-settings',
    DOMAIN_CHECKER = 'domain-checker',
    TRANSFER_DOMAIN = 'transfer-domain',
    TRANSFERS = 'transfers',
    REGISTER_DOMAIN_RESOURCE = 'register-domain-resource',
    DOMAIN_MOVE_REGISTER = 'domain-move-register',
    TRANSFER = 'transfer',
    MY_DOMAINS = 'my-domains',
    TRANSFER_DOMAIN_MANAGEMENT = 'transfer-domain-management',
    EXTERNAL_DOMAIN_DNS = 'external-domain-dns',
    ADD_DOMAIN = 'add-domain',
    ADD_DOMAIN_SELECT = 'add-domain-select',
    ADD_DOMAIN_REGISTER = 'add-domain-register',
    ADD_DOMAIN_COMPLETE = 'add-domain-complete',
  }

  export enum Vps {
    AI_ASSISTANT = 'ai-assistant',
    SERVER_SETTINGS = 'server-settings',
    SERVER_USAGE = 'server-usage',
    SNAPSHOTS_AND_BACKUPS = 'snapshots-&-backups',
    LATEST_ACTIONS = 'latest-actions',
    OPERATING_SYSTEM = 'operating-system',
    LICENSES = 'licenses',
    VPS_HELP = 'vps-help',
    SERVER_OVERVIEW = 'server-overview',
    SERVER_DETAILS = 'server-details',
    VPS_UPGRADE = 'vps-upgrade',
    VPS_PLANS = 'vps-plans',
    SECURITY = 'vps-security',
    FIREWALL = 'vps-firewall',
    FIREWALL_MANAGE = 'vps-firewall-manage',
    MALWARE_SCANNER = 'vps-malware-scanner',
    OS_PANEL = 'vps-os-panel',
    VPS_BACKUP = 'vps-backup',
    ADD_DOMAIN = 'vps-add-domain',
    DNS_MANAGER = 'vps-dns-manager',
    NEW_PURCHASE = 'vps-new-purchase',
  }

  export enum VpsOnboarding {
    SERVER_ONBOARDING = 'server_onboarding',
    SERVER_ONBOARDING_COMPLETED = 'server_onboarding_completed',
    SERVER_SETTING_UP = 'server_onboarding_setting_up',
    SERVER_ONBOARDING_SUMMARY = 'server_onboarding_summary',
    SERVER_ONBOARDING_CREDENTIALS = 'server_onboarding_credentials',
    SERVER_ONBOARDING_CREATE_OS_PASSWORD = 'server_onboarding_create_os-password',
    SERVER_ONBOARDING_OS_RECOMMENDED = 'server_onboarding_os_recommended',
    SERVER_ONBOARDING_OS = 'server_onboarding_os',
    SERVER_ONBOARDING_LOCATION = 'server_onboarding_location',
    SERVER_ONBOARDING_START = 'server_onboarding_start',
    SERVER_ONBOARDING_ADDITIONAL_FEATURES = 'server_onboarding_additional_features',
  }

  export enum Hosting {
    BEING_BUILT = 'being_built',
    DETAILS_V2 = 'hosting_details_v2',
    RESOURCES_USAGE_V2 = 'resource_usage_v2',
    HOSTING_UPGRADE_V2 = 'hosting_upgrade_v2',
    HOSTING_REDIRECT = 'hosting_redirect',
  }

  export enum Websites {
    MIGRATIONS_REQUESTS = 'migrations_requests',
    ADD_MIGRATION = 'add_migration_request',
    EDIT_MIGRATION = 'edit_migration_request',
    CREATE_REPORT = 'create_report',
    CREATING_REPORT = 'creating_report',
    WEBSITES_REDIRECT = 'websites_redirect',
    BUILDER_DASHBOARD = 'builder-dashboard',
    WEBSITE_DASHBOARD = 'website-dashboard',
    AI_CHATBOT = 'ai-chatbot',
  }

  export enum HostingAccount {
    ACCOUNTS = 'accounts',
    DETAILS = 'accounts_details',
    BILLING_CYCLE = 'accounts_billing_cycle',
    GETTING_STARTED = 'accounts_getting_started',
    RENEW = 'accounts_renew',
    TRANSFER_ACCOUNT = 'accounts_transfer_account',
    RELOAD_ACCOUNT = 'accounts_reload_account',
    DEACTIVATE_ACCOUNT = 'accounts_deactivate_account',
  }

  export enum HostingAdvanced {
    ADVANCED = 'advanced',
    CRON_JOBS = 'advanced_cron_jobs',
    SSH_ACCESS = 'advanced_ssh_access',
    GIT = 'advanced_git',
    CACHE_MANAGER = 'advanced_cache_manager',
    PASSWORD_PROTECT_DIRECTORIES = 'advanced_password_protect_directories',
    IP_MANAGER = 'advanced_ip_manager',
    HOTLINK_PROTECTION = 'advanced_hotlink_protection',
    FOLDER_INDEX_MANAGER = 'advanced_folder_index_manager',
    FIX_FILE_OWNERSHIP = 'advanced_fix_file_ownership',
    ACTIVITY_LOG = 'advanced_activity_log',
    PHP_INFO = 'advanced_php_info',
    PHP_CONFIGURATION = 'advanced_php_configuration',
    DNS_ZONE_EDITOR = 'advanced_dns_zone_editor',
  }

  export enum HostingDatabases {
    DATABASES = 'databases',
    MYSQL_DATABASES = 'databases_my_sql_databases',
    PHP_MY_ADMIN = 'databases_php_my_admin',
    REMOTE_MYSQL = 'databases_remote_my_sql',
  }

  export enum HostingDomains {
    DOMAINS = 'hosting-domains',
    SUBDOMAINS = 'domains_subdomains',
    PARKED_DOMAINS = 'domains_parked_domains',
    REDIRECTS = 'domains_redirects',
    ADD_WEBSITE = 'domains_add_website',
    CLOUDFLARE = 'domains_cloudflare',
    SET_MAIN_DOMAIN = 'domains_set_main_domain',
  }

  export enum HostingEmails {
    EMAILS = 'hosting_emails',
    MANAGE = 'emails_manage',
    GSUITE = 'emails_gsuite',
    MAIL_SERVICE_CONTROL = 'emails_mail_service_control',
  }

  export enum HostingFiles {
    FILES = 'files',
    IMPORT_WEBSITE = 'files_import_website',
    CHANGE_PASSWORD = 'files_change_password',
    FILE_MANAGER = 'files_file_manager',
    FTP_ACCOUNTS = 'files_ftp_accounts',
    BACKUPS = 'files_backups',
  }

  export enum HostingOrder {
    ORDER = 'order',
    DETAILS = 'order_details',
    ORDER_USAGE = 'order_order_usage',
    ORDER_USAGE_V2 = 'order_order_usage_v2',
    RENEW = 'order_renew',
  }

  export enum HostingSecurity {
    HOSTING_SECURITY = 'hosting_security',
    SSL = 'security_ssl',
    MALWARE_SCANNER = 'security_malware_scanner',
    WORDPRESS_AUTO_UPDATES = 'security_wordpress_auto_updates',
  }

  export enum HostingWebsite {
    WEBSITE = 'website',
    AUTO_INSTALLER = 'website_auto_installer',
    ERROR_PAGES = 'website_error_pages',
    ERROR_PAGES_EDIT = 'website_error_pages_edit',
    IMPORT_WEBSITE = 'website_import_website',
    WEBSITE_BUILDER = 'website_website_builder',
    BITDEGREE = 'website_bitdegree',
    ADD_WEBSITE = 'website_add_website',
    WORDPRESS_INSTALL = 'website_wordpress_install',
    COPY_WEBSITE = 'website_copy_website',
  }

  export enum HostingPerformance {
    PERFORMANCE = 'performance',
    SEO = 'performance_seo',
    ACCESS_LOGS = 'access_logs',
    CLOUDFLARE = 'performance_cloudflare',
    PAGE_SPEED = 'performance_page_speed',
    CDN = 'performance_cdn',
    CDN_CONTROLS = 'performance_cdn_controls',
    OBJECT_CACHE = 'performance_object_cache',
  }

  export enum HostingWordpress {
    WORDPRESS = 'wordpress',
    WORDPRESS_WORDPRESS = 'wordPress_wordpress',
    LEARN_WITH_US = 'wordpress_learn_with_us',
    STAGING = 'wordpress_staging',
    DASHBOARD = 'wordpress_dashboard',
    INSTALL_NEW_WP = 'install-new-wp',
    INSTALL = 'install',
    SECURITY = 'wordpress_security',
    MANAGE_STAGING = 'manage_wp_staging',
    MANAGE_STAGING_DASHBOARD = 'manage_wp_staging_dashboard',
    MANAGE_STAGING_SECURITY = 'manage_wp_staging_security',
    WORDPRESS_AI_TROUBLESHOOTER = 'wordpress_ai_troubleshooter',
    LITESPEED_SETTINGS = 'litespeed-settings',
    WORDPRESS_PRESETS = 'wordpress_presets',
    WORDPRESS_PRESET_CREATE = 'wordpress_presets_create',
    WORDPRESS_PRESET_EDIT = 'wordpress_presets_edit',
    WORDPRESS_PRESET_SETUP = 'wordpress_presets_setup',
    PRESETS_PREVIEW_WORDPRESS_TEMPLATE = 'presets_preview_wordpress_template',
  }

  export enum Billing {
    INDEX = 'billing-index',
    UNPAID = 'billing-unpaid',
    SERVICES = 'billing-services',
    PAYMENT_HISTORY = 'billing-payment-history',
    PAYMENT_METHODS = 'billing-payment-method',
    PAYMENT_METHOD_DETAILS = 'billing-payment-method-single',
    DISABLE_AUTO_RENEWAL = 'billing-disable-auto-renewal',
    DISABLE_AUTO_RENEWAL_SURVEY = 'billing-disable-auto-renewal-survey',
  }

  export enum HBilling {
    SUBSCRIPTIONS = 'billing-subscriptions',
    SUBSCRIPTION_DETAILS = 'billing-subscriptions-details',
    PAYMENT_HISTORY = 'billing-payment-history-cb',
    PAYMENT_HISTORY_PAID = 'paid-history',
    PAYMENT_HISTORY_DETAILS = 'billing-payment-history-details-cb',
    REFUND_HISTORY = 'refunded-history',
    REFUND_HISTORY_DETAILS = 'billing-payment-history-refund-details-cb',
    PAYMENT_METHODS = 'billing-payment-method-cb',
    PAYMENT_METHOD_DETAILS = 'billing-payment-method-single-cb',
  }

  export enum HRefunds {
    AUTO_REFUND = 'auto_refund',
    AUTO_REFUND_SERVICES = 'auto_refund_services',
    AUTO_REFUND_CONFIRMATION = 'auto_refund_confirmation',
    AUTO_REFUND_SURVEY = 'auto_refund_survey',
    AUTO_REFUND_SUMMARY = 'auto_refund_summary',
  }

  export enum Order {
    ORDER_UPGRADE = 'order_upgrade',
    ORDER_UPGRADE_TYPE = 'order_upgrade_type',
  }

  export enum Onboarding {
    OTHER_PLATFORMS = 'onboarding_other_platforms',
    TOOL = 'onboarding_tool',
    MIGRATION = 'onboarding_migration',
    SELECT_WORDPRESS = 'onboarding_select_wordpress',
    SELECT_WORDPRESS_AI_CONTENT = 'onboarding_select_wordpress_ai_content',
    BUILD_OR_MIGRATE = 'build_or_migrate',
    WEBSITE_TYPE_SURVEY = 'website_type_survey',
    START = 'onboarding_start',
    SURVEY = 'onboarding_survey',
    TOOL_COMPARISON = 'onboarding_tool_comparison',
    RECOMMENDED = 'onboarding_recommended',
    EXISTING_DOMAIN = 'onboarding_existing_domain',
    BUY_NEW_DOMAIN = 'onboarding_buy_new_domain',
    EXISTING_DOMAIN_SUMMARY = 'onboarding_existing_domain-summary',
    CPANEL_PASSWORD = 'onboarding_create_cpanel-password',
    WP_TEMPLATES = 'onboarding_wp_templates',
    SELECT_WORDPRESS_THEME_COLOR = 'onboarding_select_theme_color',
    SELECT_WORDPRESS_THEME_FONT = 'onboarding_select_theme_font',
    CHOOSE_APP_NAME = 'onboarding_choose_app_name',
    OVERVIEW = 'onboarding_overview',
    COMPLETED = 'onboarding_completed',
    DOMAIN_SETUP = 'onboarding_domain_setup',
    DOMAIN_REGISTRATION_COMPLETE = 'onboarding_domain_setup_complete',
    BEING_BUILT = 'being_built',
    BEING_TRANSFERRED = 'being_transferred',
    PREVIEW_TEMPLATE = 'preview_template',
    THEME_CARD_PREVIEW = 'theme_card_preview',
    SELECT_WORDPRESS_PLUGIN = 'onboarding_select_wordpress_plugins',
    SELECT_WORDPRESS_PRESETS = 'onboarding_select_wordpress_presets',
    WEBSITES = 'websites',
    HOSTING_V2 = 'hosting_v2',
    DOMAIN_SURVEY = 'onboarding_domain_survey',
    CPANEL_GETTING_READY = 'onboarding_cpanel_getting_ready',
  }

  export enum OnboardingParam {
    ADDON = 'addon',
  }

  export enum Email {
    EMAILS = 'emails',
    CHOOSE_PLAN = 'hosting_emails_choose_plan',
    UPGRADE_PLAN = 'hosting_emails_upgrade_plan',
    UPSELL = 'hostinger_email_upsell',
    GOOGLE_WORKSPACE_PURCHASE = 'google_workspace_purchase',
    GOOGLE_WORKSPACE_SETUP = 'google_workspace_setup',
    GOOGLE_WORKSPACE_MANAGEMENT = 'google_workspace_management',
    MIGRATE_GSUITE = 'migrate_gsuite',
    EMAIL_ONBOARDING_START = 'email_onboarding_start',
    EMAIL_ONBOARDING_HELLO = 'email_onboarding_hello',
    EMAIL_ONBOARDING_CHOOSE_DOMAIN = 'email_onboarding_choose_domain',
    EMAIL_ONBOARDING_BUY_DOMAIN = 'email_onboarding_buy_domain',
    EMAIL_ONBOARDING_EXISTING_DOMAIN = 'email_onboarding_existing_domain',
    EMAIL_ONBOARDING_SETUP_DOMAIN = 'email_onboarding_setup_domain',
    EMAIL_ONBOARDING_CREATE_ACCOUNT = 'email_onboarding_create_account',
    EMAIL_ONBOARDING_DNS = 'email_onboarding_dns',
    EMAIL_ONBOARDING_CONNECT_DOMAIN = 'email_onboarding_connect_domain',
    EMAIL_ONBOARDING_CONFIRM = 'email_onboarding_confirm',
    EMAIL_ONBOARDING_SUCCESS = 'email_onboarding_success',
    AUTOMATIC_REPLIES = 'email_automatic_replies',
    CREATE_AUTOMATIC_REPLY = 'create_automatic_reply',
    EDIT_AUTOMATIC_REPLY = 'edit_automatic_reply',
    EMAIL_IMPORT = 'email_import',
    ADD_EMAIL_IMPORT_REQUEST = 'add_email_import_request',
    CUSTOM_DKIM = 'custom_dkim',
    VERIFY_CUSTOM_DKIM = 'verify_custom_dkim',
    CREATE_ACCOUNT_START = 'email_create_account_start',
    EMAIL_ACCOUNTS = 'email_accounts',
    EMAIL_OVERVIEW = 'email_overview',
    EMAIL_FORWARDERS = 'email_forwarders',
    EMAIL_ALIAS = 'email_alias',
    EMAIL_SETUP_DEVICES = 'email_setup_devices',
    EMAIL_LOGS = 'email_logs',
    CREATE_ACCOUNT = 'email_create_account',
    CONFIGURE_ACCOUNT = 'email_configure_account',
    EMAIL_DNS = 'email_dns',
    CONNECT_DOMAIN = 'email-connect-domain',
    TITAN_CHOOSE_PLAN = 'titan-choose-plan',
    HMAIL_TRIAL = 'hosting_emails_trial',
    HMAIL_MANAGEMENT = 'hostinger_email_management',
    TITAN_UPGRADE_PLAN = 'flock_upgrade_page',
    TITAN_DASHBOARD = 'flock_dashboard',
    TITAN_MANAGEMENT = 'flock_management',
    TITAN_ONBOARDING_START = 'titan_email_onboarding_start',
    TITAN_ONBOARDING_DOMAIN_SELECT = 'titan_email_onboarding_domain_select',
    TITAN_ONBOARDING_DOMAIN_BUY = 'titan_email_onboarding_domain_buy',
    TITAN_ONBOARDING_DOMAIN_EXISTING = 'titan_email_onboarding_existing_buy',
    TITAN_ONBOARDING_FINISH = 'titan_email_onboarding_finish',
    EMAILS_HELP = 'emails_help',
  }

  export enum EmailPro {
    EMAILS = 'pro_emails',
    CHOOSE_PLAN = 'pro_hosting_emails_choose_plan',
    UPGRADE_PLAN = 'pro_hosting_emails_upgrade_plan',
    UPSELL = 'pro_hostinger_email_upsell',
    GOOGLE_WORKSPACE_SETUP = 'pro_google_workspace_setup',
    GOOGLE_WORKSPACE_MANAGEMENT = 'pro_google_workspace_management',
    EMAIL_ONBOARDING_START = 'pro_email_onboarding_start',
    EMAIL_ONBOARDING_HELLO = 'pro_email_onboarding_hello',
    EMAIL_ONBOARDING_CHOOSE_DOMAIN = 'pro_email_onboarding_choose_domain',
    EMAIL_ONBOARDING_BUY_DOMAIN = 'pro_email_onboarding_buy_domain',
    EMAIL_ONBOARDING_EXISTING_DOMAIN = 'pro_email_onboarding_existing_domain',
    EMAIL_ONBOARDING_SETUP_DOMAIN = 'pro_email_onboarding_setup_domain',
    EMAIL_ONBOARDING_CREATE_ACCOUNT = 'pro_email_onboarding_create_account',
    EMAIL_ONBOARDING_DNS = 'pro_email_onboarding_dns',
    EMAIL_ONBOARDING_CONNECT_DOMAIN = 'pro_email_onboarding_connect_domain',
    EMAIL_ONBOARDING_CONFIRM = 'pro_email_onboarding_confirm',
    EMAIL_ONBOARDING_SUCCESS = 'pro_email_onboarding_success',
    AUTOMATIC_REPLIES = 'pro_email_automatic_replies',
    CREATE_AUTOMATIC_REPLY = 'pro_create_automatic_reply',
    EDIT_AUTOMATIC_REPLY = 'pro_edit_automatic_reply',
    EMAIL_IMPORT = 'pro_email_import',
    ADD_EMAIL_IMPORT_REQUEST = 'pro_add_email_import_request',
    CUSTOM_DKIM = 'pro_custom_dkim',
    VERIFY_CUSTOM_DKIM = 'pro_verify_custom_dkim',
    CREATE_ACCOUNT_START = 'pro_email_create_account_start',
    EMAIL_ACCOUNTS = 'pro_email_accounts',
    EMAIL_FORWARDERS = 'pro_email_forwarders',
    EMAIL_ALIAS = 'pro_email_alias',
    EMAIL_SETUP_DEVICES = 'pro_email_setup_devices',
    EMAIL_LOGS = 'pro_email_logs',
    CONFIGURE_ACCOUNT = 'pro_email_configure_account',
    EMAIL_DNS = 'pro_email_dns',
    CONNECT_DOMAIN = 'pro_email-connect-domain',
    TITAN_CHOOSE_PLAN = 'pro_titan-choose-plan',
    HMAIL_TRIAL = 'pro_hosting_emails_trial',
    HMAIL_DOMAIN = 'pro_hosting_emails_domain',
    TITAN_UPGRADE_PLAN = 'pro_flock_upgrade_page',
    TITAN_DASHBOARD = 'pro_flock_dashboard',
    TITAN_MANAGEMENT = 'pro_flock_management',
    TITAN_ONBOARDING_START = 'pro_titan_email_onboarding_start',
    TITAN_ONBOARDING_DOMAIN_SELECT = 'pro_titan_email_onboarding_domain_select',
    TITAN_ONBOARDING_DOMAIN_BUY = 'pro_titan_email_onboarding_domain_buy',
    TITAN_ONBOARDING_DOMAIN_EXISTING = 'pro_titan_email_onboarding_existing_buy',
    TITAN_ONBOARDING_FINISH = 'pro_titan_email_onboarding_finish',
    EMAILS_HELP = 'pro_emails_help',
  }

  export enum Sale {
    NEW_YEAR = 'new-year',
    MONSOON_SALE = 'monsoon-upgrade',
    INDEPENDENCE_DAY = 'independence-day-offer',
    SEPTEMBER_SALE = 'september-sale-offer',
    SUMMER_SALE = 'upgrade-summer',
    POST_MONSOON_SALE = 'post-monsoon-sale',
    DEFAULT_POST_SALE = 'upgrades-offer',
    POST_INDEPENDENCE_DAY = 'post-independence-day-offer',
    POST_SUMMER_SALE = 'post-summer-upgrade',
    BLACK_FRIDAY = 'black-friday',
    POST_BLACK_FRIDAY = 'post-black-friday',
    CYBER_WEEK = 'cyber-week',
  }

  export enum Referral {
    REFERRALS = 'referrals',
    REFERRAL = 'referral',
    MY_REFERRALS = 'my-referrals',
    WELCOME_TO_REFERRALS = 'welcome-to-referrals',
    WIRE_TRANSFER = 'wire-transfer',
    SUCCESSFUL_REWARD_METHOD_SELECTION = 'referral-method-selected',
    SELECT_HOSTING_PLAN = 'select-hosting-plan',
    PLAN_SELECTION_SUMMARY = 'plan-selection-summary',
    DASHBOARD = 'referrals-dashboard',
    REFFER_AND_EARN = 'refer-and-earn',
  }

  export enum CPanel {
    CPANEL = 'cpanel',
    INDEX = 'cpanel-index',
    SECURITY = 'cpanel-security',
    SSH_ACCESS = 'cpanel-ssh-access',
    WORDPRESS = 'cpanel-wordpress',
    WORDPRESS_MANAGER = 'cpanel-wordpress-manager',
    WORDPRESS_STAGING = 'cpanel-wordpress-staging',
    WORDPRESS_CLONE = 'cpanel-wordpress-clone',
    INSTALL_WORDPRESS = 'cpanel.install-wordpress',
  }

  export enum CPanelOrder {
    DETAILS = 'cpanel-details',
    CPANEL_UPGRADE = 'cpanel-order-upgrade',
  }

  export enum Profile {
    PERSONAL_INFORMATION = 'personal_information',
    SECURITY = 'security',
    ACCOUNT_LOGS = 'account_logs',
    TWO_FACTOR_AUTH = 'two-factor-auth',
    GRANT_ACCESS = 'grant_access',
    REQUEST_ACCESS = 'request_access',
    REQUEST_ACCESS_PRO = 'request_access_pro',
    ACCOUNT_SHARING = 'account_sharing',
    EMAIL_AUTH = 'email-auth',
    APPLICATION_AUTH = 'application-auth',
    BACKUP_AUTH = 'backup-auth',
    AUTH_SUCCESS = 'auth-success',
  }

  export enum HostingerPro {
    MAIN_SPACE = 'main_space',
    CLIENTS_LIST = 'clients_list',
    ADD_NEW_CLIENT = 'add_new_client',
    WEBSITES_LIST = 'websites_list',
    CLIENT_SELECT = 'client_select',
    ADD_PURCHASE_CLIENT = 'add_purchase_client',
    PURCHASE_HOSTING_PLAN = 'purchase_hosting_plan',
    PURCHASE_SUMMARY = 'purchase_summary',
    DOMAINS = 'pro_panel_domains',
    OVERVIEW = 'overview',
    PARTNER_BENEFITS = 'partner_benefits',
    GET_STARTED = 'get_started',
    GET_HELP = 'get_help',
    WORDPRESS_PRESETS = 'wordpress_presets',
    WORDPRESS_PRESET_EDIT = 'wordpress_presets_edit',
    WORDPRESS_PRESET_SETUP = 'wordpress_presets_setup',
    SELECT_PLATFORM = 'hosting_select_platform',
    SELECT_WORDPRESS = 'hosting_select_wordpress',
    CHOOSE_WEBSITE = 'hosting_choose_website',
    HOSTING_OVERVIEW = 'pro_panel_hosting_overview',
    BEING_BUILT = 'hosting_being_built',
    SETUP_COMPLETED = 'hosting_setup_completed',
    EXISTING_DOMAIN_SUMMARY = 'existing_domain_summary',
    PREVIEW_WORDPRESS_TEMPLATE = 'preview_wordpress_template',
    PRESETS_PREVIEW_WORDPRESS_TEMPLATE = 'presets_preview_wordpress_template',
    PRO_REGISTER_DOMAIN_RESOURCE = 'pro_register_domain_resource',
    PRO_BUY_DOMAIN = 'pro_buy_new_domain',
    SELECT_ONBOARDING_FLOW = 'select_onboarding_flow',
    EMAILS_LIST = 'emails_list',
    PRO_VPS_LIST = 'pro_vps_list',
    PRO_VPS_PURCHASE = 'pro_vps_purchase',
    PRO_VPS = 'pro_vps',
    PRO_SELECT_WORDPRESS_PRESET = 'pro_select_wordpress_preset',
    PRO_SELECT_WORDPRESS_PLUGIN = 'pro_select_wordpress_plugin',
    PRO_SELECT_WORDPRESS_THEME = 'pro_select_wordpress_theme',
    PRO_SELECT_PRODUCTS = 'pro_select_products',
  }

  export enum Marketplace {
    HOSTING = 'marketplace-hosting',
    DOMAIN = 'marketplace-domain',
    EMAIL = 'marketplace-email',
    SERVICES = 'marketplace-services',
    OTHER = 'marketplace-other',
  }

  export enum Playground {
    HPTable = 'playground-hp-table',
    H_BUTTON = 'playground-h-button',
    H_CIRCLE_LOADER = 'playground-h-circle-loaders',
    H_RADIO = 'playground-h-radio',
    H_RADIO_CARD = 'playground-h-radio-card',
    HP_DROPDOWN = 'playground-hp-dropdown',
    H_POPOVER = 'playground-h-popover',
  }

  export enum EmailCpanel {
    BASE_PATH = 'email-cpanel',
    INDEX = 'emailcpanel',
    DASHBOARD = 'emailcpanel-dashboard',
    DETAILS = 'emailcpanel-details',
    UPGRADE = 'emailcpanel-updrage',
  }

  export enum OnboardingV2 {
    BASE_PATH = 'onboarding-v2-base',
  }

  // Structure for side menu:
  // <CATEGORY-NAME>_<ELEMENT-NAME>
  export enum H5G {
    BASE_PATH = 'h5g',
    ONBOARDING = 'h5g-onboarding',
    WEBSITES = 'h5g-websites',
    DASHBOARD = 'h5g-dashboard',
    FILE_MANAGER = 'h5g-files_file-manager',
    DATABASES = 'h5g-databases_manage',
    RESOURCE_USAGE = 'h5g-resource-usage',
  }

  export enum DeveloperTools {
    BASE_PATH = 'developer-tools',
    DASHBOARD = 'developer-tools-dashboard',
    PRESETS = 'developer-tools-presets',
    PRESET_CREATE = 'developer-tools-presets-create',
    PRESET_EDIT = 'developer-tools-presets-edit',
    PRESET_PREVIEW = 'developer-tools-presets-preview',
    OWNERSHIP_TRANSFER = 'developer-tools-ownership-transfer',
    REPORTS = 'developer-tools-reports',
    CREATE_REPORT = 'developer-tools-report',
    EDIT_REPORT_SCHEDULE = 'developer-tools-edit-schedule',
    MONITORING = 'developer-tools-monitoring',
    SUBSCRIPTIONS = 'developer-tools-subscriptions',
    UPGRADE = 'developer-tools-upgrade',
    ASSIGN_WEBSITE = 'developer-tools-assign-website',
    PURCHASE = 'developer-tools-purchase',
  }

  export enum MigrationsOnboarding {
    BASE_PATH = 'migrations-onboarding',
  }

  export enum Playground {
    PLAYGROUND_H_RADIO_CARD = 'playground-h-radio-card',
    PLAYGROUND_HP_TABLE = 'playground-hp-table',
    PLAYGROUND_H_CIRCLE_LOADERS = 'playground-h-circle-loaders',
    PLAYGROUND_H_BUTTON = 'playground-h-button',
  }

  export const Routes: readonly (
    | Route.Email
    | Route.Base
    | Route.OnboardingV2
    | Route.EmailCpanel
    | Route.Playground
    | Route.Marketplace
    | Route.HostingerPro
    | Route.Profile
    | Route.CPanelOrder
    | Route.CPanel
    | Route.Referral
    | Route.Sale
    | Route.EmailPro
    | Route.OnboardingParam
    | Route.Onboarding
    | Route.HRefunds
    | Route.Order
    | Route.Domain
    | Route.Vps
    | Route.VpsOnboarding
    | Route.Hosting
    | Route.HostingAccount
    | Route.HostingAdvanced
    | Route.HostingDatabases
    | Route.HostingDomains
    | Route.HostingEmails
    | Route.HostingFiles
    | Route.HostingOrder
    | Route.HostingSecurity
    | Route.HostingWebsite
    | Route.HostingPerformance
    | Route.HostingWordpress
    | Route.Billing
    | Route.HBilling
  )[] = [
    ...Object.values(Route.Email),
    ...Object.values(Route.Base),
    ...Object.values(Route.OnboardingV2),
    ...Object.values(Route.EmailCpanel),
    ...Object.values(Route.Playground),
    ...Object.values(Route.Marketplace),
    ...Object.values(Route.HostingerPro),
    ...Object.values(Route.Profile),
    ...Object.values(Route.CPanelOrder),
    ...Object.values(Route.CPanel),
    ...Object.values(Route.Referral),
    ...Object.values(Route.Sale),
    ...Object.values(Route.EmailPro),
    ...Object.values(Route.OnboardingParam),
    ...Object.values(Route.Onboarding),
    ...Object.values(Route.HRefunds),
    ...Object.values(Route.Order),
    ...Object.values(Route.Domain),
    ...Object.values(Route.Vps),
    ...Object.values(Route.VpsOnboarding),
    ...Object.values(Route.Hosting),
    ...Object.values(Route.HostingAccount),
    ...Object.values(Route.HostingAdvanced),
    ...Object.values(Route.HostingDatabases),
    ...Object.values(Route.HostingDomains),
    ...Object.values(Route.HostingEmails),
    ...Object.values(Route.HostingFiles),
    ...Object.values(Route.HostingOrder),
    ...Object.values(Route.HostingSecurity),
    ...Object.values(Route.HostingWebsite),
    ...Object.values(Route.HostingPerformance),
    ...Object.values(Route.HostingWordpress),
    ...Object.values(Route.Billing),
    ...Object.values(Route.HBilling),
    ...Object.values(Route.Order),
  ];

  export type RouteType = (typeof Route.Routes)[number];
}

export namespace RoutePath {
  export enum Domain {
    DOMAIN = '/domain/:domain',
    TRANSFERS = 'transfers',
  }

  export enum Hosting {
    HOSTING = '/hosting',
    HOSTING_V2 = '/hosting-v2',
  }
}
