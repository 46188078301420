import { defineStore } from 'pinia';
import { ref } from 'vue';

import {
  UNSUPPORTED_DOMAINS,
  BUILDER_DOMAINS,
} from '@/data/migration/migrationRestrictedDomains';
import { migrationRepo } from '@/repositories';
import type {
  MigrationConnectionStatus,
  IVerifyWpMigrationCredentials,
  IGenericMigrationCredentials,
  IVerifyCpanelMigrationCredentials,
  IDisplayedMigration,
  MigrationPlatform,
} from '@/types';
import {
  STORE_PERSISTENT_KEYS,
  MIGRATION_PLATFORMS,
  MIGRATION_CONNECTION_STATUS,
  MigrationStatus,
} from '@/types';
import { mapKeyValue } from '@/utils/helpers';
import hLocalStorage from '@/utils/services/localStorageService';

// Didn't want to break existing completed capsules for users that already hid them
const HiddenMigrationCapsuleKeys = {
  [MigrationStatus.COMPLETED]: 'Completed',
  [MigrationStatus.REJECTED]: 'Rejected',
} as Record<MigrationStatus, string>;

export const useHostingMigrationsStore = defineStore(
  'hostingMigrationsStore',
  () => {
    const isTableLoading = ref(false);

    const existingMigrations = ref<IDisplayedMigration[]>([]);
    const hiddenMigrationCapsules = ref<Record<string, string[]>>({
      [MigrationStatus.COMPLETED]: [],
      [MigrationStatus.REJECTED]: [],
    });
    const connectionStatus = ref<MigrationConnectionStatus | null>(null);

    const fetchIsConnected = async (
      credentials:
        | IVerifyWpMigrationCredentials
        | IVerifyCpanelMigrationCredentials,
      selectedPlatform: MigrationPlatform,
    ) => {
      if (selectedPlatform === MIGRATION_PLATFORMS.OTHER) {
        connectionStatus.value = MIGRATION_CONNECTION_STATUS.LOGIN_SUCCESS;

        return;
      }

      const PLATFORM_VERIFICATION_MAP = {
        [MIGRATION_PLATFORMS.WORDPRESS]:
          migrationRepo.verifyWpMigrationCredentials.bind(migrationRepo),
        [MIGRATION_PLATFORMS.CPANEL]:
          migrationRepo.verifyCpanelMigrationCredentials.bind(migrationRepo),
        default: () => null,
      };

      const verifyCall = mapKeyValue(
        PLATFORM_VERIFICATION_MAP,
        selectedPlatform,
      );

      const [{ data }, err] = await verifyCall(credentials, {
        hideToastr: true,
      });

      if (err || !data) {
        if (!err?.response) {
          connectionStatus.value = MIGRATION_CONNECTION_STATUS.TIMEOUT;
        } else {
          connectionStatus.value =
            MIGRATION_CONNECTION_STATUS.WORDPRESS_CREDENTIALS_ERROR;
        }

        return;
      }

      connectionStatus.value = data;
    };

    const setHiddenMigrationCapsules = (
      id: string,
      status: MigrationStatus,
    ) => {
      const key = `closed${HiddenMigrationCapsuleKeys[status]}Migrations`;
      const closedMigrations = hLocalStorage.getValue(key) || [];

      const newClosedMigrations = [...closedMigrations, id];

      hLocalStorage.setValue(key, newClosedMigrations);
      hiddenMigrationCapsules.value[status] = newClosedMigrations;
    };

    const fetchExistingHiddenMigrationCapsules = () => {
      (Object.keys(HiddenMigrationCapsuleKeys) as MigrationStatus[]).forEach(
        (status: MigrationStatus) => {
          const key = `closed${HiddenMigrationCapsuleKeys[status]}Migrations`;
          const closedMigrations = hLocalStorage.getValue(key) || [];

          hiddenMigrationCapsules.value[status] = closedMigrations;
        },
      );
    };

    const fetchExistingMigrations = async () => {
      isTableLoading.value = true;
      const [{ data }, err] = await migrationRepo.getMigrations();
      isTableLoading.value = false;

      if (!err) {
        existingMigrations.value = data.map((item) => ({
          isLoading: false,
          ...item,
        }));
      }
    };

    const setIsItemLoading = (id: string, isLoading: boolean) => {
      const itemIndex = existingMigrations.value.findIndex(
        (item) => item.id === id,
      );

      existingMigrations.value[itemIndex].isLoading = isLoading;
    };

    const deleteMigration = async (id: string) => {
      setIsItemLoading(id, true);
      const [, err] = await migrationRepo.destroyMigration(id);
      setIsItemLoading(id, false);

      if (err) return;

      existingMigrations.value = existingMigrations.value.filter(
        (item) => item.id !== id,
      );
    };
    const getIsUnsupportedControlPanel = (loginUrl: string) =>
      UNSUPPORTED_DOMAINS.some((element) => loginUrl.includes(element));

    const getIsBuilderDomains = (loginUrl: string) =>
      BUILDER_DOMAINS.some((element) => loginUrl.includes(element));

    const getCheckMigrationBody = ({
      credentials,
      migrationPlatform,
    }: {
      credentials: IGenericMigrationCredentials;
      migrationPlatform: MigrationPlatform;
    }) => {
      const { loginUrl, username, password } = credentials;

      const cpanelRequestBody = {
        panelUrl: loginUrl,
        panelUsername: username,
        panelPassword: password,
      };

      const wordpressRequestBody = {
        adminUrl: loginUrl,
        wpUsername: username,
        wpPassword: password,
      };

      return migrationPlatform === MIGRATION_PLATFORMS.CPANEL
        ? cpanelRequestBody
        : wordpressRequestBody;
    };

    const checkMigrationConnection = async ({
      credentials,
      migrationPlatform,
    }: {
      credentials: IGenericMigrationCredentials;
      migrationPlatform: MigrationPlatform;
    }) => {
      const { loginUrl } = credentials;

      const connectionCheckRequestBody = getCheckMigrationBody({
        credentials,
        migrationPlatform,
      });

      if (getIsUnsupportedControlPanel(loginUrl)) {
        connectionStatus.value =
          MIGRATION_CONNECTION_STATUS.CANNOT_MIGRATE_WORDPRESSCOM;

        return;
      }

      if (getIsBuilderDomains(loginUrl)) {
        connectionStatus.value = MIGRATION_CONNECTION_STATUS.WEBSITE_BUILDER;
      }

      await fetchIsConnected(connectionCheckRequestBody, migrationPlatform);
    };

    const $reset = () => {
      existingMigrations.value = [];
      connectionStatus.value = null;
      isTableLoading.value = false;
    };

    return {
      isTableLoading,
      $reset,
      checkMigrationConnection,
      existingMigrations,
      deleteMigration,
      connectionStatus,
      fetchIsConnected,
      fetchExistingMigrations,
      setHiddenMigrationCapsules,
      hiddenMigrationCapsules,
      fetchExistingHiddenMigrationCapsules,
    };
  },
  { persist: { key: STORE_PERSISTENT_KEYS.HOSTING_MIGRATIONS_STORE } },
);
