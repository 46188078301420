import { computed } from 'vue';
import { useRoute, type RouteRecordName } from 'vue-router';

import { useBrands } from '@/composables/useBrands';
import { useGlobals } from '@/composables/useGlobals';
import { useLanguages } from '@/composables/useLanguages';
import { useProfile } from '@/composables/useProfile';
import { useReferrals } from '@/composables/useReferrals';
import { useProfileStore, useReferralStore } from '@/stores';
import type { KeyValueMap } from '@/types';
import {
  Country,
  HIcon,
  Referral,
  Route,
  SIMPLE_TIMER_VARIANTS,
} from '@/types';
import { isCurrentTimeWithinDates, mapKeyValue } from '@/utils/helpers';

const REFERRAL_ROUTES = [
  Route.Referral.REFFER_AND_EARN,
  Route.Referral.MY_REFERRALS,
] as RouteRecordName[];

export const useReferralsPromotion = () => {
  const { t } = useGlobals();
  const route = useRoute();
  const { accountDaysRegistered } = useProfile();
  const { isFromAnotherBrand } = useBrands();
  const { country } = useProfileStore();
  const referralsStore = useReferralStore();
  const profileStore = useProfileStore();
  const { referralDiscountRate } = useReferrals();
  const { isCurrentLanguageEnglish } = useLanguages();

  const COMMISSION_RATE_AND_AMOUNT_MAP: KeyValueMap = {
    [Referral.CommissionRate.COMMISSION_35]: Referral.CommissionAmount.USD_400,
    [Referral.CommissionRate.COMMISSION_40]: Referral.CommissionAmount.USD_450,
    default: Referral.CommissionAmount.USD_300,
  };

  const isWordCampPromotionActive = computed(
    () =>
      referralsStore.rewardRate !== Referral.CommissionRate.COMMISSION_20 &&
      referralsStore.rewardRate !== undefined,
  );

  const wordCampCommissionRate = computed(() => {
    if (referralsStore.rewardRate === undefined) {
      return Referral.CommissionRate.COMMISSION_20;
    }

    return referralsStore.rewardRate;
  });

  const promotions = computed(() =>
    // Priority is defined by the order of the array.
    // If there are multiple candidates by date, the first one will be active.
    [
      ...(isWordCampPromotionActive.value
        ? [
            {
              id: 'wordcamp',
              commissionRate: wordCampCommissionRate.value,
              title: t('v2.referrals.wordcamp.offer', {
                commissionInDollars: mapKeyValue(
                  COMMISSION_RATE_AND_AMOUNT_MAP,
                  wordCampCommissionRate.value,
                ),
                friendDiscountPercentage: `${
                  referralDiscountRate.value * 100
                }%`,
              }),
              placements: [],
              startDate: '2024-09-01T00:00:00Z',
              endDate: '2025-03-31T23:59:59Z',
              key: Referral.PromotionalTopBannerKey.WORDCAMP,
              promotionType: Referral.LimitedTimeOfferCampaignTypeKey.ALL_PLANS,
              backgroundColor: 'dark',
              textColor: 'white',
              noTimer: true,
              isCloseButtonVisible: false,
              isButtonVisible: false,
              homepageButtonText: t(
                'v2.refer.and.earn.up.to.commissionInDollars.usd',
                {
                  commissionInDollars: mapKeyValue(
                    COMMISSION_RATE_AND_AMOUNT_MAP,
                    wordCampCommissionRate.value,
                  ),
                },
              ),
              homepageButtonSubtitle: t(
                'v2.your.friends.will.get.friendCommissionPercentage.off.too.its.win.win.exclamation',
                {
                  friendCommissionPercentage: `${
                    referralDiscountRate.value * 100
                  }%`,
                },
              ),
              isHomepageButtonAnimated: false,
              topBarLinkText:
                isCurrentLanguageEnglish() &&
                t('v2.refer.and.earn.commissionInDollars.usd', {
                  commissionInDollars: mapKeyValue(
                    COMMISSION_RATE_AND_AMOUNT_MAP,
                    wordCampCommissionRate.value,
                  ),
                }),
              isTopBarLinkHiddenInHomepage: false,
              isTopBarLinkAnimated: true,
              icon: {
                name: HIcon.ICON_BADGE_DISCOUNT,
                color: 'teal',
              },
            },
          ]
        : []),

      {
        id: 'Q4C1',
        commissionRate: Referral.CommissionRate.COMMISSION_30,
        title: t('v2.referrals.Q4C1.title', {
          openTag: '<strong>',
          closeTag: '</strong>',
        }),
        placements: [],
        startDate: '2024-10-07T09:00:00Z',
        endDate: '2024-10-13T23:59:59Z',
        key: Referral.PromotionalTopBannerKey.CLOUD_VPS,
        promotionType: Referral.LimitedTimeOfferCampaignTypeKey.CLOUD_VPS,
        backgroundColor: 'primary-light',
        textColor: 'meteorite-dark',
        noTimer: true,
        buttonColor: 'danger',
        closeButtonColor: 'primary-charts',
        isCloseButtonVisible: !isInReferralsRoute.value,
        isButtonVisible: !isInReferralsRoute.value,
        homepageButtonText: t(
          'v2.refer.and.earn.up.to.commissionInDollars.usd',
          {
            commissionInDollars: mapKeyValue(
              COMMISSION_RATE_AND_AMOUNT_MAP,
              Referral.CommissionRate.COMMISSION_30,
            ),
          },
        ),
        homepageButtonSubtitle: t(
          'v2.your.friends.will.get.friendCommissionPercentage.off.too.its.win.win.exclamation',
          {
            friendCommissionPercentage: `${
              Referral.CommissionRate.COMMISSION_20 * 100
            }%`,
          },
        ),
        isHomepageButtonAnimated: false,
        topBarLinkText:
          isCurrentLanguageEnglish() &&
          t('v2.refer.and.earn.commissionInDollars.usd', {
            commissionInDollars: 300,
          }),
        isTopBarLinkHiddenInHomepage: false,
        isTopBarLinkAnimated: true,
        icon: {
          name: HIcon.ICON_BADGE_DISCOUNT,
          color: 'primary-dark',
        },
      },
      // Tests are based on Q3C2 promo, keep it.
      {
        id: 'Q3C2',
        commissionRate: Referral.CommissionRate.COMMISSION_35,
        title: t(
          'Earn up to {openTag}400 USD{closeTag} per referral. Your referral will get a 20% discount too.',
          {
            openTag: '<strong>',
            closeTag: '</strong>',
          },
        ),
        placements: [
          Route.Websites.WEBSITE_DASHBOARD,
          Route.Websites.BUILDER_DASHBOARD,
          Route.Base.SERVERS,
        ],
        startDate: '2024-08-05T09:00:00Z',
        endDate: '2024-08-18T23:59:59Z',
        key: Referral.PromotionalTopBannerKey.ALL_PLANS,
        promotionType: Referral.LimitedTimeOfferCampaignTypeKey.ALL_PLANS,
        backgroundColor: 'meteorite-dark',
        textColor: 'white',
        timerVariant: SIMPLE_TIMER_VARIANTS.WHITE,
        isCloseButtonVisible: !isInReferralsRoute.value,
        isButtonVisible: !isInReferralsRoute.value,
        icon: {
          name: HIcon.ICON_BADGE_DISCOUNT,
          color: 'white',
        },
        closeButtonColor: 'white',
      },
    ],
  );

  const isInReferralsRoute = computed(() =>
    REFERRAL_ROUTES.includes(route.name as RouteRecordName),
  );

  const isBannerVisibleInRoute = (
    promotion: (typeof promotions.value)[number],
  ) => {
    if (isInReferralsRoute.value) {
      return true;
    }

    const placements = promotion.placements as RouteRecordName[];

    return placements.includes(route.name as RouteRecordName);
  };

  const isClientEligibleForPromotion = computed(() => {
    const isNonEligibleBrand =
      isFromAnotherBrand.value || country === Country.Code.RU;

    if (
      (accountDaysRegistered.value < 7 && !isWordCampPromotionActive.value) ||
      isNonEligibleBrand ||
      referralsStore.isMemberDisabled ||
      profileStore.isAccessManager
    ) {
      return false;
    }

    return true;
  });

  const activePromotion = computed(() => {
    if (!isClientEligibleForPromotion.value) return null;

    return (
      promotions.value.find((promotion) =>
        isCurrentTimeWithinDates({
          startsAt: promotion.startDate,
          endsAt: promotion.endDate,
        }),
      ) ?? null
    );
  });

  return {
    isInReferralsRoute,
    activePromotion,
    isBannerVisibleInRoute,
  };
};
