import { isString } from 'lodash';
import type { DirectiveBinding } from 'vue';

import { useLanguageStore } from '@/stores';
import { MouseEvent } from '@/types';
import { isMobileBrowser } from '@/utils/helpers';
import { $t } from '@/utils/services/i18nService';

const TOOLTIP_ATTR = 'tooltip';
interface ComplexTooltip {
  content: string;
  variables?: Record<string, string>;
  autoWidth?: boolean;
}

interface TooltipHTMLElement extends HTMLElement {
  onMouseOver?: (event: Event) => void;
  onMouseLeave?: (event: Event) => void;
  onMouseClick?: (event: Event) => void;
}

type TooltipBinding =
  | DirectiveBinding<string>
  | DirectiveBinding<ComplexTooltip>;

const getCurrentRotation = (el: HTMLElement) => {
  const computedStyles = window.getComputedStyle(el, null);
  const transformProp =
    computedStyles.getPropertyValue('-webkit-transform') ||
    computedStyles.getPropertyValue('-moz-transform') ||
    computedStyles.getPropertyValue('-ms-transform') ||
    computedStyles.getPropertyValue('-o-transform') ||
    computedStyles.getPropertyValue('transform') ||
    'none';
  if (transformProp !== 'none') {
    const values = transformProp.split('(')[1].split(')')[0].split(',');

    const angle = Math.round(
      Math.atan2(values[1] as any, values[0] as any) * (180 / Math.PI),
    );

    return angle < 0 ? angle + 360 : angle;
  }

  return 0;
};

const getTooltipClass = (
  { modifiers }: DirectiveBinding,
  isRotated: boolean,
) => {
  const modifiersKeys = Object.keys(modifiers);
  if (modifiersKeys.length <= 1) {
    const position = Object.keys(modifiers)[0];

    return [
      `has-tooltip--${position || 'bottom'}${isRotated ? '-rotated' : ''}`,
    ];
  }

  return modifiersKeys.map((key) => `has-tooltip--${key}`);
};

const setTooltipWidth = (el: HTMLElement, binding: TooltipBinding) => {
  if (!isString(binding.value) && binding.value.autoWidth) {
    document.documentElement.style.setProperty('--tooltip-width', 'auto');

    return;
  }

  if (binding.modifiers?.['bottom-edge']) {
    const tooltipMaxWidth = 250; // Default maximum width of the tooltip

    const rect = el.getBoundingClientRect();
    const leftMargin = 4;
    const availableSpace =
      document.documentElement.clientWidth - leftMargin - rect.left;

    const maxTooltipWidth = Math.min(tooltipMaxWidth, availableSpace);

    document.documentElement.style.setProperty(
      '--tooltip-width',
      `${maxTooltipWidth}px`,
    );
  }
};

const getTooltipContent = (binding: TooltipBinding) => {
  if (!binding.value) return;

  const value = isString(binding.value) ? binding.value : binding.value.content;
  const variables = !isString(binding.value)
    ? binding.value.variables
    : undefined;

  if (!value) return;

  if (!variables) {
    return $t(value);
  }

  return $t(value, variables);
};

const addTooltip = (el: HTMLElement, binding: TooltipBinding) => {
  const content = getTooltipContent(binding);

  if (!content) return el.removeAttribute(TOOLTIP_ATTR);

  const rotation = getCurrentRotation(el);

  el.setAttribute(TOOLTIP_ATTR, content);

  document.documentElement.style.setProperty(
    '--tooltip-rotation',
    `-${rotation}deg`,
  );

  setTooltipWidth(el, binding);

  el.style.transition = '0s';
  const zIndex = getComputedStyle(document.documentElement).getPropertyValue(
    '--z-index-max',
  );
  el.style.zIndex = zIndex;
  el.style.position = 'relative';
  el.style.textAlign = 'center';

  el.classList.add(...getTooltipClass(binding, rotation === 180));
};

const removeTooltip = (el: HTMLElement, binding: TooltipBinding) => {
  el.removeAttribute(TOOLTIP_ATTR);

  el.style.zIndex = '';
  el.style.position = '';
  el.style.textAlign = '';

  el.classList?.remove(...getTooltipClass(binding, true));
  el.classList?.remove(...getTooltipClass(binding, false));
};

const unbind = (el: TooltipHTMLElement, binding: TooltipBinding) => {
  if (!el.onMouseOver || !el.onMouseLeave || !el.onMouseClick) return;

  el.removeEventListener(MouseEvent.MOUSE_OVER, el.onMouseOver);
  el.removeEventListener(MouseEvent.MOUSE_LEAVE, el.onMouseLeave);
  el.removeEventListener(MouseEvent.CLICK, el.onMouseClick);

  delete el.onMouseOver;
  delete el.onMouseLeave;
  delete el.onMouseClick;

  removeTooltip(el, binding);
};

const bind = (el: TooltipHTMLElement, binding: TooltipBinding) => {
  const content = getTooltipContent(binding);

  if (!content) {
    unbind(el, binding);

    return;
  }

  const updated = binding.value !== binding.oldValue;
  const attached = el.onMouseOver || el.onMouseLeave || el.onMouseClick;

  if (!updated && attached) return;

  if (attached) {
    unbind(el, binding);
  }

  const tooltipEventOnClick = isMobileBrowser() ? addTooltip : removeTooltip;

  const languageStore = useLanguageStore();
  el.setAttribute('key', languageStore.currentLocale);

  el.onMouseOver = () => {
    addTooltip(el, binding);
  };

  el.onMouseLeave = () => {
    removeTooltip(el, binding);
  };

  el.onMouseClick = () => {
    tooltipEventOnClick(el, binding);
  };

  el.addEventListener(MouseEvent.MOUSE_OVER, el.onMouseOver);
  el.addEventListener(MouseEvent.MOUSE_LEAVE, el.onMouseLeave);
  el.addEventListener(MouseEvent.CLICK, el.onMouseClick);
};

export const vTooltip = {
  mounted: bind,
  updated: bind,
  beforeUnmount: unbind,
};
