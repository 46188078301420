import type { ScheduleListRes } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_WH_API}/api/hapi/v1`,

  async getScheduledReportsList(params: { page: number; perPage: number }) {
    return await hAsync<ScheduleListRes>(
      http.get(`${this.url}/website-report-schedules`, {
        plain: true,
        params,
      }),
    );
  },

  async deleteSchedule({ id }: { id: number }) {
    return await hAsync<void>(
      http.delete(`${this.url}/website-report-schedules/${id}`),
    );
  },

  async editSchedule({ id, data }: { id: number; data: any }) {
    return await hAsync(
      http.put(`${this.url}/website-report-schedules/${id}`, data, {
        plain: true,
      }),
    );
  },
};
