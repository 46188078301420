import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { Header } from '@/types';

export const useHostingWordPressStore = defineStore(
  'hostingWordPressStore',
  () => {
    const store = useStore();
    const route = useRoute();

    const wordPressSubdomainConfig = computed(() => {
      const { domain, domainToManage, subdomain } = route.params;
      if (!domain) return;

      const accountData = store.getters.getCurrentAccountWithDomain(domain);

      if (!accountData) return;

      const { username, referenceId } = accountData;

      return {
        noHostingHeaders: true,
        headers: {
          [Header.DOMAIN]: subdomain || domainToManage || domain,
          [Header.USERNAME]: username,
          [Header.ORDER_ID]: referenceId,
        },
      };
    });

    return { wordPressSubdomainConfig };
  },
);
