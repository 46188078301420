import { useResourceMapper } from '@/composables';
import { ordersRepo } from '@/repositories';
import { router } from '@/router';
import { HRESOURCES_TYPE, Hosting } from '@/types';
import { toUnicode, toASCII } from '@/utils/helpers';
import { mapHostingAccountResource } from '@/utils/mappers/resourceAccountMapper';

const INITIAL_STATE = {
  accounts: [],
  visitedAccount: null,
  boostedOrders: [],
  boostedOrdersLoaded: false,
  hostingOrderUsage: {},
};

export default {
  state: INITIAL_STATE,
  mutations: {
    domainChange: (state) => (state.accounts = [...state.accounts]),
    RESET_ACCOUNT_STATE: (state) => {
      Object.assign(state, INITIAL_STATE);
    },
    SET_VISITED_ACCOUNT_DETAILS(state, payload) {
      state.visitedAccount = state.accounts.find(
        (account) => account.domain === payload,
      );
    },
    SET_BOOSTED_ORDERS(state, payload) {
      state.boostedOrders = payload;
    },
    SET_BOOSTED_ORDERS_LOADED(state, payload) {
      state.boostedOrdersLoaded = payload;
    },
    ADD_PARKED_DOMAIN(state, parkedDomain) {
      const accountIndex = state.accounts.findIndex(
        ({ domain }) => domain === parkedDomain.mainDomain,
      );

      if (accountIndex !== -1) {
        const account = state.accounts[accountIndex];
        account.parkedDomains.push(parkedDomain);
        state.accounts[accountIndex] = account;
      }
    },
    REMOVE_PARKED_DOMAIN(state, parkedDomain) {
      const accountIndex = state.accounts.findIndex(
        ({ domain }) => domain === parkedDomain.mainDomain,
      );

      if (accountIndex !== -1) {
        const account = state.accounts[accountIndex];
        state.accounts[accountIndex].parkedDomains =
          account.parkedDomains.filter(
            ({ parkedDomain: parkedDomainString }) =>
              parkedDomainString !== parkedDomain.parkedDomainString,
          );
      }
    },
    SET_HOSTING_ORDER_ACCOUNTS(state, { accounts, orderId }) {
      state.accounts = state.accounts?.filter(
        (account) => account?.orderId !== orderId,
      );

      accounts?.forEach((account) => {
        state.accounts.push({
          ...account,
          orderId,
        });
      });
    },
    DELETE_HOSTING_ACCOUNT(state, currentDomain) {
      state.accounts = state.accounts.filter(
        ({ domain }) => domain !== currentDomain,
      );
    },
    SET_ACCOUNT_SUBDOMAINS(state, { accDomain, subdomains }) {
      const account = state.accounts.find(
        ({ domain, username }) => domain === accDomain && username,
      );
      if (account) account.subdomains = subdomains;
    },
    REMOVE_HOSTING_ORDER_ACCOUNT(state, { domain }) {
      const account = state.accounts.find(
        (account) => account.domain === domain,
      );

      account.removed = true;
    },
    SET_HOSTING_ORDER_USAGE(state, { usage, orderId }) {
      state.hostingOrderUsage = {
        ...state.hostingOrderUsage,
        [orderId]: usage,
      };
    },
  },
  getters: {
    getCurrentAccount: (state) => getCurrentAccountData(state),
    getCurrentAccountWithDomain: (state) => (domain) =>
      state.accounts.find(
        (account) => account.domain === domain && account.username,
      ) || state.visitedAccount,
    getCurrentOrderAccounts: (state) => getCurrentAccounts(state),
    getCurrentAccountSubdomains: (_, { currentOrderDetails }) => {
      const account = getCurrentVhost(currentOrderDetails);
      if (account) return account.subdomains;

      return [];
    },
    getCurrentAccountParkedDomains: (_, { currentOrderDetails }) => {
      const account = getCurrentVhost(currentOrderDetails);

      if (account) return account.parkedDomains;

      return [];
    },
    getCurrentAccountAllDomainsSelect: (_, { currentOrderDetails }) => {
      const currentAccount = getCurrentVhost(currentOrderDetails);

      if (!currentAccount) return [];

      const parkedDomains = parkDomainSelection(currentAccount);
      const subdomains = subdomainSelection(currentAccount);
      const domains = domainSelection(currentAccount);

      return [...parkedDomains, ...subdomains, ...domains];
    },
    getCurrentAccountParkedDomainsSelect: (state, { currentOrderDetails }) => {
      const account = getCurrentVhost(currentOrderDetails);

      if (!account) return [];

      const parkedDomainSelections = parkDomainSelection(account);

      return [...domainSelection(account)].concat(parkedDomainSelections);
    },
    getCurrentAccountSubdomainsSelect: (_, { currentOrderDetails }) => {
      const currentAccount = getCurrentVhost(currentOrderDetails);

      if (!currentAccount) return [];

      const subdomain = subdomainSelection(currentAccount);

      return [...domainSelection(currentAccount)].concat(subdomain);
    },
    getCurrentAccountServerID: (state) => {
      const account = getCurrentAccountData(state);

      return account?.serverId;
    },
    getAllBuilderAccounts: (state) =>
      state.accounts.filter((account) => account.isWebsiteBuilder),
    getAllParkedDomains: (_, { getAllAccounts }) =>
      getAllAccounts.flatMap((account) => account.parkedDomains),
    isDomainParked:
      (_, { getAllParkedDomains }) =>
      (domain) =>
        !!getAllParkedDomains.find(
          (parkedDomain) => parkedDomain?.parkedDomain === domain,
        ),

    getParkedMainDomain:
      (_, { getAllParkedDomains }) =>
      (domain) => {
        const foundParkedDomain = getAllParkedDomains.find(
          (parkedDomain) => parkedDomain.parkedDomain === domain,
        );

        return foundParkedDomain?.mainDomain || null;
      },
    domainSelections: (_, { currentOrderDetails }) =>
      currentOrderDetails?.orderVhosts.map(({ vhost }) => ({
        label: toUnicode(vhost),
        value: vhost,
        isSelection: true,
      })) || [],
    activeDomainSelectionsByType:
      (_, { getCurrentOrderAccounts }) =>
      (isBuilder = false) =>
        getCurrentOrderAccounts
          .filter(
            ({ vhostSuspended, isWebsiteBuilder }) =>
              !vhostSuspended && isWebsiteBuilder === isBuilder,
          )
          .map(({ domain }) => ({
            label: toUnicode(domain),
            value: domain,
            isSelection: true,
          })) || [],
    currentAccountDomainSelections: (_, { currentOrderDetails }) => {
      const currentUsername = currentOrderDetails.username;

      return (
        currentOrderDetails?.orderVhosts
          .filter(({ username }) => username === currentUsername)
          .map(({ vhost }) => ({
            label: toUnicode(vhost),
            value: vhost,
            isSelection: true,
          })) || []
      );
    },
    getAccountByDomain: (state) => (findDomain) =>
      state.accounts.find(({ domain }) => domain === findDomain),
    // This solves an issue of accounts returning cpanel plans when
    // the domain is the same for both hosting and cpanel plans.
    getAccountByDomainWithoutCpanel: (state) => (findDomain) =>
      state.accounts.find(
        ({ domain, type }) =>
          domain === findDomain &&
          ![
            HRESOURCES_TYPE.CPANEL_HOSTING,
            HRESOURCES_TYPE.CPANEL_RESELLER,
          ].includes(type),
      ),
    getMainAccountByUsername: (state) => (findUsername) =>
      state.accounts.find(
        ({ username, type }) =>
          username === findUsername &&
          [Hosting.AccountType.ACCOUNT, Hosting.AccountType.BUILDER].includes(
            type,
          ),
      ),
    getAccountsByOrder: (state) => (findOrderId) =>
      state.accounts.filter(({ orderId }) => orderId === findOrderId),
    getFirstAccountByOrder: (state) => (findOrderId) =>
      state.accounts.find(({ orderId }) => orderId === findOrderId),
    getFirstActiveAccountByOrder: (state) => (findOrderId) =>
      state.accounts.find(
        ({ orderId, status }) => orderId === findOrderId && status === 'active',
      ),
    accountExists: (state) => (findDomain) =>
      !!state.accounts.find(
        ({ domain, username }) => domain === findDomain && username,
      ),
    getIsAccountDomainFree: (state) => (findDomain) => {
      const account = state.accounts.find(
        ({ domain, username }) => domain === findDomain && username,
      );

      return account?.isFreeDomain;
    },
    getHasAccountsWebsiteBuilder: (state) =>
      state.accounts.some(({ isWebsiteBuilder }) => isWebsiteBuilder),
    isBuilderOnlyWebsites: (state) =>
      state.accounts &&
      state.accounts.length &&
      state.accounts.every(({ isWebsiteBuilder }) => isWebsiteBuilder),

    getHasActiveAccounts: (state) => !!state.accounts.length,

    getAccountAllowManage: (state) => (findDomain) => {
      const account = state.accounts.find(
        ({ domain, username }) => domain === findDomain && username,
      );

      return account ? account.allowManage : true;
    },
    getAccountAllowManageMessage: (state) => (findDomain) => {
      const account = state.accounts.find(
        ({ domain, username }) => domain === findDomain && username,
      );

      return account ? account.allowManageMessage : '';
    },
    getAccountUsername: (state) => (findDomain) => {
      const account = state.accounts.find(
        ({ domain, username }) => domain === findDomain && username,
      );

      return account?.username;
    },
    getAccountHttpParams: (state) => (findDomain) => {
      const account = state.accounts.find(
        ({ domain, username }) => domain === findDomain && username,
      );

      if (!account) return null;

      const accountDetails = {
        domain: account?.domain || findDomain,
        username: account?.username || null,
        orderId: account?.orderId || null,
      };

      Object.keys(accountDetails).forEach(
        (key) => accountDetails[key] === null && delete accountDetails[key],
      );

      return accountDetails;
    },
    getAllAccounts: (state) => state.accounts,
    getAccountsInTransfer: (state) =>
      state.accounts.filter((account) => account.inTransfer),
    getBoostedOrders: (state) => state.boostedOrders,
    getBoostedOrdersLoaded: (state) => state.boostedOrdersLoaded,
    getHostingOrderUsage: (state) => (orderId) =>
      state.hostingOrderUsage[orderId] || null,
  },
  actions: {
    async hostingOrderAccountsIndex(context, params) {
      const { getResourcesByReferenceId, getResourceBySubscriptionId } =
        useResourceMapper();

      let id = params?.id;

      if (!id) {
        const account = getCurrentAccountData(context.state);
        if (account) id = account.orderId;
      }

      let resource = getResourcesByReferenceId(params?.referenceId);

      if (!resource) {
        resource = getResourceBySubscriptionId(id);
      }

      let data = params ? [params] : undefined;

      if (
        [HRESOURCES_TYPE.HOSTING, HRESOURCES_TYPE.WEBSITE_BUILDER].includes(
          params?.service,
        )
      ) {
        [{ data }] = await ordersRepo.getOrdersAccounts(params.referenceId);
      }

      if (data) {
        context.commit('SET_HOSTING_ORDER_ACCOUNTS', {
          accounts: data.map((account) =>
            mapHostingAccountResource(account, resource),
          ),
          orderId: id,
        });
      }
    },
    async restoreHostingAccountIndex(context, { account }) {
      const [{ data }] = await ordersRepo.restoreHostingAccountIndex(account);
      if (data) {
        context.dispatch('fetchHostingOrders');
      }
    },
    async deleteHostingAccountIndex(context, { account }) {
      const [{ data }] = await ordersRepo.deleteHostingAccountIndex(account);

      return data;
    },
    async boostedOrdersIndex(context, { domain }) {
      context.commit('SET_BOOSTED_ORDERS_LOADED', false);

      const [{ data }, err] = await ordersRepo.getBoostedOrders(domain);
      context.commit('SET_BOOSTED_ORDERS_LOADED', true);

      if (!err) {
        context.commit('SET_BOOSTED_ORDERS', data);
      }

      return true;
    },
    async fetchHostingOrderUsage(context, { orderId, username }) {
      const [{ data }, err] = await ordersRepo.getHostingOrderUsage(
        orderId,
        username,
      );
      if (!err) {
        context.commit('SET_HOSTING_ORDER_USAGE', { usage: data, orderId });
      }
    },

    async updateAccountsBySubscriptionId(context, subscriptionId) {
      const { getResourceBySubscriptionId } = useResourceMapper();
      const resource = getResourceBySubscriptionId(subscriptionId);

      if (!resource?.referenceId) {
        return;
      }

      const [{ data }] = await ordersRepo.getOrdersAccounts(
        resource.referenceId,
      );

      if (!data) return;

      const updatedAccounts = data.map((account) =>
        mapHostingAccountResource(account, resource),
      );

      context.commit('SET_HOSTING_ORDER_ACCOUNTS', {
        accounts: updatedAccounts,
        orderId: subscriptionId,
      });
    },

    async updateAccountsByReferenceId(context, referenceId) {
      const [{ data }] = await ordersRepo.getOrdersAccounts(referenceId);

      if (!data) return;

      const { getResourcesByReferenceId } = useResourceMapper();
      const resource = getResourcesByReferenceId(referenceId);

      const updatedAccounts = data.map((account) =>
        mapHostingAccountResource(account, resource),
      );

      context.commit('SET_HOSTING_ORDER_ACCOUNTS', {
        accounts: updatedAccounts,
        orderId: resource.chargebeeSubscriptionId,
      });
    },
  },
};

const getCurrentDomain = () =>
  toASCII(
    router.currentRoute?.value.params?.domain ||
      router.currentRoute?.value.query?.domain,
  );

const getCurrentId = () => router.currentRoute?.value.params?.id;

const getCurrentAccountData = (state, domain) => {
  const currentDomain = domain ? toASCII(domain) : getCurrentDomain();

  if (!currentDomain) {
    return (
      state.accounts.find(
        (account) => account.orderId === getCurrentId() && account.username,
      ) || state.visitedAccount
    );
  }

  return (
    state.accounts.find(
      (account) => account.domain === currentDomain && account.username,
    ) || state.visitedAccount
  );
};

const getCurrentVhost = (data) =>
  data?.vhosts.find(({ vhost }) => vhost === getCurrentDomain());

const getCurrentAccounts = (state) => {
  const account = getCurrentAccountData(state);

  return getAccountsByOrder(state, account?.orderId);
};

const getAccountsByOrder = (state, findOrderId) =>
  state.accounts.filter(({ orderId }) => orderId === findOrderId);

const parkDomainSelection = ({ parkedDomains }) =>
  parkedDomains.map((domain) => ({
    label: toUnicode(domain.vhost),
    value: domain.vhost,
    type: 'parked',
  }));

const subdomainSelection = (account) =>
  account.subdomains.map(({ subdomain, domain }) => ({
    label: toUnicode(`${subdomain}.${domain}`),
    value: `${subdomain}.${domain}`,
  }));

const domainSelection = (account) => [
  {
    label: toUnicode(account.vhost),
    value: account.vhost,
  },
];
