import { NEXT_BEST_ACTION } from '@hostinger/hdomains-status';
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import WebsiteMonitoringEmailNotificationModal from '@/components/Modals/HModal/WebsiteMonitoringEmailNotificationModal.vue';
import {
  useGlobals,
  useModal,
  useWordpress,
  useDomainStatus,
} from '@/composables';
import { HTTP_STATUS_CODE_200 } from '@/data/globalConstants';
import { aiTroubleshootRepo, websitesRepo } from '@/repositories';
import {
  useProfileStore,
  useWordpressStore,
  useDomainsStatusStore,
} from '@/stores';
import type { IAiTroubleshootMessage } from '@/types';
import {
  AI_TROUBLESHOOT_USER,
  AI_TROUBLESHOOT_LOADING_ACTION_ID,
  AI_TROUBLESHOOT_ACTION_NAME,
  AmplitudeEvent,
  AmplitudeLocation,
  Website,
} from '@/types';
import { isWebsiteStatusOk, timeout } from '@/utils/helpers';
import { isPreviewDomain } from '@/utils/helpers/domainsHelper';
import { getAiTroubleshooterArticle } from '@/utils/services/supportArticleService';

export const useAiTroubleshootStore = defineStore('aiTroubleshootStore', () => {
  const autoFixStatusCodes = [403, 404, 500];

  const { amplitudeV2 } = useGlobals();
  const profileStore = useProfileStore();
  const { openModal } = useModal();
  const store = useStore();
  const { handleWordPressWebsiteValidation } = useWordpress();
  const wordpressStore = useWordpressStore();
  const route = useRoute();

  const domainStatusStore = useDomainsStatusStore();

  const displayedDomain = computed(
    (): string =>
      (route.params.domainToManage as string) ||
      (route.params.domain as string),
  );

  const { refetchDomainStatus, domainNextBestActionMap, isUsingCFNameservers } =
    useDomainStatus(displayedDomain.value);

  const showTemporaryDomainMessage = ref(false);
  const isAiTroubleshootFailed = ref(false);
  const isLoading = ref(false);
  const websiteStatusCode = ref(0);
  const isDisclaimerShown = ref(false);
  const showDomainIssueMessage = ref(false);
  const hasDomainIssue = ref(false);
  const waitForPusherMessage = ref(false);

  const pusherResponse = ref<{
    domain: string;
    result: IAiTroubleshootMessage;
  } | null>(null);

  const domainStatus = computed(() =>
    domainStatusStore.getDomainStatus(displayedDomain.value),
  );

  const isNoErrorDetected = computed(
    () =>
      (isWebsiteStatusOk(websiteStatusCode.value) ||
        isUsingCFNameservers.value) &&
      wordpressStore.isWordPressVersionValid,
  );
  const messageLoaders = ref<Record<string, boolean>>({
    [AI_TROUBLESHOOT_LOADING_ACTION_ID.initDomain]: false,
    [AI_TROUBLESHOOT_LOADING_ACTION_ID.init]: false,
    [AI_TROUBLESHOOT_LOADING_ACTION_ID.applyingSolution]: false,
    [AI_TROUBLESHOOT_LOADING_ACTION_ID.autoFix]: false,
  });

  const messages = ref<IAiTroubleshootMessage[]>([]);
  const isTroubleshootRunAtLeastOnce = ref(false);
  const isDomainConnected = computed(
    () =>
      domainStatus.value?.additionalDetails?.domainPointingStatus?.status ===
      'Connected',
  );

  const domainAction = computed(() => {
    if (
      !domainStatus.value?.domainStatus?.nextBestAction ||
      isPreviewDomain(domainStatus.value.domain)
    ) {
      return null;
    }

    const nextBestAction = domainStatus.value.domainStatus.nextBestAction;

    return domainNextBestActionMap[nextBestAction] || null;
  });

  const checkIfDomainIsConnected = async () => {
    if (!domainStatus.value) {
      isLoading.value = true;
      await refetchDomainStatus();
      isLoading.value = false;
    }

    messageLoaders.value[AI_TROUBLESHOOT_LOADING_ACTION_ID.initDomain] = true;

    addMessage({
      user: AI_TROUBLESHOOT_USER.AI,
      message:
        'Analyzing the possible cause and the solution to fix it. This process will take me a while, so please wait until the process is done.',
      loadingActionId: AI_TROUBLESHOOT_LOADING_ACTION_ID.initDomain,
      isProblemDetected:
        !isDomainConnected.value &&
        !isPreviewDomain(domainStatus.value?.domain),
    });
    // WAITING FOR SPINNER TO MAINTAIN LOADING STATE
    await timeout(2000);
    checkDomainStatus();
    messageLoaders.value[AI_TROUBLESHOOT_LOADING_ACTION_ID.initDomain] = false;
  };

  const checkDomainStatus = () => {
    const nextBestAction = domainStatus.value?.domainStatus?.nextBestAction;
    if (!nextBestAction) {
      return;
    }
    if (nextBestAction === NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL) {
      addMessage({
        user: AI_TROUBLESHOOT_USER.AI,
        message: `After analyzing your website, this could be the most possible cause of your problem: \n Domain ${displayedDomain.value} is not pointing to Hostinger nameservers. Select your domain provider:`,
      });

      showDomainIssueMessage.value = true;

      return (hasDomainIssue.value = true);
    }
    if (nextBestAction === NEXT_BEST_ACTION.CONNECT_DOMAIN) {
      addMessage({
        user: AI_TROUBLESHOOT_USER.AI,
        message: `After analyzing your website, this could be the most possible cause of your problem: \n Domain ${displayedDomain.value} is not pointing to Hostinger nameservers. Connect domain to solve this issue.`,
        actions: [
          {
            id: uuidv4(),
            title: 'Connect domain',
            actionName: AI_TROUBLESHOOT_ACTION_NAME.temporaryDomain,
            isPrimary: true,
          },
        ],
      });

      return (hasDomainIssue.value = true);
    }

    if (nextBestAction === NEXT_BEST_ACTION.BUY_DOMAIN) {
      addMessage({
        user: AI_TROUBLESHOOT_USER.AI,
        message: `After analyzing your website, this could be the most possible cause of your problem: \n Domain ${displayedDomain.value} is not purchased. Complete the payment to activate the domain and solve this issue.`,
        actions: [
          {
            id: uuidv4(),
            title: domainAction.value?.props?.actionText ?? '',
            actionName: AI_TROUBLESHOOT_ACTION_NAME.buyDomain,
            isPrimary: true,
          },
        ],
      });

      return (hasDomainIssue.value = true);
    }

    if (nextBestAction === NEXT_BEST_ACTION.FINISH_REGISTRATION) {
      addMessage({
        user: AI_TROUBLESHOOT_USER.AI,
        message: `After analyzing your website, this could be the most possible cause of your problem: \n Domain ${displayedDomain.value}  is not registered. Finish domain registration to solve the issue.`,
        actions: [
          {
            id: uuidv4(),
            title: domainAction.value?.props?.actionText ?? '',
            actionName: AI_TROUBLESHOOT_ACTION_NAME.finishRegistration,
            isPrimary: true,
          },
        ],
      });

      return (hasDomainIssue.value = true);
    }
    if (nextBestAction === NEXT_BEST_ACTION.RESTORE_DOMAIN) {
      addMessage({
        user: AI_TROUBLESHOOT_USER.AI,
        message: `After analyzing your website, this could be the most possible cause of your problem: \n Domain ${displayedDomain.value} has expired and currently in redemption period. Restore the domain to solve the issue.`,
        actions: [
          {
            id: uuidv4(),
            title: domainAction.value?.props?.actionText ?? '',
            actionName: AI_TROUBLESHOOT_ACTION_NAME.restoreDomain,
            isPrimary: true,
          },
        ],
      });

      return (hasDomainIssue.value = true);
    }
  };

  const getInitData = async () => {
    $reset();
    await checkIfDomainIsConnected();
    if (hasDomainIssue.value) return;

    isLoading.value = true;
    await getWebsiteErrorCode();
    isLoading.value = false;

    addMessage({
      user: AI_TROUBLESHOOT_USER.AI,
      message:
        'Analyzing the possible cause and the solution to fix it. This process will take me a while, so please wait until the process is done.',
      loadingActionId: AI_TROUBLESHOOT_LOADING_ACTION_ID.init,
    });

    if (autoFixStatusCodes.includes(websiteStatusCode.value)) {
      handleAutoFixError();
    } else {
      await getTroubleshootAnalyze();
    }
  };

  const getWebsiteErrorCode = async (domain?: string) => {
    const account = store.getters.getCurrentAccountWithDomain(
      domain || (route.params.domain as string),
    );

    websiteStatusCode.value = 0;
    const params = {
      domain: domain || displayedDomain.value,
      directory: (route.params.directory as string) || '',
      username: account.username,
    };

    const [{ data }, err] = await websitesRepo.getWebsiteHttpStatus(params);

    if (!data || err) {
      handleAiTroubleshootError();

      return;
    }

    websiteStatusCode.value = data?.code;

    if (websiteStatusCode.value === HTTP_STATUS_CODE_200) {
      await validatePossibleWebsiteError();
    }
  };

  const validatePossibleWebsiteError = async () => {
    await handleWordPressWebsiteValidation({
      domain: displayedDomain.value,
      domainToManage: route.params.domainToManage as string,
      directory: route.params.directory as string,
    });
  };

  const handleAutoFixError = () => {
    amplitudeV2(AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_PROBLEM_SHOWN, {
      websiteStatusCode: websiteStatusCode.value,
    });
    addMessage({
      user: AI_TROUBLESHOOT_USER.AI,
      message: `After analyzing your website, we found <strong>${websiteStatusCode.value} error</strong> and several possible ways to fix it. Let us take care of your site.`,
      actions: [
        {
          id: uuidv4(),
          title: 'Fix my website',
          actionName: AI_TROUBLESHOOT_ACTION_NAME.autoFix,
          isBackendAction: true,
          isPrimary: true,
        },
      ],
    });
  };

  const getTroubleshootAnalyze = async () => {
    if (isAiTroubleshootFailed.value || isNoErrorDetected.value) {
      return;
    }

    messageLoaders.value[AI_TROUBLESHOOT_LOADING_ACTION_ID.init] = true;

    const [{ data }, err] = await aiTroubleshootRepo.fetchTroubleshootAnalyze(
      (route.params.domainToManage as string) ||
        (route.params.domain as string),
      route.params.directory as string,
    );

    if (!data || err) {
      handleAiTroubleshootError();

      return;
    }

    const analysisResult = data?.analysisResult;
    const suggestedActions = data?.suggestedActions;

    amplitudeV2(AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_PROBLEM_SHOWN, {
      action: analysisResult?.action ?? 'none',
    });

    isTroubleshootRunAtLeastOnce.value = !analysisResult?.isActionSupported;

    addMessage({
      reportUuid: data.checkUuid,
      user: AI_TROUBLESHOOT_USER.AI,
      message: data?.message,
      actions: analysisResult?.isActionSupported && [
        {
          id: uuidv4(),
          title: 'Fix my website',
          actionName: analysisResult?.action,
          isBackendAction: true,
          isPrimary: true,
        },
      ],
      pluginSlug: suggestedActions?.[0]?.checkUuid,
    });

    messageLoaders.value[AI_TROUBLESHOOT_LOADING_ACTION_ID.init] = false;
  };

  const handleTroubleshootAction = (
    reportUuid: string = '',
    action: string,
    actionProp?: string,
  ) => {
    const actionFunctions: Record<string, () => void> = {
      [AI_TROUBLESHOOT_ACTION_NAME.temporaryDomain]: () =>
        handleTemporaryDomain(),
      [AI_TROUBLESHOOT_ACTION_NAME.buyDomain]: () => openBuyDomainModal(),
      [AI_TROUBLESHOOT_ACTION_NAME.finishRegistration]: () =>
        domainAction.value?.action(),
      [AI_TROUBLESHOOT_ACTION_NAME.restoreDomain]: () =>
        domainAction.value?.action(),
      [AI_TROUBLESHOOT_ACTION_NAME.retry]: () => handleRetryAction(),
      [AI_TROUBLESHOOT_ACTION_NAME.survey]: () => handleSurveyAction(),
      [AI_TROUBLESHOOT_ACTION_NAME.surveyAnswer]: () =>
        handleSurveyAnswerAction(actionProp),
      [AI_TROUBLESHOOT_ACTION_NAME.openWebsite]: () => handleOpenWebsite(),
      [AI_TROUBLESHOOT_ACTION_NAME.autoFix]: () => handleAutoFix(),
      [AI_TROUBLESHOOT_ACTION_NAME.openKnowledgeBase]: () =>
        handleOpenKnowledgeBase(),
    };

    if (actionFunctions[action]) {
      actionFunctions[action]();
    } else {
      postTroubleshootAction(reportUuid);
    }
  };

  const handleAutoFix = async () => {
    amplitudeV2(AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_FIX, {
      websiteStatusCode: websiteStatusCode.value,
    });
    messageLoaders.value[AI_TROUBLESHOOT_LOADING_ACTION_ID.autoFix] = true;
    addMessage({
      user: AI_TROUBLESHOOT_USER.AI,
      message: 'Applying solutions',
      loadingActionId: AI_TROUBLESHOOT_LOADING_ACTION_ID.autoFix,
    });
    const [, err] = await postAutoFix();

    if (err) {
      handleAiTroubleshootError();

      return;
    }
  };

  const postAutoFix = async (params?: {
    domain: string;
    directory: string;
    username: string;
    referenceId: string;
  }) => {
    waitForPusherMessage.value = true;

    return await aiTroubleshootRepo.postTroubleshootAutoFix(
      websiteStatusCode.value.toString(),
      params?.directory || (route.params.directory as string),
      params?.domain ||
        (route.params.domainToManage as string) ||
        (route.params.domain as string),
      params?.username,
      params?.referenceId,
    );
  };

  const handleTemporaryDomain = () => {
    domainAction.value?.action({ websiteType: Website.Type.WORDPRESS });
  };

  const openBuyDomainModal = () => {
    domainAction.value?.action({
      location: AmplitudeLocation.Base.AI_TROUBLESHOOTER,
    });
  };

  const handleRetryAction = () => {
    amplitudeV2(AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_TRY_AGAIN);
    addMessage({
      user: AI_TROUBLESHOOT_USER.USER,
      message:
        'My website is still not working. Can you try a different solution?',
    });

    addMessage({
      user: AI_TROUBLESHOOT_USER.AI,
      message:
        'Sure! I will come back to you with another possible cause and the solution to fix that cause. This process will take me a while, so please wait until the process is completely done.',
      loadingActionId: AI_TROUBLESHOOT_LOADING_ACTION_ID.init,
    });

    getTroubleshootAnalyze();
  };

  const handleOpenKnowledgeBase = () => {
    const article = getAiTroubleshooterArticle(websiteStatusCode.value);

    if (!article) return;

    window.open(article, '_blank');
  };

  const handleOpenWebsite = () => {
    const websiteDirectory = (route.params.directory as string) || '';
    window.open(
      `http://${displayedDomain.value}/${websiteDirectory}`,
      '_blank',
    );
  };

  const handleSurveyAction = () => {
    amplitudeV2(AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_SUCCESS);

    if (profileStore.isPro) openAutoMonitoringModalAfter2sec();

    addMessage({
      user: AI_TROUBLESHOOT_USER.USER,
      message: 'My website is working now. Thank you!',
      loadingActionId: AI_TROUBLESHOOT_LOADING_ACTION_ID.applyingSolution,
    });

    wordpressStore.detectWordPressRun();

    const surveyMessage = profileStore.contact?.firstName
      ? `Anytime, ${profileStore.contact?.firstName}! I want to ask your feedback about how I solve your website problem. How was your experience using my help?`
      : `Anytime! I want to ask your feedback about how I solve your website problem. How was your experience using my help?`;

    addMessage({
      user: AI_TROUBLESHOOT_USER.AI,
      message: surveyMessage,
      actions: [
        {
          id: uuidv4(),
          title: 'Very poor',
          actionName: AI_TROUBLESHOOT_ACTION_NAME.surveyAnswer,
          actionValue: 'very_poor',
        },
        {
          id: uuidv4(),
          title: 'Poor',
          actionName: AI_TROUBLESHOOT_ACTION_NAME.surveyAnswer,
          actionValue: 'poor',
        },
        {
          id: uuidv4(),
          title: 'Neutral',
          actionName: AI_TROUBLESHOOT_ACTION_NAME.surveyAnswer,
          actionValue: 'neutral',
        },
        {
          id: uuidv4(),
          title: 'Satisfied',
          actionName: AI_TROUBLESHOOT_ACTION_NAME.surveyAnswer,
          actionValue: 'satisfied',
        },
        {
          id: uuidv4(),
          title: 'Very satisfied',
          actionName: AI_TROUBLESHOOT_ACTION_NAME.surveyAnswer,
          actionValue: 'very_satisfied',
        },
      ],
    });
  };

  const handleSurveyAnswerAction = (rating = '') => {
    amplitudeV2(AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_SURVEY_FILLED, {
      rating,
    });
  };

  const setDisclaimerShown = (status: boolean) => {
    isDisclaimerShown.value = status;
  };

  const postTroubleshootAction = async (reportUuid = '') => {
    isTroubleshootRunAtLeastOnce.value = false;

    messageLoaders.value[AI_TROUBLESHOOT_LOADING_ACTION_ID.applyingSolution] =
      true;

    amplitudeV2(AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_FIX);

    addMessage({
      user: AI_TROUBLESHOOT_USER.USER,
      message: 'Can you help me to fix my website based on your suggestion?',
    });

    addMessage({
      user: AI_TROUBLESHOOT_USER.AI,
      message: 'Sure! Hang on a sec while I’m applying the solution.',
      loadingActionId: AI_TROUBLESHOOT_LOADING_ACTION_ID.applyingSolution,
    });

    const [{ data }, err] = await aiTroubleshootRepo.postTroubleshootAction(
      reportUuid,
      route.params.directory as string,
      (route.params.domainToManage as string) ||
        (route.params.domain as string),
    );

    isTroubleshootRunAtLeastOnce.value = true;

    if (!data || err) {
      handleAiTroubleshootError();

      return;
    }

    await handleTroubleshootActionComplete();

    messageLoaders.value[AI_TROUBLESHOOT_LOADING_ACTION_ID.applyingSolution] =
      false;
  };

  const handleTroubleshootActionComplete = async () => {
    await getWebsiteErrorCode();

    if (websiteStatusCode.value === HTTP_STATUS_CODE_200) {
      amplitudeV2(AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_SUCCESS);

      addMessage({
        user: AI_TROUBLESHOOT_USER.AI,
        message: 'Your website is now functioning without any issues.',
        actions: [
          {
            id: uuidv4(),
            title: 'Visit my website',
            actionName: AI_TROUBLESHOOT_ACTION_NAME.openWebsite,
            isPrimary: true,
          },
        ],
      });

      return;
    }

    addMessage({
      user: AI_TROUBLESHOOT_USER.AI,
      message:
        'Your website should be working now, please check your website. If your website is still not working, click the Try another solution button. I will try to find different solutions.',
      actions: [
        {
          id: uuidv4(),
          title: 'Try another solution',
          actionName: AI_TROUBLESHOOT_ACTION_NAME.retry,
        },
        {
          id: uuidv4(),
          title: 'My website is working now',
          actionName: AI_TROUBLESHOOT_ACTION_NAME.survey,
          isPrimary: true,
        },
      ],
    });
  };

  const handleTroubleshootActionCompleteFromPusher = (data: {
    variables: {
      finalHttpStatus: number;
      domain: string;
      directory: string;
    };
  }) => {
    if (!waitForPusherMessage.value) return;

    const MESSAGES = {
      successAutoFix: {
        user: AI_TROUBLESHOOT_USER.STATUS_SUCCESS,
        message: `<strong>${websiteStatusCode.value} Error is successfully resolved!</strong><br />
        Resetting file permissions helped to resolve the issue. Website should appear online soon.`,
        actions: [
          {
            id: uuidv4(),
            title: 'Check website',
            actionName: AI_TROUBLESHOOT_ACTION_NAME.openWebsite,
            isPrimary: true,
          },
        ],
        isSuccess: true,
      },
      failedAutoFix: {
        user: AI_TROUBLESHOOT_USER.STATUS_FAILED,
        message: `<strong>${websiteStatusCode.value} error was not resolved</strong><br />
        After applying all automatic fixes, we were unable to resolve the error on your website. Learn more about additional troubleshooting options from our Knowledge Base article.`,
        actions:
          websiteStatusCode.value === 403
            ? [
                {
                  id: uuidv4(),
                  title: 'Learn more',
                  actionName: AI_TROUBLESHOOT_ACTION_NAME.openKnowledgeBase,
                  isPrimary: true,
                },
              ]
            : [],
        isFailed: true,
      },
    };

    messageLoaders.value[AI_TROUBLESHOOT_LOADING_ACTION_ID.autoFix] = false;

    const { finalHttpStatus, domain } = data?.variables || {};

    if (
      displayedDomain.value !== undefined &&
      domain !== displayedDomain.value
    ) {
      return;
    }

    if (finalHttpStatus === 200) {
      amplitudeV2(AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_SUCCESS, {
        websiteStatusCode: websiteStatusCode.value,
      });
      addMessage(MESSAGES.successAutoFix);
      pusherResponse.value = { domain, result: MESSAGES.successAutoFix };
      wordpressStore.detectWordPressRun();
    } else {
      amplitudeV2(AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_FAILED, {
        websiteStatusCode: websiteStatusCode.value,
      });
      pusherResponse.value = { domain, result: MESSAGES.failedAutoFix };
      addMessage(MESSAGES.failedAutoFix);
    }
  };

  const handleAiTroubleshootError = () => {
    isAiTroubleshootFailed.value = true;
    waitForPusherMessage.value = false;
    isLoading.value = false;
    messageLoaders.value = {
      [AI_TROUBLESHOOT_LOADING_ACTION_ID.init]: false,
      [AI_TROUBLESHOOT_LOADING_ACTION_ID.applyingSolution]: false,
      [AI_TROUBLESHOOT_LOADING_ACTION_ID.autoFix]: false,
    };

    addMessage({
      user: AI_TROUBLESHOOT_USER.AI,
      message:
        'I apologize for not being able to respond. I encountered some technical difficulties that prevented me from generating a proper reply. Please try later.',
    });
  };

  const addMessage = (AiTroubleshootMessage: IAiTroubleshootMessage) => {
    if (!AiTroubleshootMessage.message) return;
    messages.value.push({ id: uuidv4(), ...AiTroubleshootMessage });
  };

  const $reset = () => {
    isAiTroubleshootFailed.value = false;
    messages.value = [];
    showDomainIssueMessage.value = false;
    showTemporaryDomainMessage.value = false;
    waitForPusherMessage.value = false;
    pusherResponse.value = null;
  };

  const openAutoMonitoringModalAfter2sec = () => {
    setTimeout(() => {
      openModal({
        component: { WebsiteMonitoringEmailNotificationModal },
      });
    }, 2000);
  };

  return {
    getInitData,
    getWebsiteErrorCode,
    getTroubleshootAnalyze,
    postTroubleshootAction,
    handleTroubleshootAction,
    handleTroubleshootActionCompleteFromPusher,
    postAutoFix,
    addMessage,
    handleOpenKnowledgeBase,
    setDisclaimerShown,
    websiteStatusCode,
    isNoErrorDetected,
    messages,
    isLoading,
    messageLoaders,
    isTroubleshootRunAtLeastOnce,
    isAiTroubleshootFailed,
    isDisclaimerShown,
    isDomainConnected,
    showDomainIssueMessage,
    domainAction,
    hasDomainIssue,
    autoFixStatusCodes,
    pusherResponse,
    $reset,
  };
});
