import { ALIASES_TOOLTIP, Perks } from '@/data/emails/emailPurchasePerks';
import type { Feature } from '@/types';

export const dnsHelpArticles = [
  {
    label: 'Hostinger',
    value:
      'https://support.hostinger.com/en/articles/1583249-how-to-manage-my-dns-records-at-hostinger',
    selected: true,
  },
  {
    label: 'GoDaddy',
    value:
      'https://support.hostinger.com/en/articles/4410579-how-to-manage-dns-zone-on-godaddy',
    selected: false,
  },
  {
    label: 'NameCheap',
    value:
      'https://support.hostinger.com/en/articles/4410794-how-to-manage-dns-zone-on-namecheap',
    selected: false,
  },
  {
    label: 'SiteGround',
    value:
      'https://support.hostinger.com/en/articles/4410863-how-to-manage-dns-zone-on-siteground',
    selected: false,
  },
  {
    label: 'BlueHost',
    value:
      'https://support.hostinger.com/en/articles/4410635-how-to-manage-dns-zone-on-bluehost',
    selected: false,
  },
  {
    label: 'DreamHost',
    value:
      'https://support.hostinger.com/en/articles/4410642-how-to-manage-dns-zone-on-dreamhost',
    selected: false,
  },
  {
    label: 'HostGator',
    value:
      'https://support.hostinger.com/en/articles/4410748-how-to-manage-dns-zone-on-hostgator',
    selected: false,
  },
  {
    label: 'NameSilo',
    value:
      'https://support.hostinger.com/en/articles/4410811-how-to-manage-dns-zone-on-namesilo',
    selected: false,
  },
  {
    label: 'Name.com',
    value:
      'https://support.hostinger.com/en/articles/4410766-how-to-manage-dns-zone-on-name-com',
    selected: false,
  },
  {
    label: 'Wix',
    value:
      'https://support.hostinger.com/en/articles/4410883-how-to-manage-dns-zone-on-wix',
    selected: false,
  },
  {
    label: 'One.com',
    value:
      'https://support.hostinger.com/en/articles/4410844-how-to-manage-dns-zone-on-one-com',
    selected: false,
  },
];

export const FREE_TRIAL_PERKS: Feature[] = [
  {
    value: '10 GB Email storage',
    id: 'email-storage',
    tooltip: 'Specific storage space is assigned for each created account',
  },
  {
    value: '10 Forwarding Rules',
    id: 'forwarding-rules',
    tooltip:
      'Forward emails sent to your account to any other email address (even from a different domain).',
  },
  {
    value: Perks.EMAIL_ALIASES,
    id: 'account-aliases',
    tooltip: ALIASES_TOOLTIP,
    tooltipValues: { count: 50 },
  },
  {
    value: 'Advanced anti-spam and anti-virus',
    id: 'protection',
    tooltip:
      'Top-notch security measures to keep your accounts safe from spam, malware, phishing attacks, etc.',
  },
  {
    value: 'Custom DKIM',
    id: 'custom-dkim',
    tooltip:
      'Custom DKIM (DomainKeys Identified Mail) authenticates the email sender’s domain. Generate a domain-matched DKIM key to prove that you are the actual sender of your emails.',
  },
  {
    value: 'Cloud-Based Infrastructure',
    id: 'cloud',
    tooltip:
      'Cloud email services help to guarantee deliverability, high uptime, and low delays from any place in the world',
  },
  {
    value: 'Rich webmail',
    id: 'webmail',
    tooltip:
      'Webmail makes sending and reading emails easier directly from your web browser on any device without installing a software or configuring new devices',
  },
  {
    value: 'One-click import of existing emails',
    id: 'email-import',
    tooltip:
      'A new professional email account doesn’t have to mean leaving your old contacts and emails behind. Hostinger’s built in import tools make it easy to get going with a new account.',
  },
  {
    value: 'IMAP/POP access',
    id: 'imap-pop',
    tooltip:
      'Access email accounts of your choice third-party email services using IMAP/POP protocols',
  },
];
export const HOSTINGER_BUSINESS_SUGGESTION_PERKS = [
  {
    value: '10 GB Email storage',
    id: 'email-storage',
    tooltip: 'Specific storage space is assigned for each created account',
  },
  {
    value: '10 Forwarding Rules',
    id: 'forwarding-rules',
    tooltip:
      'Forward emails sent to your account to any other email address (even from a different domain).',
  },
  {
    value: Perks.EMAIL_ALIASES,
    id: 'account-aliases',
    tooltip: ALIASES_TOOLTIP,
    tooltipValues: { count: 50 },
  },
  {
    value: 'Advanced anti-spam and anti-virus',
    id: 'protection',
    tooltip:
      'Top-notch security measures to keep your accounts safe from spam, malware, phishing attacks, etc.',
  },
  {
    value: 'Custom DKIM',
    id: 'custom-dkim',
    tooltip:
      'Custom DKIM (DomainKeys Identified Mail) authenticates the email sender’s domain. Generate a domain-matched DKIM key to prove that you are the actual sender of your emails.',
  },
  {
    value: 'Cloud-Based Infrastructure',
    id: 'cloud',
    tooltip:
      'Cloud email services help to guarantee deliverability, high uptime, and low delays from any place in the world',
  },
  {
    value: 'Rich webmail',
    id: 'webmail',
    tooltip:
      'Webmail makes sending and reading emails easier directly from your web browser on any device without installing a software or configuring new devices',
  },
  {
    value: 'One-click import of existing emails',
    id: 'email-import',
    tooltip:
      'A new professional email account doesn’t have to mean leaving your old contacts and emails behind. Hostinger’s built in import tools make it easy to get going with a new account.',
  },
  {
    value: 'IMAP/POP access',
    id: 'imap-pop',
    tooltip:
      'Access email accounts of your choice third-party email services using IMAP/POP protocols',
  },
];

/**
 * Used as a threshold for identifying if domain is still in propagation. At the moment
 * we choose 6 hours, which according to propagation time statistics should be enough.
 * When UDS provides propagation status out of the box, this value should be removed.
 */
export const TIME_TO_PROPAGATE_DOMAIN_CALCULATIONS = 6; // hours

/**
 * Displayed for clients. It is higher than the on used for calculations
 * just to be safe and give us some space for unexpected delays.
 */
export const TIME_TO_PROPAGATE_DOMAIN_DISPLAYED = 12; // hours
