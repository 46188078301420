import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useWebsiteReportActions } from '@/composables';
import { useGlobals } from '@/composables/useGlobals';
import { useScheduledReportsStore } from '@/stores/developerTools/scheduledReportsStore';
import { useProfileStore } from '@/stores/profile/profileStore';
import { useWordpressStore } from '@/stores/wordpressStore';
import type {
  ICreateReportContactDetails,
  ICreateReportParams,
  ICreateReportScheduleDetails,
  ICreateReportStructureDetails,
} from '@/types';
import { Header, Route, ScheduleType } from '@/types';

export const useCreateReportStore = defineStore('createReportStore', () => {
  const { t } = useGlobals();
  const profileStore = useProfileStore();
  const route = useRoute();
  const scheduledReportStore = useScheduledReportsStore();
  const wordpressStore = useWordpressStore();

  const { IS_WEBSITE_ADDRESS_ENABLED, getScheduleTypeOptionFromParam } =
    useWebsiteReportActions();

  const isTemplatesEnabled = ref(false);
  const shouldIncludeContactDetails = ref(true);

  const hasSupportForSpecialChars = false;

  const DEFAULT_TITLE = computed(() =>
    hasSupportForSpecialChars
      ? t('v2.reports.default.title')
      : 'Maintenance report',
  );

  const DEFAULT_INTRO_MESSAGE = computed(() =>
    hasSupportForSpecialChars
      ? t('v2.reports.default.intro.message')
      : 'This is the summary of your website’s health and all the work we have done to keep it up do date and well performing for the last 30 days. If you have any questions, please feel free to contact us.',
  );

  const defaultCompanyName = computed(
    () => profileStore.contact?.companyName || '',
  );
  const defaultEmail = computed(() => profileStore.contact?.email || '');
  const defaultPhoneNumber = computed(() => {
    if (!profileStore.contact?.phone || !profileStore.contact?.phoneCc) {
      return '';
    }

    return `${profileStore.contact.phoneCc}${profileStore.contact.phone}`;
  });

  const defaultWebsiteAddress = computed(() => '');

  const emptyContactDetails = computed(() => ({
    companyName: '',
    email: '',
    phone: '',
    ...(IS_WEBSITE_ADDRESS_ENABLED ? [{ websiteAddress: '' }] : []),
  }));

  const isEditScheduleFlow = computed(
    () => route.name === Route.DeveloperTools.EDIT_REPORT_SCHEDULE,
  );

  const details = ref<ICreateReportParams>({
    domain: '',
    title: DEFAULT_TITLE.value,
    introMessage: DEFAULT_INTRO_MESSAGE.value,
    contactDetails: {
      companyName: defaultCompanyName.value,
      email: defaultEmail.value,
      phone: defaultPhoneNumber.value,
      ...(IS_WEBSITE_ADDRESS_ENABLED
        ? [{ websiteAddress: defaultWebsiteAddress.value }]
        : []),
    },
    structure: {},
    deliverToEmail: '',
    schedule: {
      scheduleType: ScheduleType.NONE,
      scheduleValue: undefined,
      scheduleHour: undefined,
    },
  });

  const setShouldIncludeContactDetails = (value: boolean) =>
    (shouldIncludeContactDetails.value = value);

  const setDomain = (targetDomain: string) => {
    details.value.domain = targetDomain;
  };

  const set = <K extends keyof ICreateReportParams>(
    field: K,
    value: ICreateReportParams[K],
  ) => {
    details.value = {
      ...details.value,
      [field]: value,
    };
  };

  const setContactDetails = <K extends keyof ICreateReportContactDetails>(
    field: K,
    value: ICreateReportContactDetails[K],
  ) => {
    details.value.contactDetails = {
      ...details.value.contactDetails,
      [field]: value,
    };
  };

  const setStructureDetails = <K extends keyof ICreateReportStructureDetails>(
    field: K,
    value: ICreateReportStructureDetails[K],
  ) => {
    details.value.structure = {
      ...details.value.structure,
      [field]: value,
    };
  };

  const setScheduleDetails = <K extends keyof ICreateReportScheduleDetails>(
    field: K,
    value: ICreateReportScheduleDetails[K],
  ) => {
    if (field === 'scheduleType') {
      details.value.schedule = {
        scheduleType: value as ScheduleType,
        scheduleValue: undefined,
        scheduleHour: undefined,
      };

      return;
    }

    details.value.schedule = {
      ...details.value.schedule,
      [field]: value,
    };
  };

  const setDeliverToEmail = (receiverEmail: string) => {
    details.value.deliverToEmail = receiverEmail;
  };

  const resetContactDetails = () => {
    details.value.contactDetails = {
      companyName: '',
      email: '',
      phone: '',
      ...(IS_WEBSITE_ADDRESS_ENABLED ? [{ websiteAddress: '' }] : []),
    };
  };

  const getIsDetailsScheduleTypeSelected = (type: ScheduleType) =>
    details.value.schedule.scheduleType === type;

  const prefillScheduledReportData = (id: number) => {
    const schedule = scheduledReportStore.getScheduleById(id);

    if (!schedule) {
      return;
    }

    details.value = {
      domain: schedule?.domain,
      title: schedule?.title,
      introMessage: schedule?.introMessage,
      contactDetails: schedule.contactDetails,
      structure: schedule.structure,
      deliverToEmail: schedule?.deliverToEmail,
      schedule: {
        scheduleType: getScheduleTypeOptionFromParam(schedule.scheduleType),
        scheduleValue: `${schedule.scheduleValue}`,
        scheduleHour: `${schedule.scheduleHour}`,
      },
    };
  };

  const $reset = () => {
    shouldIncludeContactDetails.value = true;

    details.value = {
      domain: '',
      title: DEFAULT_TITLE.value,
      introMessage: DEFAULT_INTRO_MESSAGE.value,
      contactDetails: {
        companyName: defaultCompanyName.value,
        email: defaultEmail.value,
        phone: defaultPhoneNumber.value,
        ...(IS_WEBSITE_ADDRESS_ENABLED
          ? [{ websiteAddress: defaultWebsiteAddress.value }]
          : []),
      },
      structure: {},
      deliverToEmail: '',
      schedule: {
        scheduleType: ScheduleType.NONE,
        scheduleValue: undefined,
        scheduleHour: undefined,
      },
    };
  };

  const reportRequestHeaders = computed(() => {
    const domain = details.value.domain || '';
    const website =
      wordpressStore.getManagedAndOwnedWordpressWebsiteByDomain(domain);

    if (!website) return null;

    return {
      [Header.USERNAME]: website.username,
      [Header.ORDER_ID]: website.orderId,
      [Header.DOMAIN]: domain,
    };
  });

  const getReportsMainParams = (isEditScheduleFlow = false) => {
    const website = wordpressStore.getManagedAndOwnedWordpressWebsiteByDomain(
      details.value.domain || '',
    );

    if (!website) return null;

    const { title, introMessage, contactDetails, structure, deliverToEmail } =
      details.value;

    return {
      title,
      introMessage,
      contactDetails: shouldIncludeContactDetails.value
        ? contactDetails
        : emptyContactDetails.value,
      directory: website.directory,
      structure,
      deliverToEmail: deliverToEmail || null,
      ...(isEditScheduleFlow ? { whiteLabel: null } : {}),
    };
  };

  return {
    details,
    isEditScheduleFlow,
    isTemplatesEnabled,
    shouldIncludeContactDetails,
    emptyContactDetails,
    reportRequestHeaders,
    setDomain,
    set,
    setContactDetails,
    setShouldIncludeContactDetails,
    setStructureDetails,
    setScheduleDetails,
    setDeliverToEmail,
    resetContactDetails,
    getIsDetailsScheduleTypeSelected,
    prefillScheduledReportData,
    getReportsMainParams,
    $reset,
  };
});
