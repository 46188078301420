<template>
  <div ref="banners" class="banner-container">
    <MaintenanceNotification v-if="isInHomepage" />
    <template v-for="banner in bannersPriorityList" :key="banner.orderId">
      <Component
        :is="banner.component"
        v-if="banner.vIf"
        v-show="
          priorityBannerId === banner.orderId &&
          banner.vShow &&
          !isAutoRenewBannerLoading
        "
        :banner="banner"
        @banner-visibility="
          banner.setVisibility && banner.setVisibility($event)
        "
        @loading-banner="banner.setLoading && banner.setLoading($event)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { mapGetters } from 'vuex';

import BlackFridayBanner from '@/components/Header/Banners/BlackFridayBanner.vue';
import DomainBulkActionBanner from '@/components/Header/Banners/DomainBulkActionBanner.vue';
import DomainStatusBanner from '@/components/Header/Banners/DomainStatusBanners/DomainStatusBanner.vue';
import FailedAutoRenewBanner from '@/components/Header/Banners/FailedAutoRenewBanner.vue';
import HostingPageDeprecationBanner from '@/components/Header/Banners/HostingPageDeprecationBanner.vue';
import PerformanceDropBanner from '@/components/Header/Banners/PerformanceDropBanner.vue';
import UkraineSupportBanner from '@/components/Header/Banners/UkraineSupportBanner.vue';
import MaintenanceNotification from '@/components/Hosting/Maintenance/MaintenanceNotification.vue';
import { useSaleCampaign, useHostingV2, useFeatureFlag } from '@/composables';
import { useDomainBulkActionsStore } from '@/stores/domainBulkActionsStore';
import type { HeaderType } from '@/types';
import { Route, RoutePath, FeatureFlag } from '@/types';

const EXCLUDED_DOMAIN_STATUS_ROUTES = ['order_upgrade_v2'];

type Data = {
  showDomainStatus: boolean;
  showHostingMaintenance: boolean;
  showPerformanceDrop: boolean;
  showFailedAutorenewBanner: boolean;
  showHostingDeprecationBanner: boolean;
  isAutoRenewBannerLoading: boolean;
  bannerHeightObserver: null | ResizeObserver;
};

export default defineComponent({
  name: 'BannerGenerator',
  components: {
    DomainStatusBanner,
    MaintenanceNotification,
    PerformanceDropBanner,
    FailedAutoRenewBanner,
    UkraineSupportBanner,
    DomainBulkActionBanner,
    BlackFridayBanner,
    HostingPageDeprecationBanner,
  },
  emits: ['banner-height'],
  mounted() {
    this.observeBannerHeight();
    this.$emit('banner-height', 0);
  },
  data(): Data {
    return {
      showDomainStatus: false,
      showHostingMaintenance: false,
      showPerformanceDrop: false,
      showFailedAutorenewBanner: false,
      showHostingDeprecationBanner: false,
      isAutoRenewBannerLoading: false,
      bannerHeightObserver: null,
    };
  },
  props: {
    headerType: String as PropType<HeaderType>,
  },
  setup() {
    const route = useRoute();
    const { isHostingV2 } = useHostingV2();
    const { isBlackFridaySaleAvailable } = useSaleCampaign();
    const domainBulkActionsStore = useDomainBulkActionsStore();
    const { isFeatureEnabled: isDashboardDomainStatusEnabled } = useFeatureFlag(
      FeatureFlag.ID.DASHBOARD_DOMAIN_STATUS,
    );

    const isInHostingV2List = computed(
      () =>
        route.fullPath.includes(RoutePath.Hosting.HOSTING_V2) &&
        !isHostingV2.value,
    );

    return {
      isHostingV2,
      domainBulkActionsStore,
      isBlackFridaySaleAvailable,
      isInHostingV2List,
      isDashboardDomainStatusEnabled,
    };
  },
  beforeUnmount() {
    this.bannerHeightObserver?.unobserve(this.$refs.banners);
  },
  computed: {
    isDomainBulkActionBannerShown() {
      const isVisibleInRoute =
        this.$route.name === Route.HostingerPro.DOMAINS ||
        this.$route.meta?.wrapperName === 'domain';

      return (
        !!(
          this.domainBulkActionsStore.isBulkActionCompleted ||
          this.domainBulkActionsStore.isBulkActionInProgress
        ) && isVisibleInRoute
      );
    },
    bannersPriorityList() {
      return [
        {
          orderId: 2,
          vIf: this.isInHostingDashboard,
          vShow: this.showPerformanceDropBanner,
          component: 'PerformanceDropBanner',
          setVisibility: (value: boolean) => (this.showPerformanceDrop = value),
        },
        {
          orderId: 3,
          vIf: true,
          vShow: this.showFailedAutorenewBanner,
          component: 'FailedAutoRenewBanner',
          setVisibility: (value: boolean) =>
            (this.showFailedAutorenewBanner = value),
          setLoading: (value: boolean) =>
            (this.isAutoRenewBannerLoading = value),
        },
        {
          orderId: 4,
          vIf: this.isInHostingV2List,
          vShow: true,
          component: 'HostingPageDeprecationBanner',
        },
        {
          orderId: 5,
          vIf: this.getOrdersLoaded,
          vShow: this.showDomainStatusBanner,
          component: 'DomainStatusBanner',
          setVisibility: (value: boolean) =>
            (this.showDomainStatusBanner = value),
        },
        {
          orderId: 6,
          vIf: this.isDomainBulkActionBannerShown,
          vShow: true,
          component: 'DomainBulkActionBanner',
        },
      ];
    },
    priorityBannerId() {
      const sortedList = [...this.bannersPriorityList].sort(
        (a, b) => a.orderId - b.orderId,
      );

      return sortedList.find((banner) => banner.vIf && banner.vShow)?.orderId;
    },
    showDomainStatusBanner() {
      return (
        this.showDomainStatus &&
        !this.getDomainNotPointingHidden &&
        EXCLUDED_DOMAIN_STATUS_ROUTES.indexOf(this.$route.name as string) < 0 &&
        !this.isDashboardDomainStatusEnabled
      );
    },
    showPerformanceDropBanner() {
      return (
        this.showPerformanceDrop && this.$route.name !== 'order_order_usage'
      );
    },
    isInHomepage() {
      return this.$route.name === Route.Base.HOME;
    },
    isInReferrals() {
      return [
        Route.Referral.REFFER_AND_EARN,
        Route.Referral.MY_REFERRALS,
        Route.Referral.REFERRALS,
      ].includes(this.$route.name as Route.Referral);
    },
    isInHostingDashboard() {
      const breadcrumbs = (this.$route.meta?.breadcrumbs as string[]) || [];

      return breadcrumbs[0] === Route.Base.HOSTING && !this.isHostingV2;
    },
    ...mapGetters(['getDomainNotPointingHidden', 'getOrdersLoaded']),
  },
  methods: {
    observeBannerHeight() {
      this.bannerHeightObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const newHeight = entry.contentRect.height;
          this.$emit('banner-height', newHeight);
        }
      });

      this.bannerHeightObserver.observe(this.$refs.banners);
    },
  },
});
</script>
<style lang="scss" scoped>
.banner-container {
  z-index: var(--z-index-4);
}
</style>
