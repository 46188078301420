import type { IDataCenter, ServerOS } from './serverModels';

export const VPS_PURCHASE_STEP = {
  START: 'vps-purchase-start',
  FEATURES: 'vps-purchase-features',
  GET_PLAN: 'vps-purchase-plan',
} as const;

export type VpsPurchaseStep =
  (typeof VPS_PURCHASE_STEP)[keyof typeof VPS_PURCHASE_STEP];

export interface IVpsPurchaseFeature {
  name: string;
  title: string;
  description: string;
  isSelected: boolean;
}

export interface IVpsPurchaseState {
  dataCenter?: IDataCenter;
  template?: ServerOS;
  hostname: string;
  rootPassword: string;
  panelPassword: string;
  sshKey: {
    name: string;
    key: string;
  };
  serverId?: number;
  isSettingUp: boolean;
  type?: string;
  features: IVpsPurchaseFeature[];
}

export type MostPopularPurchaseTheme =
  | 'primary'
  | 'primary-light'
  | 'black'
  | undefined;
