import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useReferralsPromotion } from '@/composables/referrals/useReferralsPromotion';
import { useExperiment } from '@/composables/useExperiment';
import { YEAR_IN_MONTHS } from '@/data/globalConstants';
import { referralRepo } from '@/repositories';
import { Experiment, Referral, Route } from '@/types';
import type { KeyValueMap, BillingPeriodUnit } from '@/types';
import { mapKeyValue } from '@/utils/helpers';

// make sure that all plans have this duration, for examaple, VPS does not have 48 month option
const INITIAL_PLAN_DURATION = Referral.PlanDuration.MONTHS_12;
const INITIAL_PLAN_SELECTION = Referral.ReferredProductType.WEB_HOSTING;

const PLAN_PRODUCT_TYPE_MAP: KeyValueMap = {
  [Referral.LastReferredPlan.HOSTING_STARTER]:
    Referral.ReferredProductType.WEB_HOSTING,
  [Referral.LastReferredPlan.HOSTING_BUSINESS]:
    Referral.ReferredProductType.WEB_HOSTING,
  [Referral.LastReferredPlan.HOSTING_PREMIUM]:
    Referral.ReferredProductType.WEB_HOSTING,
  [Referral.LastReferredPlan.CLOUD_ECONOMY]: Referral.ReferredProductType.CLOUD,
  [Referral.LastReferredPlan.CLOUD_ENTERPRISE]:
    Referral.ReferredProductType.CLOUD,
  [Referral.LastReferredPlan.CLOUD_PROFESSIONAL]:
    Referral.ReferredProductType.CLOUD,
  [Referral.LastReferredPlan.VPS_KVM1]: Referral.ReferredProductType.VPS,
  [Referral.LastReferredPlan.VPS_KVM2]: Referral.ReferredProductType.VPS,
  [Referral.LastReferredPlan.VPS_KVM4]: Referral.ReferredProductType.VPS,
  [Referral.LastReferredPlan.VPS_KVM8]: Referral.ReferredProductType.VPS,
  default: INITIAL_PLAN_SELECTION,
};

const MONTH_AND_DURATION_MAP: KeyValueMap = {
  12: Referral.PlanDuration.MONTHS_12,
  24: Referral.PlanDuration.MONTHS_24,
  48: Referral.PlanDuration.MONTHS_48,
  default: INITIAL_PLAN_DURATION,
};

export const usePlanReferralStore = defineStore('planReferralStore', () => {
  const { isExperimentActive: isLastReferredPlanExperimentActive } =
    useExperiment(Experiment.ID.REFERRALS_LAST_REFERRED_PRODUCT);

  const { activePromotion } = useReferralsPromotion();
  const router = useRouter();

  const isLoadingLastReferredPlan = ref<boolean | null>(null);

  const lastReferredPlan = reactive<{
    plan?: Referral.LastReferredPlan;
    period?: Referral.PlanDuration;
    periodUnit?: BillingPeriodUnit;
  }>({
    plan: undefined,
    period: undefined,
    periodUnit: undefined,
  });

  const shouldFetchLastReferredPlan = computed(
    () =>
      isLastReferredPlanExperimentActive.value &&
      isLoadingLastReferredPlan.value === null,
  );

  const lastReferredPlanValuesMapped = computed(() => {
    const planType = mapKeyValue(
      PLAN_PRODUCT_TYPE_MAP,
      lastReferredPlan.plan as string,
    );

    const periodInMonths =
      lastReferredPlan.periodUnit === 'year'
        ? Number(lastReferredPlan.period) * YEAR_IN_MONTHS
        : lastReferredPlan?.period;

    const duration = mapKeyValue(
      MONTH_AND_DURATION_MAP,
      periodInMonths as string,
    );

    return {
      planType,
      duration,
    };
  });

  // Method because router.options.history.state.back is not reactive and computed does not update when it changes
  const getInitialSelectionValues = () => {
    if (isLastReferredPlanExperimentActive.value) {
      return lastReferredPlanValuesMapped.value;
    }
    if (
      activePromotion.value?.promotionType ===
      Referral.LimitedTimeOfferCampaignTypeKey.CLOUD_VPS
    ) {
      const isPreviousRouteFromVPS = (
        router.options.history.state.back as string
      )?.includes(Route.Base.SERVERS);

      return {
        planType: isPreviousRouteFromVPS
          ? Referral.ReferredProductType.VPS
          : Referral.ReferredProductType.CLOUD,
        duration: Referral.PlanDuration.MONTHS_12,
      };
    }

    return {
      planType: INITIAL_PLAN_SELECTION,
      duration: INITIAL_PLAN_DURATION,
    };
  };

  const fetchLastReferredPlan = async () => {
    isLoadingLastReferredPlan.value = true;
    const [{ data }, error] = await referralRepo.getLastReferredPlan();
    isLoadingLastReferredPlan.value = false;

    if (error) {
      return;
    }

    lastReferredPlan.plan = data?.plan;
    lastReferredPlan.period = data?.period;
    lastReferredPlan.periodUnit = data?.periodUnit;
  };

  const $reset = () => {
    isLoadingLastReferredPlan.value = false;
    lastReferredPlan.plan = undefined;
    lastReferredPlan.period = undefined;
    lastReferredPlan.periodUnit = undefined;
  };

  return {
    shouldFetchLastReferredPlan,
    isLoadingLastReferredPlan,
    getInitialSelectionValues,
    fetchLastReferredPlan,
    $reset,
  };
});
